
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { ClientsService } from '@core/services/clients.service';
import { ProjetsService } from '@core/services/projets.service';
@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss']
})
export class ProjectHeaderComponent {
  @Input() project: IProjet;
  @Input() shoppedServices: IService[];
  public data: any;

  constructor(
    private router: Router,
    private clientsService: ClientsService,
    public projectsService: ProjetsService,
  ) {
    const nav = this.router.getCurrentNavigation();
    if (nav.extras.state) {
      const state = nav.extras.state as { data: any };
      this.project = state.data;
    }
  }

  goBack() {
    this.router.navigateByUrl('/project/' + this.project.key, { state: { data: this.data } });
  }

  mapService(snasphot: firebase.firestore.QueryDocumentSnapshot): IService {
    const key = snasphot.id;
    const data = snasphot.data();
    return { key, ...data } as any;
  }

  getMontantTotalShoppedServices() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const services = this.shoppedServices.filter(service => service.articles && service.articles.length > 0);
    let price = (services && services.length > 0 ? services.map(service =>
      service.articles.map(article => article.amount * article.cost).reduce(reducer)).reduce(reducer) : 0);
    return +price.toFixed(2);
  }

  getChargeTotalShoppedServicesForUnityDay() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const services = this.shoppedServices.filter(service => service.articles && service.articles.length > 0 && service.unity == "J");
    return (services && services.length > 0 ? services.map(service => service.articles.map(article =>
      article.amount).reduce(reducer)).reduce(reducer) : 0);
  }

  getChargeTotalShoppedServicesForUnityMonth() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const services = this.shoppedServices.filter(service => service.articles && service.articles.length > 0 && service.unity == "M");
    return (services && services.length > 0 ? services.map(service => service.articles.map(article =>
      article.amount).reduce(reducer)).reduce(reducer) : 0);

  }

  getNombresArticles() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const services = this.shoppedServices.filter(service => service.articles && service.articles.length > 0);
    return (services && services.length > 0 ? services.map(service => service.articles.length).reduce(reducer) : 0);
  }


  currentClient() {
    if (this.project) {
      return (this.project && this.project.clientId ?
        this.clientsService.clients.filter(client => client.key === this.project.clientId)[0] : null);
    }
  }

}
