<ng-container *ngIf="currentService">
  <div class="entete">
    <mat-form-field appearance="outline" class="title-input">
      <mat-label>Nom du service</mat-label>
      <input matInput type="text" autocomplete="off" [(ngModel)]="currentService.name" (ngModelChange)="changeTitle()">
    </mat-form-field>
      <div class="boutons" *ngIf="currentService && !inCurrentProject">
        <button class="suppr" mat-fab [color]="(currentService.disabled? 'primary':'warn') " (mouseenter)="this.disableHover = true"
          (mouseleave)="this.disableHover = false" (click)="disableCurrentService()"
          [matTooltip]="(currentService.disabled? 'Activer':'Désactiver')">
          <i *ngIf="!currentService.disabled" [ngStyle]="{'font-size': this.disableHover == true ? '21px' : '17px'}"
            class="fas fa-times fa-lg"></i>
          <i *ngIf="currentService.disabled" [ngStyle]="{'font-size': this.disableHover == true ? '21px' : '17px'}"
            class="fas fa-check fa-lg"></i>
        </button>

        <button mat-fab color="warn" (mouseenter)="trashHover = true" matTooltip="Supprimer"
          (mouseleave)="trashHover = false" (click)="deleteCurrentService()">
          <i *ngIf="!trashHover" class="fas fa-trash fa-lg"></i>
          <i *ngIf="trashHover" class="fas fa-trash-restore fa-lg"></i>
        </button>
    </div>
  </div>

  <div *ngIf="!inCurrentProject; else elseBlock">
    <app-article-edit class="content2" [services]="currentService" [articles]="currentService.articles" (save)="saveCurrentService()">
    </app-article-edit>
  </div>

  <ng-template #elseBlock>
    <app-article-edit class="content2" [services]="currentService" [articles]="currentService.articles" (save)="saveCurrentShoppedService()">
    </app-article-edit>
  </ng-template>

</ng-container>
