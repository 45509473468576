<div *ngIf="!articles || articles.length === 0">
  Aucun article !
  </div>
  <table fxFlex *ngIf="articles && articles.length > 0">
    <thead>
      <tr>
        <td>Nom de l'article</td>
        <td>Quantité</td>
        <td>Prix unitaire</td>
        <td>Prix</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let article of articles; let index = index">
        <td>
          <span>{{article.name}}</span>
        </td>
        <td>
          {{ article.amount }} {{service.unity}}
        </td>
        <td>
          {{ article.cost }} €
        </td>
        <td>
          {{ getTotalPrice(article) }} €
        </td>
      </tr>
    </tbody>
  </table>
