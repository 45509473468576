import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, QuerySnapshot } from '@angular/fire/firestore';
import { Country } from '@core/models/country.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  private readonly COLLECTION_NAME: string = 'countries';

  constructor(
    private readonly firestore: AngularFirestore
  ) { }

  public getCountries(): Observable<Country[]> {
    return this.firestore.collectionGroup<Country>(this.COLLECTION_NAME).get().pipe(
      map((query: QuerySnapshot<DocumentData>) => query.docs.map((doc) => doc.data() as Country)),
      map((countries: Country[]) => countries.sort((a, b) => a.label.localeCompare(b.label)))
    );
  }

  public getCountryCodeByLabel(label: string): Observable<string> {
    return this.getCountries().pipe(
      map((countries: Country[]) => countries.find((country) => country.label === label)?.key)
    );
  }



}
