<h1 mat-dialog-title>Sélectionne le(s) chapitre(s) à copier</h1>
<div mat-dialog-content>
  <mat-selection-list #chapters ngDefaultControl [(ngModel)]="selectedChapters">
    <mat-list-option *ngFor="let chapter of data.chaptersTemplates" [value]="chapter">
      {{ chapter.name }}{{chapter.type === 'article' ? ' - Premade' : ''}}
    </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">CANCEL</button>
  <button mat-button [mat-dialog-close]="selectedChapters" cdkFocusInitial>OK</button>
</div>
