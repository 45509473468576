import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router, public afAuth: AngularFireAuth, private authService: AuthService, private db: AngularFirestore) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const expectedRole = route.data.expectedRole;
    return new Promise(
      (resolve, reject) => {
        this.afAuth.onAuthStateChanged(
          (user) => {
            if (user) {
              const subscriber = this.db.doc('/users/' + user.uid).snapshotChanges().pipe(
                map((action: Action<DocumentSnapshot<unknown>>) => {
                  return action.payload.data() as any;
                })
              ).subscribe((data) => {
                this.db.collection('roles', ref => ref.where('name','==',data.role)).get().subscribe(
                  (data) => {

                    if (data.docs[0].data().permissions["E-Business"] !== undefined) {
                      resolve(true);
                    } else {
                      this.router.navigateByUrl('restricted-access');
                      resolve(false);
                    }
                  }
                );
                subscriber.unsubscribe();
              }, (error) => {
                this.router.navigateByUrl('restricted-access');
                resolve(false);
                subscriber.unsubscribe();
              });
            } else if (this.authService.firstCookieLog) {
              this.authService.logFromCookie().then(() => this.canActivate(route));
            } else {
              resolve(false);
            }
          }
        );
      }
    );
  }
}
