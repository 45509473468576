import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IContact } from '@core/models/contacts.model';
import { ContactsService } from '@core/services/contacts.service';
import { phoneValidator } from '@shared/validators/phone.validator';
import { Subscription } from 'rxjs';

export enum EditContactsResultEnum {
  CANCEL,
  BACKDROP,
  UPDATE,
  DELETE
}
@Component({
  selector: 'app-edit-contacts',
  templateUrl: './edit-contacts.component.html',
  styleUrls: ['./edit-contacts.component.scss']
})
export class EditContactsComponent implements OnInit, OnDestroy {

  public contactForm: FormGroup;
  public types: string[] = this.contactService.contactType;

  public _contactUpdate: Subscription;
  public _contactDelete: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IContact,
    private contactService: ContactsService,
    private dialogRef: MatDialogRef<EditContactsComponent>) { }

  public ngOnInit(): void {
    this.initForm()
  }

  public initForm(): void {
    this.contactForm = new FormGroup({
      firstName: new FormControl(this.data.firstName, [Validators.required]),
      lastName: new FormControl(this.data.lastName, [Validators.required]),
      type: new FormControl(this.data.type, [Validators.required]),
      phone: new FormControl(this.data.phone, [Validators.nullValidator, phoneValidator()]),
      email: new FormControl(this.data.email, [Validators.nullValidator, Validators.email])
    })
  }

  public ngOnDestroy(): void {
    this._contactUpdate && this._contactUpdate.unsubscribe();
    this._contactDelete && this._contactDelete.unsubscribe();
  }

  public getContactFromForm(): IContact {
    return {
      id: this.data.id,
      parent: this.data.parent,
      parentId: this.data.parentId,
      actif: this.data.actif,
      email: this.contactForm.get('email').value,
      firstName: this.contactForm.get('firstName').value,
      lastName: this.contactForm.get('lastName').value,
      phone: this.contactForm.get('phone').value,
      type: this.contactForm.get('type').value
    }
  }

  public onSubmit(): void {
    this._contactUpdate = this.contactService.updateContacts(this.getContactFromForm()).subscribe(() => this.dialogRef.close(EditContactsResultEnum.UPDATE));
  }

  public onCancel(): void {
    this.dialogRef.close(EditContactsResultEnum.CANCEL);
  }

  public onDelete(): void {
    if (!confirm('Êtes-vous sûr de vouloir supprimer ce contact ?')) return;
    this._contactDelete = this.contactService.deleteContacts(this.data.id).subscribe(() => this.dialogRef.close(EditContactsResultEnum.DELETE));
  }

  public get mailControl(): AbstractControl {
    return this.contactForm.get('email');
  }

  public get phoneControl(): AbstractControl {
    return this.contactForm.get('phone');
  }

  public getMailErrorMessage(): string {
    if (this.mailControl.hasError('email')) return 'Format de mail invalide (exemple@gmail.com)';
  }

  public getPhoneErrorMessage(): string {
    if (this.phoneControl.hasError('phone')) return this.phoneControl.errors.phone;
  }
}
