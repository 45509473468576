import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IService } from '@core/models/service.models';
import { AuthService } from '@core/services/auth.service';
import { ServicesService } from '@core/services/services.service';
import { AddServicesComponent } from '@pages/gest-services/modals/add-services/add-services.component';
import { ModifyUnityComponent } from '@pages/gest-services/modals/modify-unity/modify-unity.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent implements OnInit, OnDestroy, AfterViewInit {

  public currentService: IService = null;
  @Output() sendCurrentService = new EventEmitter();

  public dataSource = new MatTableDataSource<IService>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ["points", "name", "contenu"];
  private _serviceSub: Subscription;

  constructor(public servicesService: ServicesService, private db: AngularFirestore, private snackBar: MatSnackBar, private authService: AuthService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this._serviceSub = this.servicesService.ServicesChange.subscribe((res) => {
      this.dataSource.data = res;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  ngOnDestroy() {
    this._serviceSub?.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getMontantTotalByService(service: IService): number {
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    return +(service.articles && service.articles.length > 0 ?
      service.articles.map(article => article.amount * article.cost).reduce(reducer) : 0).toFixed(2);

  }

  getChargeTotalByServiceDay(service: IService) {
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    return (service.articles && service.articles.length > 0 ?
      service.articles.map(article => article.amount).reduce(reducer) : 0);
  }


  changeTitle() {
    const newService = { ...this.currentService };
    this.db.doc(`services/${this.currentService.key}`).update(newService);
  }

  addCustomService() {
    const dialogRef = this.dialog.open(AddServicesComponent, {
      width: '425px',
      data: ''
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dataToadd: IService = {
          updatedBy: null,
          addedBy: this.authService.userFB.uid,
          articles: [],
          addedDate: new Date(),
          updatedDate: null,
          unity : result.unity,
          projectID: null,
          name: result.name,
          order: null,
          disabled: false,
        };
        this.db.collection('services').add(dataToadd);
      }
    });
  }

  saveCurrentService() {
    const toSave = { ...this.currentService };
    delete toSave.key;
    toSave.updatedBy = this.authService.userFB.uid;
    toSave.updatedDate = new Date();
    this.db.doc('services/' + this.currentService.key).update(toSave);
  }

  deleteCurrentService() {
    if (this.currentService) {
      this.db.doc('services/' + this.currentService.key).delete();
      this.currentService = null;
    }
  }

  setCurrentService(service: IService) {
    this.currentService = service;
    this.sendCurrentService.emit(this.currentService);
  }

  disableCurrentService() {
    const toSave = { ...this.currentService };
    toSave.updatedBy = this.authService.userFB.uid;
    toSave.updatedDate = new Date();
    toSave.disabled = !this.currentService.disabled;
    this.db.doc('services/' + this.currentService.key).update(toSave).then(() => {
      this.currentService = toSave;
    });
  }

  modifyUnity(article: IService) {
    const dialogRef = this.dialog.open(ModifyUnityComponent, {
      width: '450px',
      data: article
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.currentService.unity = result;
        this.db.doc('services/' + this.currentService.key).update({unity : this.currentService.unity})
      }
    });
  }
}
