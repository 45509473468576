import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { AuthService } from '@core/services/auth.service';
import { AddServicesComponent } from '@pages/gest-services/modals/add-services/add-services.component';

@Component({
  selector: 'app-services-project',
  templateUrl: './services-project.component.html',
  styleUrls: ['./services-project.component.scss']
})
export class ServicesProjectComponent implements OnInit {

  @Input() shoppedServices: IService[];
  @Input() projet: IProjet;
  @Input() multi : boolean;
  public currentService: IService;
  public movedService: IService = null;
  public trashHover;
  @Output() sendCurrentService = new EventEmitter();
  @Output() updateProject = new EventEmitter();

  constructor(private db: AngularFirestore, private authService: AuthService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  getMontantTotalByService(service: IService) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return +(service.articles && service.articles.length ?
      service.articles.map(article => article.amount * article.cost).reduce(reducer) : 0).toFixed(2);
  }

  getChargeTotalByService(service: IService) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return (service.articles && service.articles.length ?
      service.articles.map(article => article.amount).reduce(reducer) : 0);
  }

  drop(event: CdkDragDrop<IService[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.moveShoppedService();
    } else {
      this.addServiceToShoppedService(event.previousContainer.data[event.previousIndex], event.currentIndex);
    }
  }

  addServiceToShoppedService(service: IService, position: number) {
    const dataToadd = { ...service };
    delete dataToadd['key'];
    dataToadd.projectID = this.projet.key;
    dataToadd.addedDate = new Date();
    dataToadd.addedBy = this.authService.userFB.uid;
    dataToadd.order = position;
    const toIncrease = this.shoppedServices.filter(aService => aService.order >= position);
    this.db.collection('shoppedServices').add(dataToadd)
      .then(docRef => {
        dataToadd.key = docRef.id;
        this.shoppedServices.splice(position, 0, dataToadd);
        const promises = [];
        toIncrease.forEach(aService => {
          const newService = { ...aService };
          delete newService['key'];
          newService.order++;
          promises.push(this.db.doc('shoppedServices/' + aService.key).update(newService));
        });
        Promise.all(promises).then(() => {
          this.updateProject.emit();
        });
      });
  }

  sendService(service: IService) {
    this.sendCurrentService.emit(service);
  }

  moveShoppedService() {
    const promises = [];
    this.shoppedServices.forEach((aService, index) => {
      if (aService.order !== index) {
        const newService = { ...aService };
        delete newService['key'];
        newService.order = index;
        this.db.doc('shoppedServices/' + aService.key).update(newService);
        promises.push(this.db.doc('shoppedServices/' + aService.key).update(newService));
      }
    });
    Promise.all(promises).then(() => {
      this.updateProject.emit();
    });
  }

  addCustomService() {
    let order = 0;
    if (this.shoppedServices && this.shoppedServices.length > 0) {
      order = Math.max(... this.shoppedServices.map(aService => aService.order)) + 1;
    }
    const dialogRef = this.dialog.open(AddServicesComponent, {
      width: '300px',
      data: ''
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dataToadd: IService = {
          updatedBy: null,
          addedBy: this.authService.userFB.uid,
          articles: [],
          addedDate: new Date(),
          updatedDate: null,
          unity : result.unity,
          projectID: this.projet.key,
          name: result.name,
          order,
          disabled: false,
        };
        this.db.collection('shoppedServices').add(dataToadd).then((docRef => {
          this.updateProject.emit();
          dataToadd.key = docRef.id;
          this.shoppedServices.splice(order, 0, dataToadd);
        }));
      }
    });

  }

  trash(event: CdkDragDrop<IService[]>) {
    if (event.previousContainer.id === 'shopContent') {
      this.db.doc('shoppedServices/' + this.movedService.key).delete().then(() => {
        this.shoppedServices.splice(event.previousIndex, 1);
        this.moveShoppedService();
      }).catch(err => console.log(err));
    }
  }

}
