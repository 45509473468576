import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { tap, switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private snackBar: MatSnackBar) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.url != `http://${environment.authAPI}/security/refresh` && !req.url.includes(`facturx`) && !req.url.includes(`SRV-EMO-PI-APIG:5555`) && !req.url.includes('assets')) {
            return from(this.authService.afAuth.idToken)
            .pipe(
                switchMap((token: string) => {
                    const headers = new HttpHeaders({
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        authorization: token
                    });
                    const changedRequest = req.clone({ headers });
                    return next.handle(changedRequest).pipe(
                        tap(event => {
                            if (event instanceof HttpResponse) {
                                // something if okay
                            }
                        }, (error: any) => {
                            if (error instanceof HttpErrorResponse) {
                                this.snackBar.open(error.statusText, 'x', {
                                    duration: 4000,
                                    verticalPosition: 'top',
                                    horizontalPosition: 'end',
                                    panelClass: ['red-snackbar']
                                });
                            }
                        })
                    );
                }));
        } else {
            console.log(req.url);
            return next.handle(req);
        }
    }
}
