import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  public transform(value: number): string {
    const decimalPipe: DecimalPipe = new DecimalPipe('en-US');
    const formatedValueString: string = decimalPipe.transform(value, '1.2-2');
    let formatedString: string = formatedValueString.replace(',', ' ');
    formatedString = formatedString.replace('.', ',');
    return `${formatedString}`;
  }

}