<div fxLayout="column" fxLayoutAlign="center center" class="over">
  <mat-accordion [multi]="multi" id="shopContent" class="content" cdkDropList [cdkDropListData]="shoppedServices"
    cdkDropListConnectedTo="trash2" (cdkDropListDropped)="drop($event)">
    <ng-container *ngIf="!multi">
      <mat-expansion-panel *ngFor="let service of shoppedServices" [cdkDragData]="service" cdkDrag
        (cdkDragStarted)="movedService = service" (afterExpand)="currentService = service"
        (afterExpand)="sendService(currentService)" (closed)="currentService = null"
        (closed)="sendService(currentService)" [expanded]="currentService && service.key === currentService.key ">
        <mat-expansion-panel-header cdkDragHandle>
          <mat-panel-title>
            <b>{{ service.name }}</b>
          </mat-panel-title>
          <mat-panel-description *ngIf="service.articles">
            <span class="panel-description"><span
                [ngClass]="{'hasNoArticle': service.articles.length <= 0}">{{ service.articles.length }}
                articles</span>
              ({{ getMontantTotalByService(service) }} €,
              {{ getChargeTotalByService(service) }} {{service.unity}})</span>
          </mat-panel-description>
          <mat-panel-description *ngIf="!service.articles">
            <span class="panel-description">Aucun article</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-article [service]="service" [articles]="service.articles"></app-article>
      </mat-expansion-panel>
    </ng-container>
    <ng-container *ngIf="multi">
      <mat-expansion-panel *ngFor="let service of shoppedServices" [cdkDragData]="service" cdkDrag
        (cdkDragStarted)="movedService = service" [expanded]="currentService && service.key === currentService.key ">
        <mat-expansion-panel-header cdkDragHandle>
          <mat-panel-title>
            <b>{{ service.name }}</b>
          </mat-panel-title>
          <mat-panel-description *ngIf="service.articles">
            <span class="panel-description"><span
                [ngClass]="{'hasNoArticle': service.articles.length <= 0}">{{ service.articles.length }}
                articles</span>
              ({{ getMontantTotalByService(service) }} €,
              {{ getChargeTotalByService(service) }} {{service.unity}})</span>
          </mat-panel-description>
          <mat-panel-description *ngIf="!service.articles">
            <span class="panel-description">Aucun article</span>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <app-article [service]="service" [articles]="service.articles"></app-article>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
<button mat-raised-button class="addButtonInPanel" (click)="addCustomService()"><b>Ajouter un service
    personnalisé</b></button>

<button id="trash2" mat-fab cdkDropList (cdkDropListDropped)="trash($event)" color="warn"
  (mouseenter)="trashHover = true" (mouseleave)="trashHover = false">
  <i *ngIf="!trashHover" class="fas fa-trash fa-lg"></i>
  <i *ngIf="trashHover" class="fas fa-trash-restore fa-lg"></i>
</button>
