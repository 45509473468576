<app-devis-express [invoice]="devisInvoice"></app-devis-express>
<!-- <div>
  <app-project-header [project] = "projectsService.currentProject"  [shoppedServices]= "serviceService.shoppedServices"></app-project-header>
  <div style="height: 100%">
    <mat-horizontal-stepper #stepper (selectionChange)="stepChange($event)"  style="height: 100%">
      <mat-step *ngFor="let step of stepValue; let i = index">
        <ng-template matStepLabel>{{step}}</ng-template>
        <router-outlet *ngIf="i === currentStep"></router-outlet>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
</div> -->