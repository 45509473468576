export const UnitLabel: ReadonlyArray<Unit> = [
  {
    value: 'DAY',
    label: 'Jour',
    transform: (qty: number, unitPrice: number) => qty * unitPrice
  },
  {
    value: 'MON',
    label: 'Mois',
    transform: (qty: number, unitPrice: number) => qty * unitPrice
  },
  {
    value: 'HUR',
    label: 'Heure',
    transform: (qty: number, unitPrice: number) => qty * unitPrice
  },
  {
    value: 'C62',
    label: 'Unité',
    transform: (qty: number, unitPrice: number) => qty * unitPrice
  }, {
    value: 'P1',
    label: '%',
    transform: (qty: number, unitPrice: number) => qty * unitPrice / 100
  }
];

export type UnitCode = typeof UnitLabel[number]['value'];

export type Unit = {
  value: string;
  label: string;
  transform:(qty: number, unitPrice: number) => number;
}
