<div fxLayout="row">
  <div class="container" fxFlex = 33>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1>Liste des chapitres</h1>
    </div>
    <app-paragraphe-list (sendCurrentChapter)="setCurrentChapter($event)"></app-paragraphe-list>
  </div>
  <div class="container" fxFlex>
    <app-modify-paragraphe [inCurrentProject] = "false" [currentChapter]="currentChapter"></app-modify-paragraphe>
  </div>
</div>
