import { Pipe, PipeTransform } from "@angular/core";
import { PaymentMeansLabel } from "@pages/projects/components/invoice/invoice-header/invoice-header.component";

@Pipe({
    name: 'paymentMean'
})
export class PaymentMeanPipe implements PipeTransform {
  
    public transform(value: string): string {
      return PaymentMeansLabel.find((paymentMean) => paymentMean.value === value)?.label || value;
    }
}