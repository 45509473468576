import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-client',
  templateUrl: './projects-client.component.html',
  styleUrls: ['./projects-client.component.scss']
})
export class ProjectsClientComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
