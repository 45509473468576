import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { DevisExpressRoutingModule } from './devis-express-routing.module';
import { DevisExpressComponent } from './devis-express.component';
import { DevisInvoiceHeaderComponent } from './components/devis-invoice-header/devis-invoice-header.component';
import { DevisInvoiceLinesCardComponent } from './components/devis-invoice-lines-card/devis-invoice-lines-card.component';
import { DevisInvoicePreviewComponent } from './components/devis-invoice-preview/devis-invoice-preview.component';
import { SharedModule } from '@shared/shared.module';
import { DevisInvoiceLineComponent } from './components/devis-invoice-line/devis-invoice-line.component';

@NgModule({
  declarations: [
    DevisExpressComponent,
    DevisInvoiceHeaderComponent,
    DevisInvoiceLinesCardComponent,
    DevisInvoiceLineComponent,
    DevisInvoicePreviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    DevisExpressRoutingModule,
    SharedModule
  ],
  exports: [
    DevisExpressComponent
  ]
})
export class DevisExpressModule { }
