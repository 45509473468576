<div fxLayout="row">
  <div class="container" fxFlex>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1>Résumé du projet</h1>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-accordion id="shopContent">
        <mat-expansion-panel *ngFor="let service of shoppedServices">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <b>{{ service.name }}</b>
            </mat-panel-title>
            <mat-panel-description *ngIf="service.articles">
              <span class="panel-description"><span
                  [ngClass]="{'hasNoArticle': service.articles.length <= 0}">{{ service.articles.length }}
                  articles</span>
                ({{ getMontantTotalByService(service) }} €,
                {{ getChargeTotalByService(service) }} {{service.unity}})</span>
            </mat-panel-description>
            <mat-panel-description *ngIf="!service.articles">
              <span class="panel-description">Aucun article</span>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-article [service]="service" [articles]="service.articles">{{service.articles}}</app-article>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

</div>
<div *ngIf="projet" [ngSwitch]="projet.validate">
  <button *ngSwitchCase="0" id="validation" mat-fab color="primary" (mouseenter)="validHover = true"
    (mouseleave)="validHover = false" (click)="validateProject()">
    <i class="fas fa-check" [ngStyle]="{'font-size': validHover == true ? '18px' : '15px'}"></i>
  </button>
  <p *ngSwitchCase="1" class="projectValidate">Le projet est en cours de validation.</p>
  <div>
    <p *ngSwitchCase="2" class="projectValidate"><a href="{{getLinkToTasker()}}"
        target="blank">Lien vers le projet Tasker</a></p>
  </div>
</div>
