import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentData, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IChapter, IChapterWithPars, IParagraphe } from '@core/models/chapters.models';
import { from, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap, tap, merge } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChaptersService {

  public chapters: IChapter[];
  private chaptersSubscription: Subscription;

  public paragraphes: IParagraphe[];
  private paragraphesSubscription: Subscription;

  public chaptersTemplates: IChapter[];
  private chaptersTemplatesSubscription: Subscription;

  public chaptersWithPars: IChapterWithPars[];
  private chaptersWithParsSubscription: Subscription;

  public chapterSelectedSubject: Subject<any>;

  public refreshPdfSubject: Subject<void>;

  public chapterSubscribe;
  public ChaptersChange = new Subject<void>();
  public ParagraphesChange = new Subject<void>();

  constructor(private db: AngularFirestore, private snackBar: MatSnackBar) {
    this.chapterSelectedSubject = new Subject<void>();
    this.refreshPdfSubject = new Subject<void>();
   }

  init() {
    this.cancelChaptersSubscription();
    this.chapterSubscribe = this.db.collection('chapters').snapshotChanges().pipe(
      map(this.getChaptersPreview),
      map(this.sortByName)
    ).subscribe((data) => {
      this.chapters = data;
      this.ChaptersChange.next();
    }, (error) => {
      console.error(error);
      this.snackBar.open(error, 'x', {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'end',
        panelClass: ['red-snackbar']
      });
    });
  }



  addChapters = (name: String, type: string): Promise<firebase.firestore.DocumentReference> => {
    return this.db.collection('chapters').add({ name, type });
  }

  addChapter(projectId: string, chapter: IChapter) {
    return this.db.collection(`projects/${projectId}/chapter`).add(chapter);
  }

  addParagraphe = (chapterKey: String, paragraphe: IParagraphe): Promise<firebase.firestore.DocumentReference> => {
    return this.db.collection(`chapters/${chapterKey}/paragraphes`).add(paragraphe);
  }

  getParagraphes = (projectId: string, chapterId): Observable<IParagraphe[]> => {
    return this.db.collection(`projects/${projectId}/chapter/${chapterId}/paragraphes`, ref => ref.orderBy('order'))
      .snapshotChanges()
      .pipe(
        map((changes: DocumentChangeAction<unknown>[]) => changes.map(this.paragrapheFromFirestore))
      );
  }

  getOnceAllTemplateChapters(): void {
    this.chaptersTemplatesSubscription = this.getAllTemplateChapters()
      .subscribe(data => this.chaptersTemplates = data);
  }

  subscribeToParagraphe(chapterKey: string) {
    this.paragraphesSubscription = this.getParagraphe(chapterKey)
      .subscribe((data) => {
        this.paragraphes = data;
        this.ParagraphesChange.next();
      }, (error) => {
        console.error(error);
        this.snackBar.open(error, 'x', {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['red-snackbar']
        });
      })
  }

  subscribeToChapters(projectId: string) {
    this.chaptersSubscription = this.getChapters(projectId)
      .subscribe(chapters => this.chapters = chapters);
  }

  getChapters = (projectId: string): Observable<IChapter[]> => {
    return this.db.collection(`projects/${projectId}/chapter`, ref => ref.orderBy('order'))
      .snapshotChanges()
      .pipe(
        map((changes: DocumentChangeAction<unknown>[]) => changes.map(this.chapterFromFirestore))
      );
  }

  getParagraphe = (chapterKey: string): Observable<IParagraphe[]> => {
    return this.db.collection(`chapters/${chapterKey}/paragraphes`).snapshotChanges()
      .pipe(
        map((snapshot: DocumentChangeAction<unknown>[]) => {
          return snapshot.map((doc: DocumentChangeAction<unknown>) => {
            const data = doc.payload.doc.data() as any;
            const key = doc.payload.doc.id;
            if (data && key) {
              data.key = key;
            }
            return data as IParagraphe;
          })
        }),
        map(this.sortByOrder)
      );
  }

  paragrapheFromFirestore = (change: DocumentChangeAction<unknown>): IParagraphe => {
    const original: any = change.payload.doc.data();
    return {
      key: change.payload.doc.id,
      name: original.name,
      chapterKey: original.chapterKey,
      order: original.order,
      type: original.type ? original.type : "par",
      ops: original.ops
    } as IParagraphe;
  }

  copyChapterTemplate = (projectId: string, chapterTemplate: IChapter): Promise<void> => {
    const lastChapterOrder = this.chapters.length > 0
      ? Math.max(...this.chapters.map(c => c.order))
      : -1;

    if (chapterTemplate.type === 'text') {
      return this.db.firestore.collection(`chapters/${chapterTemplate.key}/paragraphes`).get()
        .then(this.mapParagrapheFromQuerySnapshot)
        .then(paragraphes => {
          const newChapterRef = this.db.firestore.collection(`projects/${projectId}/chapter`).doc();
          const newChapter = { ...chapterTemplate };
          newChapter.key = newChapterRef.id;
          newChapter.order = lastChapterOrder + 1;
          newChapter.type = "text";

          const batch = this.db.firestore.batch();
          batch.set(newChapterRef, newChapter);

          for (var i = 0; i < paragraphes.length; i++) {
            const parRef = this.db.firestore.collection(`projects/${projectId}/chapter/${newChapterRef.id}/paragraphes`)
              .doc();
            paragraphes[i].key = parRef.id;
            paragraphes[i].chapterKey = newChapter.key;
            batch.set(parRef, paragraphes[i]);
          }

          return batch.commit();
        });
    } else {
      const newChapterRef = this.db.firestore.collection(`projects/${projectId}/chapter`).doc();
      const newChapter = { ...chapterTemplate };
      newChapter.key = newChapterRef.id;
      newChapter.order = lastChapterOrder + 1;
      newChapter.type = newChapter.type;
      const batch = this.db.firestore.batch();
      batch.set(newChapterRef, newChapter);
      return batch.commit();
    }
  }

  subscribeToChaptersWithPars = (projectId: string) => {
    this.chaptersWithParsSubscription = this.getChaptersWithPars(projectId)
      .subscribe(chaptersWithPar => {
        this.chaptersWithPars = chaptersWithPar;
      });
  }

  deleteChapter = (projectId: string, chapter: IChapter): Promise<void> => {
    if (chapter.type !== "text") {
      return this.db.doc(`projects/${projectId}/chapter/${chapter.key}`).delete();
    } else {
      return this.db.firestore.collection(`projects/${projectId}/chapter/${chapter.key}/paragraphes`).get()
        .then(this.mapParagrapheFromQuerySnapshot)
        .then(paragraphes => {
          const batch = this.db.firestore.batch();
          paragraphes.forEach(paragraphe => {
            batch.delete(this.db.firestore.doc(`projects/${projectId}/chapter/${chapter.key}/paragraphes/${paragraphe.key}`));
          });
          batch.delete(this.db.firestore.doc(`projects/${projectId}/chapter/${chapter.key}`));
          return batch.commit();
        });
    }
  }

  addParagraph(projectId: string, chapterKey: string, paragraphe: IParagraphe) {
    return this.db.collection(`projects/${projectId}/chapter/${chapterKey}/paragraphes`).add(paragraphe);
  }

  subscribeToParagraphes(projectId, chapterId) {
    this.paragraphesSubscription = this.getParagraphes(projectId, chapterId)
      .subscribe(paragraphes => this.paragraphes = paragraphes);
  }


  getAllTemplateChapters = (): Observable<IChapter[]> => {
    return this.db.collection(`chapters`, ref => ref.orderBy('name')).get()
      .pipe(
        map((values: QuerySnapshot<DocumentData>) => {
          return values.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
            return {
              key: doc.id,
              order: -1,
              ...doc.data()
            } as IChapter;
          });
        })
      );
  }

  getChaptersWithPars = (projectId: string): Observable<IChapterWithPars[]> => {
    const chapterObs: Observable<any> = this.getChapters(projectId);
    const selectObs: Observable<any> = this.chapterSelectedSubject.asObservable();
    return chapterObs
      .pipe(
        merge(selectObs),
        switchMap(() => from(this.getChaptersWithParagraphesPromise(projectId))),
        tap(() => this.refreshPdfSubject.next())
      )
  }

  getChaptersWithParagraphesPromise = (projectId: string): Promise<IChapterWithPars[]> => {
    return this.db.collection(`projects/${projectId}/chapter`, ref => ref.orderBy('order'))
      .get().toPromise()
      .then(this.mapChapterFromQuerySnapshot)
      .then(chapters => {
        const promises: Promise<IChapterWithPars>[] = chapters.map(chapter =>
          this.mapChapterToChapterWithPars(projectId, chapter));
        return Promise.all(promises);
      });
  }

  reorderChapters(projectId) {
    var batch = this.db.firestore.batch();
    this.chapters.forEach(chapter => {
      const docRef = this.db.firestore.doc(`projects/${projectId}/chapter/${chapter.key}`);
      batch.update(docRef, { order: chapter.order });
    });
    return batch.commit();
  }

  sortByOrder = (paragraphes: IParagraphe[]) => {
    paragraphes.sort((p1, p2) => p1.order - p2.order);
    return paragraphes;
  }

  sortByName = (chapters: IChapter[]) => {
    chapters.sort((c1, c2) => {
      if (c1.name < c2.name) { return -1; }
      if (c1.name > c2.name) { return 1; }
      return 0;
    });
    return chapters;
  }

  mapParagrapheFromQuerySnapshot = (querySnapshot: firebase.firestore.QuerySnapshot): IParagraphe[] => {
    return querySnapshot.docs.map(doc => {
      return {
        key: doc.id,
        ...doc.data()
      } as IParagraphe;
    })
  }

  mapChapterFromQuerySnapshot = (querySnapshot: firebase.firestore.QuerySnapshot): IChapter[] => {
    return querySnapshot.docs.map(doc => {
      return {
        key: doc.id,
        ...doc.data()
      } as IChapter;
    })
  }

  mapChapterToChapterWithPars = (projectId: string, chapter: IChapter): Promise<IChapterWithPars> => {
    const paragraphesPromise = this.db.collection(`projects/${projectId}/chapter/${chapter.key}/paragraphes`
      , ref => ref.orderBy('order'))
      .get().toPromise()
      .then(this.mapParagrapheFromQuerySnapshot);
    return paragraphesPromise.then(pars => ({ chapter, pars }));
  }

  chapterFromFirestore = (change: DocumentChangeAction<unknown>): IChapter => {
    const original: any = change.payload.doc.data();
    return {
      key: change.payload.doc.id,
      name: original.name,
      order: original.order,
      type: original.type ? original.type : "text"
    } as IChapter;
  }

  reorderParagraphs(chapterKey: string) {
    var batch = this.db.firestore.batch();

    this.paragraphes.forEach(paragraph => {
      const docRef = this.db.firestore.doc(`chapters/${chapterKey}/paragraphes/${paragraph.key}`);
      batch.update(docRef, { order: paragraph.order });
    });

    return batch.commit();
  }

  updateParagraphe(projectId: string, chapterKey: string, paragraphe: IParagraphe) {
    return this.db.doc(`projects/${projectId}/chapter/${chapterKey}/paragraphes/${paragraphe.key}`).update(paragraphe);
  }

  updateParagraphes = (chapterKey: String, paragraphe: IParagraphe): Promise<void> => {
    return this.db.doc(`chapters/${chapterKey}/paragraphes/${paragraphe.key}`).update(paragraphe);
  }

  updateChapter(projectId: string, chapter: IChapter) {
    return this.db.doc(`projects/${projectId}/chapter/${chapter.key}`).update(chapter);
  }

  reorderParagraphes(projectId: string, chapterKey: string) {
    var batch = this.db.firestore.batch();
    this.paragraphes.forEach(paragraph => {
      const docRef = this.db.firestore.doc(`projects/${projectId}/chapter/${chapterKey}/paragraphes/${paragraph.key}`);
      batch.update(docRef, { order: paragraph.order });
    });
    return batch.commit();
  }

  deleteChapters = (chapter: IChapter): Promise<void> => {
    if (chapter.type === "text") {
      return this.db.firestore.collection(`chapters/${chapter.key}/paragraphes`).get()
        .then(this.mapParagrapheFromQuerySnapshot)
        .then(paragraphes => {
          const batch = this.db.firestore.batch();
          paragraphes.forEach(paragraphe => {
            batch.delete(this.db.firestore.doc(`chapters/${chapter.key}/paragraphes/${paragraphe.key}`));
          });
          batch.delete(this.db.firestore.doc(`chapters/${chapter.key}`));
          return batch.commit();
        });
    }
  }

  deleteParagraphes = (chapterKey: string, paragraphe: IParagraphe) => {
    return this.db.doc(`chapters/${chapterKey}/paragraphes/${paragraphe.key}`)
      .delete();
  }

  deleteParagraphe = (projectId: string, chapterKey: string, paragraphe: IParagraphe) => {
    return this.db.doc(`projects/${projectId}/chapter/${chapterKey}/paragraphes/${paragraphe.key}`)
      .delete();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromAll();
  }

  unsubscribeFromParagraphes(): void {
    if (this.paragraphesSubscription) {
      this.paragraphesSubscription.unsubscribe();
    }
  }

  unsubscribeFromAll(): void {
    if (this.chaptersSubscription) {
      this.chaptersSubscription.unsubscribe();
    }
    if (this.paragraphesSubscription) {
      this.paragraphesSubscription.unsubscribe();
    }
    if (this.chaptersTemplatesSubscription) {
      this.chaptersTemplatesSubscription.unsubscribe();
    }
    if (this.chaptersWithParsSubscription) {
      this.chaptersWithParsSubscription.unsubscribe();
    }
  }

  cancelChaptersSubscription() {
    if (this.chapterSubscribe) {
      this.chapterSubscribe.unsubscribe();
    }
  }

  cancelParagraphesSubscription() {
    if (this.paragraphesSubscription) {
      this.paragraphesSubscription.unsubscribe();
    }
  }

  getChaptersPreview = (actions: DocumentChangeAction<unknown>[]): IChapter[] => {
    return actions.map(a => {
      const data = a.payload.doc.data() as any;
      const key = a.payload.doc.id;
      if (data && key) {
        data.key = key;
      }
      return data as IChapter;
    })
  }
}
