<div class="content">
  <button mat-raised-button type="button" color="primary" (click)="addBankAccount()">Ajouter un compte en banque</button>

  <div class="table">
    <table mat-table [dataSource]="this.dataSource" matSort matSortActive="name" matSortDirection="asc" (matSortChange)="sortData($event)">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
        <td mat-cell *matCellDef="let account"> {{account.name}} </td>
      </ng-container>
      <!-- <ng-container matColumnDef="domiciliation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Domiciliation</th>
        <td mat-cell *matCellDef="let account"> {{account.domiciliation}} </td>
      </ng-container> -->
      <ng-container matColumnDef="iban">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>IBAN</th>
        <td mat-cell *matCellDef="let account"> {{account.iban}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let rowData; columns: displayedColumns" (click)="selectAccount(rowData)" class="table-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5,10,25]"></mat-paginator>
  </div>
</div>

