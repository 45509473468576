<div class="inputs" *ngIf="hasDataBeenLoaded && invoice && client">
  <div class="inputs-line">
    <mat-form-field appearance="outline">
      <mat-label>Entité</mat-label>
      <mat-select [(ngModel)]="invoice.entity" (selectionChange)="onEntityChange($event)">
        <mat-option *ngFor="let entity of entities" [value]="entity.key">
          {{entity.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Client</mat-label>
      <input matInput placeholder="Client" [(ngModel)]="clientName" name="client" required disabled>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Compte en banque</mat-label>
      <mat-select [(ngModel)]="invoice.bankAccount" name="bankAccount" required [disabled]="!isEntitySelected" (selectionChange)="onBankAccountChange($event)">
        <mat-option *ngFor="let account of bankAccounts" [value]="account.id">
          {{account.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Compte Comptable</mat-label>
      <mat-select [(ngModel)]="invoice.accountingAccount" required>
        <mat-option *ngFor="let account of accountingAccounts" [value]="account.id">
          {{account.code}} - {{account.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Echéance</mat-label>
      <mat-select [(ngModel)]="invoice.due" required>
        <mat-option *ngFor="let due of DUE_VALUES" [value]="due">
          {{ due | dueValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Type de facture</mat-label>
      <mat-select [(ngModel)]="invoice.type" required (selectionChange)="udpateInvoicePrefix()">
        <mat-option *ngFor="let type of TYPES_VALUES" [value]="type">
          {{ type | invoiceTypeValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="inputs-line">
    <mat-form-field appearance="outline">
      <mat-label>Numéro de facture</mat-label>
      <input matInput placeholder="Numéro de facture" [(ngModel)]="invoice.invoiceNumber" [formControl]="invoiceNumberControl" name="invoiceNum" required (ngModelChange)="onInvoiceNumberChange()">
      <mat-error *ngIf="invoiceNumberControl.invalid">
        Numéro de facture déjà utilisé
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Libellé</mat-label>
      <input matInput placeholder="Libellé" [(ngModel)]="invoice.label" name="label" required>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Numéro de commande</mat-label>
      <input matInput placeholder="Numéro de commande" [(ngModel)]="deliveryNumber" name="deliveryNumber" (ngModelChange)="onInvoiceChange()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Numéro de contrat</mat-label>
      <input matInput placeholder="Numéro de contrat" [(ngModel)]="project.numContract" name="contractNumber" (ngModelChange)="onInvoiceChange()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Numéro de fournisseur</mat-label>
      <input matInput placeholder="Numéro de fournisseur" [(ngModel)]="invoice.supplierNumber" name="supplierNumber" (ngModelChange)="onInvoiceChange()">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Moyen de paiement</mat-label>
      <mat-select [(ngModel)]="invoice.paymentMean" required>
        <mat-option *ngFor="let mean of MEANS_VALUES" [value]="mean">
          {{ mean | paymentMean }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="inputs-line">
    <mat-form-field appearance="outline" class="comment-section">
      <mat-label>Commentaire</mat-label>
      <input matInput placeholder="Commentaire" [(ngModel)]="invoice.comment" name="comment">
    </mat-form-field>
  </div>
</div>
