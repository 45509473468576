import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IClient } from '@core/models/client.models';
import { ClientSearch } from '@core/models/search.model';
import { IUser } from '@core/models/users.models';
import { AuthService } from '@core/services/auth.service';
import { ClientsService } from '@core/services/clients.service';
import { phoneValidator } from '@shared/validators/phone.validator';
import { siretValidator } from '@shared/validators/siret.validator';
import { vatNumberValidator } from '@shared/validators/vat-number.validator';
import { Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-client-creation',
  templateUrl: './client-creation.component.html',
  styleUrls: ['./client-creation.component.scss']
})
export class ClientCreationComponent implements OnInit, OnDestroy {

  public user: IUser;
  private _user: Subscription;

  public searchClient: FormGroup = new FormGroup({
    name: new FormControl(''),
    siret: new FormControl(''),
  });

  public disabledClientCreation: boolean = true;

  public clientForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    buldingInfo: new FormControl(''),
    address: new FormControl('', [Validators.required]),
    additionalAddress: new FormControl(''),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required]),
    siret: new FormControl('', [siretValidator()]),
    vatNumber: new FormControl('', [vatNumberValidator()]),
    dueTerm: new FormControl(0, [Validators.required]),
    tvaRate: new FormControl(0),
    comments: new FormControl([]),
    inactif: new FormControl(false),
    incharge: new FormControl(null),
    logo: new FormControl(null),
    defaultAccountingAccount: new FormControl(null),
  });

  public alreadyExist: ClientSearch | null = null;

  public constructor(
    private readonly dialogRef: MatDialogRef<ClientCreationComponent>,
    private readonly clientService: ClientsService,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  public ngOnInit(): void {
    this._user = this.authService.user$.subscribe((user) => this.user = user);
  }

  public ngOnDestroy(): void {
    this._user?.unsubscribe();
  }

  public goToClient(clientSearch: ClientSearch): Promise<boolean> {
    this.dialogRef.close();
    return this.router.navigate(['client', 'manage', clientSearch.client?.key]);
  }

  public async createClient(): Promise<void> {
    const client: IClient = this.getClientFromForm();
    const result: ClientSearch[] = await this.clientService.searchFirebaseClient(client.society.siret, null);
    if (result && result.length > 0) {
      this.alreadyExist = result[0];
      return;
    } else {
      if (confirm('Voulez-vous vraiment créer ce client ?')) {
        const createdClient: IClient = await this.clientService.addClients(client);
        this.dialogRef.close();
        this.router.navigate(['client', 'manage', createdClient.key]);
      }
    }
  }

  public selectClient(clientSearch: ClientSearch | null): void {
    if (clientSearch === null) {
      this.clientForm.reset();
      return;
    }
    const client: IClient = clientSearch.client as IClient;
    this.clientForm.patchValue({
      name: client.name,
      buldingInfo: '',
      address: client.society.adress,
      additionalAddress: '',
      zipCode: client.society.zipCode,
      city: client.society.city,
      country: client.society.country,
      siret: client.society.siret,
      vatNumber: '',
      firstName: '',
      lastName: '',
      mail: '',
      phone: '',
      phoneFix: '',
      job: '',
      dueTerm: client.reglementDate,
      tvaRate: client.tvaRate,
      comments: client.comments,
      inactif: client.inactif,
      incharge: client.inCharge,
    });
  }

  private getClientFromForm(): IClient {
    return {
      added: {
        by: this.user?.key ?? null,
        date: new Date(),
      },
      comments: this.clientForm.get('comments').value ?? null,
      inCharge: this.clientForm.get('incharge').value?.key ?? null,
      inactif: this.clientForm.get('inactif').value ?? false,
      name: this.clientForm.get('name').value ?? null,
      firstName: this.clientForm.get('firstName').value ?? null,
      lastName: this.clientForm.get('lastName').value ?? null,
      reglementDate: this.clientForm.get('dueTerm').value ?? null,
      society: {
        adress: this.clientForm.get('address').value ?? null,
        city: this.clientForm.get('city').value ?? null,
        country: this.clientForm.get('country').value ?? null,
        intracommunautaire: this.clientForm.get('vatNumber').value ?? null,
        siret: this.clientForm.get('siret').value ?? null,
        specialMention: this.clientForm.get('additionalAddress').value ?? null,
        typeResidence: this.clientForm.get('buldingInfo').value ?? null,
        zipCode: this.clientForm.get('zipCode').value ?? null,
        logo: this.clientForm.get('logo').value ?? null,
      },
      nbProjects: 0,
      tvaRate: this.clientForm.get('tvaRate').value ?? null,
      defaultAccountingAccountId: this.clientForm.get('defaultAccountingAccount').value?.id ?? null,
    }
  }
}
