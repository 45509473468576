<div [hidden]="loading || edge">
  <div class ="downloadPdf">
    <button mat-raised-button class="btnDownload" color="primary" (click)="downloadPDF()">TELECHARGER LE PDF</button>
  </div>
  <embed id='pdfViewer' type="application/pdf" />
</div>
<div *ngIf="edge" fxLayout="column" fxLayoutAlign="center center">
  <mat-spinner diameter="40" *ngIf="loading"></mat-spinner>
  <h4 class="align">Ce navigateur ne prend pas en charge l'aperçu des PDF.</h4>
  <button mat-raised-button (click)="pdfDownload()" [disabled]="loading">Télécharger</button>
</div>
