<div>
  <button mat-raised-button class="addButtonInTableArticle" (click)="addArticle()">Ajouter un article</button>

  <table mat-table #table [dataSource]="articles" class="tabArticles" cdkDropList [cdkDropListData]="articles" (cdkDropListDropped)="dropTable($event)">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nom </th>
      <td mat-cell *matCellDef="let element">
        {{element.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Quantité </th>
      <td mat-cell *matCellDef="let element">
        {{element.amount}} {{services.unity}}
      </td>
    </ng-container>

    <ng-container matColumnDef="cost">
      <th mat-header-cell *matHeaderCellDef> Prix unitaire </th>
      <td mat-cell *matCellDef="let element">
        {{ element.cost.toFixed(2) }} €
      </td>
    </ng-container>

    <ng-container matColumnDef="prix_total">
      <th mat-header-cell *matHeaderCellDef> Prix </th>
      <td mat-cell *matCellDef="let element"> {{ totalPrice(element) }} €</td>
    </ng-container>

    <ng-container matColumnDef="modif">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button color="primary" (click)="editArticle(i,element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="suppr">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button class="red-icon" (click)="deleteArticle(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="DragAndDrop">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <i style="pointer-events: all;" cdkDragHandle class="fas fa-arrows-alt dragHandle"></i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"></tr>
  </table>

</div>

