import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IClient } from '@core/models/client.models';
import { ClientsService } from '@core/services/clients.service';
import { UserService } from '@core/services/user.service';
import { Subscription, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-client-gestion',
  templateUrl: './client-gestion.component.html',
  styleUrls: ['./client-gestion.component.scss']
})
export class ClientGestionComponent implements OnInit, OnDestroy {
  public currentClient: IClient = null;

  private _activatedRoute: Subscription

  public constructor(
    public userService : UserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly clientService: ClientsService
  ) { }

  public ngOnInit(): void {
    this._activatedRoute = this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => params.get('id')),
      switchMap((id: string) => id ? this.clientService.getClientById(id) : of(null))
    ).subscribe((client: IClient | null) => {
      this.setCurrentClient(client);
    })
  }

  public ngOnDestroy(): void {
    this._activatedRoute?.unsubscribe();
  }

  setCurrentClient(client : IClient) {
    this.currentClient = client;
  }

  public async deleteClient(client: IClient): Promise<void> {
    await this.clientService.deleteClient(client);
    this.currentClient = null;
  }

}
