import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { IArticle, IService } from '@core/models/service.models';
import { AuthService } from '@core/services/auth.service';
import { AddArticlesComponent } from '@pages/gest-services/modals/add-articles/add-articles.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss']
})
export class ArticleEditComponent implements OnInit {
  @ViewChild('table') table: MatTable<IArticle>;
  @Input() articles: IArticle[];
  @Input() services: IService;
  @Output() save = new EventEmitter();
  public currentService: IService;
  @Output() updateProject = new EventEmitter();
  txtQueryChanged = new Subject();
  constructor( public dialog: MatDialog,private authService: AuthService,private db: AngularFirestore) {
    this.txtQueryChanged
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.save.emit();
      });
  }

  displayedColumns: string[] = ["name", "amount", "cost", "prix_total", "modif", "suppr", "DragAndDrop"];

  ngOnInit() {
  }

  montantTotal() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return this.articles.map(article => article.amount * article.cost).reduce(reducer);
  }

  chargeTotal() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return this.articles.map(article => article.amount).reduce(reducer);
  }

  public totalPrice(article: IArticle): string {
    const amount: number = this.services.unity === '%'
      ? article.amount / 100
      : article.amount;
    return (amount * article.cost).toFixed(2);
  }

  changeValue() {
    this.txtQueryChanged.next();
  }

  saveCurrentService() {
    const toSave = { ...this.currentService };
    delete toSave['key'];
    toSave.updatedBy = this.authService.userFB.uid;
    toSave.updatedDate = new Date();
    this.db.doc('shoppedServices/' + this.currentService.key).update(toSave).then(() => {
      this.updateProject.emit();
    });
  }

  dropTable(event: CdkDragDrop<IArticle[]>) {
    moveItemInArray(this.articles, event.previousIndex, event.currentIndex);
    this.save.emit();
    this.table.renderRows();
  }

  addArticle() {
    const dialogRef = this.dialog.open(AddArticlesComponent, {
      width: '1100px',
      height:'800px',
      data: null
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.articles.push(result);
        this.save.emit();
        this.table.renderRows();
      }
    });
  }

  editArticle(index: number, article: IArticle) {
    const dialogRef = this.dialog.open(AddArticlesComponent, {
      width: '1100px',
      height:'800px',
      data: article
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.articles[index] = result;
        this.save.emit();
        this.table.renderRows();
      }
    });
  }

  deleteArticle(index) {
    this.articles.splice(index, 1);
    this.save.emit();
    this.table.renderRows();
  }
}
