import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IChapter } from '@core/models/chapters.models';
import { IProjet } from '@core/models/projet.models';
import { ChaptersService } from '@core/services/chapters.service';
import { CopyChapterComponent } from '@pages/gest-paragraphes/modals/copy-chapter/copy-chapter.component';

@Component({
  selector: 'app-paragraphe-project',
  templateUrl: './paragraphe-project.component.html',
  styleUrls: ['./paragraphe-project.component.scss']
})
export class ParagrapheProjectComponent implements OnInit {
  @Input() projet: IProjet;
  @Output() sendSelectedChapter = new EventEmitter();

  public chaptersTemplateDialogRef = null;
  public selectedChapter: IChapter = null;


  constructor(public chaptersTemplateDialog: MatDialog,
    public chaptersService: ChaptersService) { }

  ngOnDestroy(): void {
    this.chaptersService.unsubscribeFromAll();
  }

  ngOnInit() {
    this.chaptersService.getOnceAllTemplateChapters();
    this.chaptersService.subscribeToChapters(this.projet.key);
    this.chaptersService.subscribeToChaptersWithPars(this.projet.key);
  }

  openChaptersTemplate = () => {
    this.chaptersTemplateDialogRef = this.chaptersTemplateDialog.open(
      CopyChapterComponent,
      {
        height: '400px',
        width: '600px',
        data: {
          chaptersTemplates: this.chaptersService.chaptersTemplates
        }
      });
    this.chaptersTemplateDialogRef.afterClosed()
      .subscribe(chapters => {
        if(chapters && chapters.length > 0) {
          const promises = chapters.map(chapter =>
            this.chaptersService.copyChapterTemplate(this.projet.key, chapter));
          Promise.all(promises)
            .catch(err => console.error(err));
        }
      });
  }

  addChapter = () => {
    const lastOrder = this.chaptersService.chapters.length > 0
      ? Math.max(...this.chaptersService.chapters.map(c => c.order))
      : -1;
    const chapter: IChapter = {
      name: "Default name",
      type: "text",
      order: lastOrder + 1,
      paragraphes: []


    };
    return this.chaptersService.addChapter(this.projet.key, chapter);
  }

  reorderChapters(event: CdkDragDrop<IChapter[]>) {
    moveItemInArray(this.chaptersService.chapters, event.previousIndex, event.currentIndex);
    for (var i = 0; i < this.chaptersService.chapters.length; i++) {
      this.chaptersService.chapters[i].order = i;
    }
    this.chaptersService.reorderChapters(this.projet.key);
  }

  selectChapter(chapter) {
    if(this.selectedChapter === chapter) {
      this.selectedChapter = null;
    } else {
      this.selectedChapter = chapter;
      this.chaptersService.unsubscribeFromParagraphes();
      this.chaptersService.subscribeToParagraphes(this.projet.key, chapter.key);
      this.chaptersService.chapterSelectedSubject.next(chapter);
    }
    this.sendSelectedChapter.emit(this.selectedChapter);
  }

}
