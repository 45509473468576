import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { IUser } from '@core/models/users.models';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  public users: IUser[];
  private usersSub: Subscription;
  constructor(
    private db: AngularFirestore,
    ) { }

  init() {
    this.usersSub = this.db.collection('users').snapshotChanges()
      .pipe(
        map((values: DocumentChangeAction<unknown>[]) =>
          values.map((doc: DocumentChangeAction<unknown>) => {
            return {
              key: doc.payload.doc.id, ...doc.payload.doc.data() as any
            } as IUser;
          })
        )
      ).subscribe((data) => {
        this.users = data.filter(client => client.firstName && !client.disabled).sort((a,b) =>{
          return ('' + a.firstName).localeCompare(b.firstName);}
        );
      });
  }

  getUsersWithKey(key: string) {
    return this.users.find(e => e.key === key);
  }

  cancelSubscribe() {
    if (this.usersSub) {
      this.usersSub.unsubscribe();
    }
  }

  public get users$(): Observable<IUser[]> {
    return this.db.collection('users').snapshotChanges().pipe(
      map((values: DocumentChangeAction<unknown>[]) => {
        return values.map((doc: DocumentChangeAction<unknown>) => {
          return {
            key: doc.payload.doc.id, ...doc.payload.doc.data() as any
          } as IUser;
        }).filter(client => client.firstName && !client.disabled).sort((a,b) =>{
          return ('' + a.firstName).localeCompare(b.firstName);
        });
      })
    );
  }

}

