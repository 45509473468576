<div class="headerContent" fxLayout="row" fxLayoutAlign="space-between center">
  <div>
    <div fxLayout="column" fxLayoutAlign="start start">
      <h1>Projets du client : <span *ngIf="currentClient()">{{ currentClient().name }} </span></h1>
      <button mat-raised-button (click)="addProjet()" type="button" color="primary">Ajouter un projet</button>
    </div>
  </div>
  <div>
    <mat-form-field>
      <i matPrefix class="fas fa-search"></i>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Recherche projets">
    </mat-form-field>
  </div>
</div>
<div class="mat-elevation-z8 table">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>
    <ng-container matColumnDef="dateUpdate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de dernière modification</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.updated && element.updated.date" class ="dateLast">
          {{ element.updated.date.seconds * 1000 | date:'dd/MM/yyyy à HH:mm' }}
        </span>
        <button (click)="stopProp($event)" mat-button [matMenuTriggerFor]="menu" class="btnModif"><i class="fas fa-bars"></i></button>
            <mat-menu #menu="matMenu">
              <button (click)="onUpdate(element.key)"mat-menu-item>Modifier</button>
              <button (click)="onDelete(element.key)"mat-menu-item>Supprimer</button>
              <button (click)="copyProject(element.key)"mat-menu-item>Copier projet</button>
            </mat-menu>
      </td>
    </ng-container>
    <ng-container matColumnDef="dateAjout">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date d'ajout</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.added && element.added.date">
            {{ element.added.date.seconds * 1000 | date:'dd/MM/yyyy' }}
        </span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showProjet(row.key)" class="row">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
