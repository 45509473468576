import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IService } from '@core/models/service.models';
import { ProjetsService } from '@core/services/projets.service';
import { ServicesService } from '@core/services/services.service';

@Component({
  selector: 'app-select-services',
  templateUrl: './select-services.component.html',
  styleUrls: ['./select-services.component.scss']
})
export class SelectServicesComponent implements OnInit {


  public currentService = null;


  constructor(public projectsService: ProjetsService,public serviceService: ServicesService,private route: ActivatedRoute) {  }


  ngOnInit() {

  }

  setCurrentService(service : IService) {
    this.currentService = service;
  }

  updateProjectPrice() {
    let currentDate = new Date();
    this.projectsService.updateProjectPriceAndDate(this.projectsService.currentProject.key,
      this.serviceService.getMontantTotalShoppedServices(),currentDate);
  }

}
