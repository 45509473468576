import { Pipe, PipeTransform } from "@angular/core";
import { TvaValueLabel } from "@pages/projects/components/invoice/invoice-header/invoice-header.component";
@Pipe({
    name: 'tvaValue'
})
export class TvaValuePipe implements PipeTransform {
  
    public transform(value: number): string {
      return TvaValueLabel.find((due) => due.value === value)?.label || value.toString();
    }
}