import { formatDate } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { IBankAccount } from '@core/models/bank.model';
import { IClient } from '@core/models/client.models';
import { IEntity } from '@core/models/entity.model';
import { IFactureItem, IFacturX } from '@core/models/facture.model';
import { Invoice, InvoiceDevisExpress, InvoiceLine } from '@core/models/invoice.model';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { BankAccountService } from '@core/services/bank-account.service';
import { ClientsService } from '@core/services/clients.service';
import { CountryService } from '@core/services/country.service';
import { EntityService } from '@core/services/entity.service';
import { FacturXService } from '@core/services/factur-x.service';
import { InvoiceDevisExpressService } from '@core/services/invoice-devis-express.service';
import { InvoiceService } from '@core/services/invoice.service';

import moment from 'moment';
import { firstValueFrom, map, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DevisInvoiceHeaderComponent } from './components/devis-invoice-header/devis-invoice-header.component';

@Component({
  selector: 'app-devis-express',
  templateUrl: './devis-express.component.html',
  styleUrls: ['./devis-express.component.scss']
})
export class DevisExpressComponent implements OnInit, OnDestroy, AfterViewInit {
@ViewChild('appDevisInvoiceHeader') public invoiceHeader: DevisInvoiceHeaderComponent;

  /** Is loading  */
  public isLoading: boolean = false;

  /** The project */
  public project: IProjet;

  /** The project subscription */
  private _project: Subscription;

  public projectId: string;

  /** The invoice */
  @Input() public invoice: InvoiceDevisExpress;

  /** The client */
  public client: IClient;

  /** The selected entity */
  public selectedEntity: IEntity;

  /** The logo of entity */
  public entityLogo: string;

  /** The entity name */
  public entityName: string;

  /** The client name */
  public clientName: string;

  /** The bank name */
  public bankName: string;

  /** The selected bank account */
  public selectedBankAccount: IBankAccount;

  /** The service */
  public service: IService;

  /** The data url for preview  */
  public dataUrl: string;

  /** Check if the invoice is validated */
  public hasInvoiceBeenValidated: boolean = true;

  /**
   * Creates an instance of DevisExpressComponent.
   * @param activatedRoute of ActivatedRoute
   * @param router of the router
   * @param invoiceService of InvoiceService
   * @param invoiceDevisExpressService of InvoiceDevisExpressService
   * @param clientsService of ClientsService
   * @param bankAccountService of BankAccountService
   * @param entityService of EntityService
   * @param facturxService of FacturXService
   * @param _snackBar of MatSnackBar
   *
   */
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly invoiceService: InvoiceService,
    private readonly invoiceDevisExpressService: InvoiceDevisExpressService,
    private readonly clientService: ClientsService,
    private readonly bankAccountService: BankAccountService,
    private readonly entityService: EntityService,
    private readonly _snackBar: MatSnackBar
  ) { }

  /**
   * Called after Angular has fully initialized the component's view.
   * Defines a check to ensure the 'invoiceHeader' ViewChild is defined.
   * If 'invoiceHeader' is not defined, it logs an error message to the console.
   */
  public ngAfterViewInit() {
    if (!this.invoiceHeader) {
      console.error('invoiceHeader ViewChild undefined');
    }
  }

   /**
   * Initializes the component
   *
   * @returns {void}
   * @memberof DevisExpressComponent
  */
  public ngOnInit(): void {
    this._project = this.activatedRoute.paramMap.pipe(
      map((params) => params.get('key'))
    ).subscribe((projectId: string) => this.projectId = projectId);
    const invoice: InvoiceDevisExpress = {
      id: '',
      entity: '',
      client: '',
      bankAccount: '',
      due: '',
      type: 0,
      invoiceNumber: '',
      label: '',
      deliveryNumber: '',
      contractNumber: '',
      clientNumber: '',
      lines: [],
      hasBeenSent: false,
      plannedDate: new Date(),
      dueLabel: '',
      amount: 0,
      paymentMean: '',
      accountingAccount: '',
      comment: ''
    };

    if (this.invoice) {
      this.hasInvoiceBeenValidated = this.invoice.hasBeenSent;
    } else {
      this.invoice = invoice;
    }
  }

   /**
   * Unsubscribes from the project observable
   *
   * @returns {void}
   * @memberof DevisExpressComponent
   */
  public ngOnDestroy(): void {
    this._project?.unsubscribe();
  }

  /**
   * Updates the current invoice with the provided invoice data.
   *
   * @param invoice - The new invoice data to replace the current invoice.
   * @returns {void} - This function does not return a value.
   * @memberof DevisExpressComponent
   */
  public updateInvoice(invoice: InvoiceDevisExpress): void {
    this.invoice = invoice;

  }

  /**
   * Handles the change of bank account selection.
   *
   * @param accountId - The ID of the selected bank account.
   * @returns {Promise<void>} - A promise that resolves when the bank account details are fetched.
   * @memberof DevisExpressComponent
   */
  public async onAccountChange(accountId: string): Promise<void> {
    this.selectedBankAccount = await firstValueFrom(this.bankAccountService.getBankAccountById(accountId));
  }

  /**
   * Handles the validation of the devis express invoice.
   *
   * @returns {Promise<void>} - A promise that resolves when the invoice is validated.
   * @memberof DevisExpressComponent
   */
  public async onValidate(): Promise<void> {
    return this.invoiceDevisExpressService.validateDevisExpress(this.invoice, this.calculateLineAmount).then(() => {
      this._snackBar.open('La facture a bien été validée', 'Fermer', {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['green-snackbar']
      });
    });
  }

  /**
   * Generates a PDF document based on the provided invoice data.
   *
   * @returns {Promise<void>} - A promise that resolves when the PDF generation is complete.
   * @memberof DevisExpressComponent
   */
  public async generatePdf(): Promise<void> {
    try {
      const invoiceHeaderData: InvoiceDevisExpress = this.invoiceHeader.getDevisFromForm();

      invoiceHeaderData.lines = this.invoice.lines;

      const [bankAccount, client, entity] = await Promise.all([
        firstValueFrom(this.bankAccountService.getBankAccountById(invoiceHeaderData.bankAccount)),
        firstValueFrom(this.clientService.getClientById(invoiceHeaderData.client)),
        firstValueFrom(this.entityService.getEntity(invoiceHeaderData.entity))
      ]);
      this.selectedBankAccount = bankAccount;
      this.client = client;
      this.selectedEntity = entity;

      if (!this.selectedEntity || !this.selectedEntity.logo) {
        this._snackBar.open('Veuillez sélectionner une entité avec un logo', 'Fermer', {
          duration: 3000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
        });
        return;
      }
      this.entityLogo = await firstValueFrom(this.entityService.getEntityLogo(this.selectedEntity));
      const pdf: any = await this.invoiceDevisExpressService.createPdfDevisExpress(invoiceHeaderData, this.client, this.selectedEntity, this.selectedBankAccount, this.entityLogo, this.calculateLineAmount);
      this.invoiceService.generatePdf(pdf).getDataUrl((dataUrl: string) => {
        this.dataUrl = dataUrl;
      });
    } catch (error) {
      console.error('Erreur lors de la génération du PDF:', error);
      this._snackBar.open('Erreur lors de la génération du PDF', 'Fermer', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      });
    }
  }

  /**
   * Generates a PDF document with Factur-X data.
   *
   * @returns {Promise<void>} - A promise that resolves when the PDF generation with Factur-X is complete.
   * @memberof DevisExpressComponent
   */
  public async generatePdfWithFacturx(): Promise<void> {
    // download dataUrl
    const a = document.createElement('a');
    a.href = this.dataUrl;
    const clientName = this.client?.name ? this.client.name : `${this.client.lastName} ${this.client.firstName}`;
    a.download = `Devis${this.invoice.invoiceNumber}-${clientName}_${this.invoice.label}.pdf`;
    a.click();
    a.remove();
  }

  /** 
   * Handles the navigation back to the previous page.
   *
   * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating whether navigation was successful.
   * @memberof DevisExpressComponent
   */
  public return(): Promise<boolean> {
    return this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }

  private calculateLineAmount(amount: number, unitPrice: number, unit: string, tvaRate: string): number {
    if(unit === 'P1') {
      return +(amount * unitPrice / 100).toFixed(2) * (1 + Number(tvaRate) / 100);
    } else {
      return +(amount * unitPrice).toFixed(2) * (1 + Number(tvaRate) / 100);
    }
  }


  /**
  * Saves the current devis express invoice.
  *
  * @returns {Promise<void>} - A promise that resolves when the invoice is saved.
  * @memberof DevisExpressComponent
  */
  public async saveInvoice(): Promise<boolean> {
    const headerData = this.invoiceHeader.getDevisFromForm();
    headerData.id = this.invoice.id;
    headerData.lines = this.invoice.lines;
    if (this.invoice.id) {
      await this.invoiceDevisExpressService.updateInvoiceDevisExpress(this.projectId, headerData, this.invoice.lines, this.calculateLineAmount).then(() => {
        this._snackBar.open('Facture sauvegardée avec succès', 'x', {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['green-snackbar']
        });
      })
    } else {
      try {
          const id: string = await this.invoiceDevisExpressService.addProjectDevisExpress(headerData, this.invoice.lines, this.calculateLineAmount);
          this._snackBar.open('Facture sauvegardée avec succès', 'x', {
              duration: 4000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              panelClass: ['green-snackbar']
          });
          return this.router.navigate(['project', id]);
      } catch (error) {
          console.error('Erreur lors de la sauvegarde du devis:', error);
          this._snackBar.open('Erreur lors de la sauvegarde', 'x', {
              duration: 4000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              panelClass: ['red-snackbar']
          });
      }
    }
  }

}

