<div fxLayout="row" fxLayoutAlign="start none">
  <div class="container" fxFlex>
    <div fxLayout="row" >
      <app-paragraphe-project (sendSelectedChapter)="setCurrentChapter($event)"
        [projet]="projectService.currentProject">
      </app-paragraphe-project>
    </div>
    <div class="container-edit" fxFlex="80">
      <app-modify-paragraphe [inCurrentProject]=true [projet]="projectService.currentProject"
        [currentChapter]="currentChapter"></app-modify-paragraphe>
    </div>
  </div>
  <div class="container" fxFlex>
    <app-show-pdf [projet]="projectService.currentProject" [shoppedServices]="[servicesService.shoppedServices]"
      [chapters]="chaptersService.chaptersWithPars">
    </app-show-pdf>
  </div>
</div>
