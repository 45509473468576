import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { IContact } from '@core/models/contacts.model';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private contacts$$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private contactsByParent$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private readonly COLLECTION_NAME: string = 'contacts';

  public contactType: string[] = ['Opérationnel', ...['Comptabilité','Facturation','Responsable Compte', 'Commercial Éditeur', 'Direction', 'DAF', 'Juridique'].sort((a, b) => a.localeCompare(b))];

  constructor(
    private http: HttpClient,
    private readonly firestore: AngularFirestore
  ) { }

  public get contacts$(): Observable<IContact[]> {
    return this.contacts$$.pipe(
      switchMap(() => this.getContacts())
    );
  }

  public updateContactsList(): void {
    this.contacts$$.next(null);
  }

  public get contactByParent$(): Observable<IContact[]> {
    return this.contactsByParent$$.pipe(
      switchMap((parentId: string) => parentId ? this.getContacts().pipe(map((contacts: IContact[]) => contacts.filter((contact: IContact) => contact.parentId === parentId))) : this.getContacts())
    )
  }

  public updateContactsByParent(parentId: string): void {
    this.contactsByParent$$.next(parentId);
  }

  public getContacts(): Observable<IContact[]> {
    return this.contactCollection.valueChanges({idField: 'id'});
  }

  public getContactsById(id: string): Observable<IContact> {
    return this.contactCollection.doc<IContact>(id).valueChanges().pipe(
      map((contact: IContact) => ({
        ...contact,
        id: id
      }))
    )
  }

  public getContactByParentId(parentId: string): Observable<IContact[]> {
    return this.contacts$.pipe(
      map((contacts: IContact[]) => contacts.filter((contact: IContact) => contact.parentId === parentId))
    )
  }

  public addContacts(contact: IContact): Observable<IContact> {
    return from(this.contactCollection.add({...contact})).pipe(
      switchMap((ref: DocumentReference) => from(ref.get()).pipe(
        map<DocumentSnapshot<IContact>, IContact>((doc) => ({
          ...doc.data(),
          id: doc.id
        }))
      ))
    )
  }

  public updateContacts(contact: IContact): Observable<void> {
    const id: string = contact.id;
    delete contact.id;
    return from(this.contactCollection.doc(id).update({...contact}));
  }

  public deleteContacts(id: string): Observable<void> {
    return from(this.contactCollection.doc(id).delete());
  }

  private get contactCollection(): AngularFirestoreCollection<IContact> {
    return this.firestore.collection<IContact>(this.COLLECTION_NAME);
  }

}
