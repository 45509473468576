import { Component, OnInit } from '@angular/core';
import { IChapter } from '@core/models/chapters.models';

@Component({
  selector: 'app-gest-para',
  templateUrl: './gest-para.component.html',
  styleUrls: ['./gest-para.component.scss']
})
export class GestParaComponent implements OnInit {
  public currentChapter: IChapter = null;
  constructor() { }

  ngOnInit(): void {
  }

  setCurrentChapter(chapter : IChapter) {
    this.currentChapter = chapter;
  }

}
