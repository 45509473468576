<div fxLayout="row">
  <div class="container" fxFlex>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1>Catalogue des services</h1>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="45%">
      <app-services-list (sendCurrentService)="setCurrentService($event)"></app-services-list>
    </div>
  </div>
  <div class="container" fxFlex>
    <div fxLayout="column" fxLayoutAlign="center center">
      <app-modify-services [inCurrentProject] = "false" [currentService]=(currentService)></app-modify-services>
    </div>
  </div>
</div>
