<h1 mat-dialog-title>Nouveau compte en banque</h1>
<form mat-dialog-content (ngSubmit)="bankForm.valid && onSubmit()" [formGroup]="bankForm">
  <div class="column">
    <div class="row">
      <mat-form-field>
        <mat-label>Nom*</mat-label>
        <input matInput formControlName="name" name="name" autocomplete="off">
      </mat-form-field>
      <!-- <mat-form-field>
        <mat-label>Domiciliation</mat-label>
        <input matInput formControlName="domiciliation" name="domiciliation" autocomplete="off">
      </mat-form-field> -->
      <mat-form-field>
        <mat-label>Devise*</mat-label>
        <mat-select matInput formControlName="devise" autocomplete="off">
          <mat-option *ngFor="let devise of this.devises" [value]="devise">
            {{devise}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>IBAN*</mat-label>
        <input matInput formControlName="iban" autocomplete="off" (change)="onIbanChange()">
        <mat-error *ngIf="ibanControl.invalid" [matTooltip]="getIbanErrorMessage()">{{ getIbanErrorMessage() }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>BIC*</mat-label>
        <input matInput formControlName="bic" autocomplete="off">
        <mat-error *ngIf="bicControl.invalid" [matTooltip]="getBicErrorMessage()">{{ getBicErrorMessage() }}</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Date de début d'utilisation*</mat-label>
        <input matInput [matDatepicker]="startDatepicker" formControlName="startDate" (dateChange)="updateEndDate()">
        <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatepicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Date de fin d'utilisation</mat-label>
        <input matInput [matDatepicker]="endDatepicker" formControlName="endDate" [min]="minDate">
        <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatepicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="validate">
      <button mat-button (click)="onCancel()" type="button">Annuler</button>
      <button mat-button color="primary" type="submit" [disabled]="bankForm.invalid">Ajouter</button>
    </div>
  </div>
</form>







