<h2>Caractéristiques de l'article</h2>
<div fxLayout="row wrap" fxLayoutAlign="space-around center">

  <mat-form-field appearance="outline" class="name">
    <mat-label>Nom</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.name">
  </mat-form-field>

  <mat-slide-toggle class="barre" color="primary" [checked]="data.formation" (change)="data.formation = !data.formation">
    Lier à une formation
  </mat-slide-toggle>

  <mat-form-field appearance="outline" class="small-input">
    <mat-label>Quantité</mat-label>
    <input matInput type="number" autocomplete="off" min="0" [(ngModel)]="data.amount">
  </mat-form-field>

  <mat-form-field appearance="outline" class="small-input">
    <mat-label>Prix en €</mat-label>
    <input matInput type="number" autocomplete="off" min="0" [(ngModel)]="data.cost">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Hors Périmètre</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.outOfBounds">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Livrables</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.deliverables">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Moyens Techniques</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.technicalMeans">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Prérequis</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.requirements">
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Objectif</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.objectifs">
  </mat-form-field>

  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Principales activités</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.mainActivities">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Profil Client</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.profilsClient">
  </mat-form-field>

  <mat-form-field appearance="outline" class="example-full-width">
    <mat-label>Profil préstataire</mat-label>
    <input matInput type="text" autocomplete="off" [(ngModel)]="data.profilsPresta">
  </mat-form-field>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-button color="primary" [mat-dialog-close]="data">{{ action }}</button>
  </div>
