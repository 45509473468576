import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { ISociete } from '@core/models/client.models';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocietyService {

  public society$$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  public researchName$$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  private readonly COLLECTION_NAME: string = 'society';

  constructor(
    private http: HttpClient,
    private readonly firestore: AngularFirestore
  ) { }

  public get society$(): Observable<ISociete[]> {
    return this.society$$.pipe(
      switchMap(() => this.getSociety())
    )
  }

  public updateSocietyList(): void {
    this.society$$.next(null);
  }

  public get research$(): Observable<ISociete[]> {
    return this.researchName$$.pipe(
      switchMap((name: string) => name ? this.researchSocietyByName(name) : [])
    );
  }

  public updateResearchList(name: string): void {
    this.researchName$$.next(name);
  }

  public getSociety(): Observable<ISociete[]> {
    return this.firestore.collection<ISociete>(this.COLLECTION_NAME).valueChanges({idField: 'id'});
  }

  public getSocietyById(id: string): Observable<ISociete> {
    return this.firestore.collection<ISociete>(this.COLLECTION_NAME).doc<ISociete>(id).valueChanges().pipe(
      map((society: ISociete) => ({
        ...society,
        id: id
      }))
    )
  }

  public addSociety(society: ISociete): Observable<ISociete> {
    return from(this.firestore.collection<ISociete>(this.COLLECTION_NAME).add({...society})).pipe(
      switchMap((ref: DocumentReference) => from(ref.get()).pipe(
        map<any, ISociete>((doc) => ({
          ...doc.data(),
          id: doc.id
        })))
    ))
  }

  public updateSociety(society: ISociete): Observable<void> {
    const id: string = society.id;
    delete society.id;
    return from(this.firestore.collection<ISociete>(this.COLLECTION_NAME).doc(id).update({...society}));
  }

  public deleteSociety(id: string): Observable<void> {
    return from(this.firestore.collection<ISociete>(this.COLLECTION_NAME).doc(id).delete());
  }

  public researchSocietyByName(name: string): Observable<ISociete[]> {
    const url: string = `${environment.nodeProtocol}://${environment.nodeURL}/papper/name/${name}`;
    return this.http.get<ISociete[]>(url);
  }

}
