import { NgModule } from '@angular/core';
import {DebounceModule} from 'ngx-debounce';

import { GestParagraphesRoutingModule } from './gest-paragraphes-routing.module';
import { GestParaComponent } from './components/gest-para/gest-para.component';
import { ParagrapheListComponent } from './components/paragraphe-list/paragraphe-list.component';
import { ModifyParagrapheComponent } from './components/modify-paragraphe/modify-paragraphe.component';
import { SharedModule } from '@shared/shared.module';
import { ParagrapheProjectComponent } from './components/paragraphe-project/paragraphe-project.component';
import { CopyChapterComponent } from './modals/copy-chapter/copy-chapter.component';


@NgModule({
  declarations: [GestParaComponent, ParagrapheListComponent, ModifyParagrapheComponent, ParagrapheProjectComponent, CopyChapterComponent],
  imports: [
    SharedModule,
    GestParagraphesRoutingModule,
    DebounceModule
  ],
  exports : [
    ParagrapheProjectComponent,
    ModifyParagrapheComponent
  ]
})
export class GestParagraphesModule { }
