import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IService } from '@core/models/service.models';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'app-modify-services',
  templateUrl: './modify-services.component.html',
  styleUrls: ['./modify-services.component.scss']
})
export class ModifyServicesComponent implements OnInit {
  @Input() currentService: IService = null;
  @Input() inCurrentProject = null;
  @Output() updateProject = new EventEmitter();
  trashHover = false;
  disableHover = false;

  constructor(private db: AngularFirestore, private authService: AuthService) { }

  ngOnInit(): void {
  }

  changeTitle() {
    const newService = { ...this.currentService };
    this.db.doc(`services/${this.currentService.key}`).update(newService);
  }

  saveCurrentService() {
    const toSave = { ...this.currentService };
    delete toSave.key;
    toSave.updatedBy = this.authService.userFB.uid;
    toSave.updatedDate = new Date();
    this.db.doc('services/' + this.currentService.key).update(toSave);
  }

  saveCurrentShoppedService() {
    const toSave = { ...this.currentService };
    delete toSave['key'];
    toSave.updatedBy = this.authService.userFB.uid;
    toSave.updatedDate = new Date();
    this.db.doc('shoppedServices/' + this.currentService.key).update(toSave).then(() => {
      this.updateProject.emit();
    });

  }

  disableCurrentService() {
    const toSave = { ...this.currentService };
    toSave.updatedBy = this.authService.userFB.uid;
    toSave.updatedDate = new Date();
    toSave.disabled = !this.currentService.disabled;
    this.db.doc('services/' + this.currentService.key).update(toSave).then(() => {
      this.currentService = toSave;
    });
  }

  deleteCurrentService() {
    if (this.currentService) {
      this.db.doc('services/' + this.currentService.key).delete();
      this.currentService = null;
    }
  }

}
