<div class="content" *ngIf="!isLoading">
  <div class="header">
    <button mat-raised-button (click)="returnToDashboard()">
      <span class="fas fa-undo"></span> Retour au dashboard
    </button>
    <div *ngIf="projectExist">
      Reste à Facturer : {{ remainsToBeBilled.toFixed(2) }} €
      <mat-progress-bar
        mode="determinate"
        [value]="projectPercente"></mat-progress-bar>
    </div>
  </div>
  <form [formGroup]="projectForm" (ngSubmit)="projectForm.valid && submit()">
    <div class="grid-container-mat-card">
      <mat-card class="grid-container-left">
        <mat-form-field appearance="outline" class="padding-bot-0 name">
          <mat-label>Nom Projet</mat-label>
          <input matInput formControlName="name" autocomplete="off" />
        </mat-form-field>
        <div class="rebond">
          <mat-checkbox
            formControlName="rebound"
            (change)="checkRebound($event)"
            >Rebonds</mat-checkbox
          >
          <mat-checkbox
            formControlName="clientDemand"
            (change)="checkDemandClient($event)">
            Demande Client</mat-checkbox
          >
        </div>
        <mat-form-field appearance="outline" class="padding-bot-0 state">
          <mat-label>Status du projet</mat-label>
          <mat-select formControlName="state">
            <mat-option value="Opportunité">Opportunité</mat-option>
            <mat-option value="Emis">Emis</mat-option>
            <mat-option value="Validé">Validé</mat-option>
            <mat-option value="Refusé">Refusé</mat-option>
            <mat-option value="En Cours">En cours</mat-option>
            <mat-option value="Terminé">Terminé</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="padding-bot-0 price">
          <mat-label>Montant Projet HT</mat-label>
          <input
            type="number"
            matInput
            formControlName="amountHT"
            autocomplete="off" />
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="padding-bot-0 dateSignature">
          <mat-label>Date de Signature</mat-label>
          <input matInput type="date" formControlName="signatureDate" />
        </mat-form-field>
        <div class="orderFrom">
          <mat-checkbox formControlName="orderReceived"
            >Bon de commande reçu</mat-checkbox
          >
        </div>
        <mat-form-field appearance="outline" class="padding-bot-0 numCommand">
          <mat-label>Num Bon De Commande</mat-label>
          <input matInput formControlName="orderNum" autocomplete="off" />
        </mat-form-field>
        <div class="orderContract"></div>
        <mat-form-field appearance="outline" class="padding-bot-0 contract">
          <mat-label>Numéro de contrat</mat-label>
          <input matInput formControlName="numContract" autocomplete="off" />
        </mat-form-field>
        <div class="pv">
          <mat-checkbox formControlName="receivedPV" (change)="changePV($event)"
            >PV de recette</mat-checkbox
          >
        </div>
        <mat-form-field appearance="outline" class="padding-bot-0 datePv">
          <mat-label>Date du PV</mat-label>
          <input matInput formControlName="pvDate" type="date" />
        </mat-form-field>
      </mat-card>
      <div class="grid-container-right">
        <mat-card class="grid-container-right">
          <mat-form-field appearance="outline" class="padding-bot-0">
            <mat-label>Client</mat-label>
            <mat-select
              formControlName="clientId"
              (selectionChange)="changeClient($event)">
              <mat-option *ngFor="let client of clients" [value]="client.key">
                {{ getClientName(client) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="padding-bot-0">
            <mat-label>Contact</mat-label>
            <mat-select
              formControlName="contact"
              [disabled]="contacts.length === 0">
              <mat-option *ngFor="let contact of contacts" [value]="contact.id">
                {{ contact.lastName }} {{ contact.firstName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Addresse</mat-label>
            <input matInput formControlName="address" readonly />
          </mat-form-field>
        </mat-card>
        <mat-card class="grid-container-right">
          <mat-form-field appearance="outline">
            <mat-label>Responsable du client</mat-label>
            <input matInput formControlName="clientManager" readonly />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Entité</mat-label>
            <mat-select formControlName="entity">
              <mat-option
                *ngFor="let entite of entityList"
                [value]="entite.key">
                {{ entite.name }} ( {{ entite.value }} )
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Créateur du projet</mat-label>
            <input matInput formControlName="projectCreator" readonly />
          </mat-form-field>
        </mat-card>
      </div>
    </div>
    <div class="grid-buttons">
      <button
        *ngIf="projectExist"
        mat-raised-button
        type="button"
        class="grid-buttons-left"
        (click)="addDueDate()">
        Ajouter une échéance
      </button>
      <div class="grid-buttons-right">
        <button
          *ngIf="projectExist"
          mat-raised-button
          type="button"
          (click)="makeDevis()">
          Faire un devis
        </button>
        <button
          *ngIf="projectExist"
          mat-raised-button
          type="button"
          color="warn"
          (click)="deleteProject()">
          Supprimer le projet
        </button>
        <button mat-raised-button type="submit">Sauvegarder le projet</button>
      </div>
    </div>
  </form>
  <div class="div-table" *ngIf="projectExist">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      style="width: 100%"
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="planningForecast"
      matSortDisableClear
      matSortDirection="desc">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Libelle d'échéance</th>
        <td mat-cell *matCellDef="let element">{{ element.dueLabel }}</td>
      </ng-container>
      <ng-container matColumnDef="amountHT">
        <th mat-header-cell *matHeaderCellDef>Montant HT</th>
        <td mat-cell *matCellDef="let element">{{ element.amount }} €</td>
      </ng-container>
      <ng-container matColumnDef="numberFacture">
        <th mat-header-cell *matHeaderCellDef>Numéro Facture</th>
        <td mat-cell *matCellDef="let element">{{ element.invoiceNumber }}</td>
      </ng-container>
      <ng-container matColumnDef="issuedFacture">
        <th mat-header-cell *matHeaderCellDef>Facture Emise</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            [checked]="element.hasBeenSent"
            [disabled]="true"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="planningForecast">
        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
          Planning Prévisionnel
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.plannedDate | date: 'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="editer">
        <th mat-header-cell *matHeaderCellDef>Éditer la facture</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="editerFacture(element)">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="update">
        <th mat-header-cell *matHeaderCellDef>Modifier</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            (click)="updateFacture(element)"
            [disabled]="element.hasBeenSent">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Supprimer</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            (click)="deleteInvoice(element)"
            [disabled]="element.hasBeenSent">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="row"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
