<mat-dialog-content>
  <p>Veuillez selectionner un ou plusieurs client(s)</p>
  <mat-form-field>
    <mat-select [(value)]="clientsSelect" class="selectFilterProject" multiple>
      <mat-option *ngFor="let client of clientsService.clients" [value]='client.key'>{{client.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Annuler</button>
  <button mat-button (click)="submit()" cdkFocusInitial>Ok</button>
</div>

