import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientsService } from '@core/services/clients.service';
import { DialogDataClients, ListClientsComponent } from '@pages/clients/components/list-clients/list-clients.component';

@Component({
  selector: 'app-filter-clients',
  templateUrl: './filter-clients.component.html',
  styleUrls: ['./filter-clients.component.scss']
})
export class FilterClientsComponent implements OnInit {
  public clientsSelect: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDataClients, public clientsService: ClientsService, public dialogRef: MatDialogRef<ListClientsComponent>) { }


  ngOnInit() {
    this.clientsSelect = JSON.parse(localStorage.getItem("clientsSelect"));
  }

  submit() {
    localStorage.setItem("clientsSelect", JSON.stringify(this.clientsSelect));
    this.dialogRef.close(this.clientsSelect);
  }
  cancel() {
    this.dialogRef.close(this.clientsSelect);
  }

}
