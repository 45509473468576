import { Pipe, PipeTransform } from "@angular/core";
import { DueValueLabel } from "@pages/projects/components/invoice/invoice-header/invoice-header.component";

@Pipe({
  name: 'dueValue'
})
export class DueValuePipe implements PipeTransform {

  public transform(value: string): string {
    return DueValueLabel.find((due) => due.value === value)?.label || value;
  }

}