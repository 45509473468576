import { Pipe, PipeTransform } from "@angular/core";
import { UnitLabel } from "@core/models/units.model";

@Pipe({
  name: 'unitLabel'
})
export class UnitLabelPipe implements PipeTransform {

  public transform(value: string): string {
    return UnitLabel.find((unit) => unit.value === value).label;
  }
}