import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientGestionComponent } from './components/client-gestion/client-gestion.component';
import { ClientProjectsComponent } from './components/client-projects/client-projects.component';

const routes: Routes = [
  { path: '', component: ClientProjectsComponent  },
  { path: 'manage', component: ClientGestionComponent  },
  { path: 'manage/:id', component: ClientGestionComponent},
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full'  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
