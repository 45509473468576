import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IBankAccount } from '@core/models/bank.model';
import { IClient } from '@core/models/client.models';
import { IEntity } from '@core/models/entity.model';
import { Error, ErrorValidator, ThrowableValue, validateThrowableValue, validateThrowableValues } from '@core/models/error.model';
import { Invoice, InvoiceDevisExpress, InvoiceLine } from '@core/models/invoice.model';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { BankAccountService } from '@core/services/bank-account.service';
import { InvoiceService } from '@core/services/invoice.service';
import { InvoiceDevisExpressService } from '@core/services/invoice-devis-express.service';
import { missingInvoiceLineNameErrorValidator, missingInvoiceLineQuantityErrorValidator, missingInvoiceLineUnitErrorValidator, missingInvoiceLineUnitPriceErrorValidator } from '@pages/projects/errors/generate-invoice.error';
import { invoiceNumberAlreadyExistsErrorValidator, invoiceNumberAlreadyExistsExpressErrorValidator } from '@pages/projects/errors/validate-invoice.error';
import { ErrorModalComponent } from '@shared/modals/error-modal/error-modal.component';

@Component({
  selector: 'app-devis-invoice-lines-card',
  templateUrl: './devis-invoice-lines-card.component.html',
  styleUrls: ['./devis-invoice-lines-card.component.scss']
})
export class DevisInvoiceLinesCardComponent {

  /** The invoice */
  @Input() public invoice: InvoiceDevisExpress;

   /** The service */
  @Input() public service: IService;

  /** The project */
  @Input() public project: IProjet;

  /** Check if the invoice has validated */
  @Input() public hasInvoiceBeenValidated: boolean = true;

  /** Emit generate pdf event */
  @Output() public generatePdf: EventEmitter<void> = new EventEmitter<void>();

  /** Emit validate event */
  @Output() public validate: EventEmitter<void> = new EventEmitter<void>();

  /** Emit save event */
  @Output() public save: EventEmitter<void> = new EventEmitter<void>();

  /** The selected entity */
  public selectedEntity: IEntity;

  /** The selected bank account */
  public selectedBankAccount: IBankAccount;

  /** is validating */
  public isValidating: boolean = false;

  /** The entity logo */
  public entityLogo: string;

  /** The dataUrl */
  public dataUrl: string;

  /** The client */
  public client: IClient;


  /**
   * Creates an instance of DevisInvoiceHeaderComponent.
   * @param invoiceService of InvoiceService
   * @param invoiceDevisExpressService of InvoiceDevisExpressService
   * @param snackBar of MatSnackBar
   * @param dialog of MatDialog
   * @param bankService of BankService
   */
  constructor(
    private readonly invoiceService: InvoiceService,
    private readonly dialog: MatDialog,
  ) { }


  public onSelectedEntity(entity: IEntity): void {
    this.selectedEntity = entity;
  }

  public onSelectedBankAccount(bankAccount: IBankAccount): void {
    this.selectedBankAccount = bankAccount;
  }

  /**
   * Adds a new line to the invoice's lines array.
   *
   * @returns {void}
   * @memberof DevisInvoiceLinesCardComponent
   */
  public addLine(): void {
    this.invoice.lines.push({
      id: null,
      name: '',
      unit: '',
      quantity: 0,
      unitPrice: 0,
      tvaRate: '20'
    });
  }


  /**
   * Generates a PDF document for the invoice based on the provided data.
   * It first validates the invoice lines and the invoice itself.
   * If there are no validation errors, it emits the generatePdf event.
   * If there are validation errors, it opens an error modal with the error messages.
   *
   * @returns {Promise<void>} A promise that resolves when the PDF generation is complete.
   * @memberof DevisInvoiceLinesCardComponent
   */
  public async generate(): Promise<void> {
    const throwablesLines: ThrowableValue<InvoiceLine>[] = this.invoice.lines.map((line: InvoiceLine) => ({
      validators: this.GENERATE_LINES_VALIDATORS,
      value: line
    }));

    const errorsLines: Error<InvoiceLine>[] = await validateThrowableValues(throwablesLines);
    if (errorsLines.length === 0) {
      return this.generatePdf.emit();
    } else {
      this.dialog.open(ErrorModalComponent, {
        data: [...errorsLines],
      });
      return;
    }
  }

  /**
   * Saves the current invoice to the database.
   * If the invoice already exists, it updates it. Otherwise, it creates a new one.
   * After saving, it displays a success message using the snackbar and emits a save event.
   * If an error occurs during the process, it logs the error to the console.
   *
   * @returns {Promise<void>} A promise that resolves when the invoice is saved successfully.
   * @memberof DevisInvoiceLinesCardComponent
   */
  public async saveInvoice(): Promise<void> {
    this.save.emit();
  }


  /**
   * Validates the current devis invoice.
   * It first sets the `isValidating` flag to true to indicate that validation is in progress.
   * Then, it creates a `ThrowableValue` object for the devis invoice with the `VALIDATE_VALIDATORS_EXPRESS` validators.
   * It awaits the validation of the throwable value and retrieves the resulting errors.
   * If there are errors, it opens an error modal with the error messages and sets the `isValidating` flag to false.
   * If there are no errors, it displays a confirmation dialog to the user.
   * If the user confirms, it emits the `validate` event.
   *
   * @returns {Promise<void>} A promise that resolves when the validation process is complete.
   * @memberof DevisInvoiceLinesCardComponent
   */
  public async validateInvoice(): Promise<void> {
    this.isValidating = true;
    const throwable: ThrowableValue<InvoiceDevisExpress> = {
      value: this.invoice,
      validators: this.VALIDATE_VALIDATORS_EXPRESS
    };
    const errors: Error<InvoiceDevisExpress>[] = await validateThrowableValue(throwable);
    if (errors.length > 0) {
      this.dialog.open(ErrorModalComponent, {
        data: errors,
      });
      this.isValidating = false;
      return;
    } else {
      if (confirm('Voulez-vous vraiment valider ce devis ?')) {
        this.validate.emit();
      }
    }
  }

  /**
   * Calculates the line amount based on the given quantity, unit price, and unit.
   * If the unit is 'P1', the line amount is calculated as (amount * unitPrice) / 100.
   * Otherwise, the line amount is calculated as (amount * unitPrice).
   *
   * @param amount - The quantity of the line item.
   * @param unitPrice - The unit price of the line item.
   * @param unit - The unit of measurement for the line item.
   *
   * @returns The calculated line amount.
   * @memberof DevisInvoiceLinesCardComponent
   */
  private calculateLineAmount(amount: number, unitPrice: number, unit: string, tvaRate: string): number {
    if(unit === 'P1') {
      return +(amount * unitPrice / 100).toFixed(2) * (1 + Number(tvaRate) / 100);
    } else {
      return +(amount * unitPrice).toFixed(2) * (1 + Number(tvaRate) / 100);
    }
  }

  public get isAmountEqualToZero(): boolean {
    return this.invoice.lines.reduce((acc, cur) => {
      return acc + this.calculateLineAmount(cur.quantity, cur.unitPrice, cur.unit, cur.tvaRate);
    }, 0) <= 0;
  }

  /**
   * Deletes a line from the invoice's lines array and,
   *
   * @param line - The line to be deleted.
   *
   * @returns A promise that resolves when the line is deleted successfully.   *
   * @memberof DevisInvoiceLinesCardComponent
   */
  public async deleteLine(line: InvoiceLine): Promise<void> {
    this.invoice.lines = this.invoice.lines.filter(l => l.id !== line.id);
    return line.id ? this.invoiceService.deleteLine(this.project.key, this.invoice.id, line.id) : Promise.resolve();
  }

  /**
   * Generates lines validatos
   *
   * @readonly
   * @private
   * @type {ErrorValidator<Error<InvoiceLine>>[]}
   * @memberof DevisInvoiceLinesCardComponent
   */
  private get GENERATE_LINES_VALIDATORS(): ErrorValidator<Error<InvoiceLine>>[] {
    return [
      missingInvoiceLineNameErrorValidator,
      missingInvoiceLineQuantityErrorValidator,
      missingInvoiceLineUnitErrorValidator,
      missingInvoiceLineUnitPriceErrorValidator
    ];
  }

  /**
   * Validate Validators
   *
   * @readonly
   * @private
   * @type {ErrorValidator<Error<Invoice>>[]}
   * @memberof DevisInvoiceLinesCardComponent
   */
  private get VALIDATE_VALIDATORS(): ErrorValidator<Error<Invoice>>[] {
    return [
      invoiceNumberAlreadyExistsErrorValidator(this.invoiceService),
    ];
  }

   /**
   * Validate Validators express
   *
   * @readonly
   * @private
   * @type {ErrorValidator<Error<Invoice>>[]}
   * @memberof DevisInvoiceLinesCardComponent
   */
  private get VALIDATE_VALIDATORS_EXPRESS(): ErrorValidator<Error<InvoiceDevisExpress>>[] {
    return [
      invoiceNumberAlreadyExistsExpressErrorValidator(this.invoiceService),
    ];
  }

}
