<h2>Nouveau service</h2>
<form (ngSubmit)="myForm.form.valid && onSubmit()" #myForm="ngForm">
  <mat-form-field class="champNom" appearance="outline">
    <mat-label>Nom du service</mat-label>
    <input matInput type="text" id="name" class="form-control" required autocomplete="name"
    [(ngModel)]="data.name" autofocus="autofocus" name="name" #name="ngModel"
    [ngClass]="{ 'is-invalid': myForm.submitted && name.invalid }">
  </mat-form-field>
  <h3>Choisissez votre unité de temps :</h3>
  <mat-form-field class="choix" appearance="outline">
    <mat-select [(value)]="data.unity">
      <mat-option value="J">Jours</mat-option>
      <mat-option value="M">Mois</mat-option>
      <mat-option value="H">Heure</mat-option>
      <mat-option value="U">Unité</mat-option>
      <mat-option value="%">%</mat-option>
    </mat-select>
  </mat-form-field>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-button mat-dialog-close type="button">Annuler</button>
    <button mat-button color="primary" type="submit">Ajouter</button>
  </div>
</form>
