<h1 mat-dialog-title>Modifier le nom du projet</h1>
<form (ngSubmit)="myForm.form.valid && onSubmit()" #myForm="ngForm" fxLayout="row wrap" fxLayoutAlign="space-around center">
  <mat-form-field class="champNomProjet">
      <input matInput type="text" id="name" class="form-control" placeholder="Modifier le nom du projet" required autocomplete="name"
      [(ngModel)]="data" autofocus="autofocus" name="name" #name="ngModel"
      [ngClass]="{ 'is-invalid': myForm.submitted && name.invalid }">
  </mat-form-field>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
    <button mat-button mat-dialog-close type="button">Annuler</button>
    <button mat-button color="primary" type="submit">Modifier</button>
  </div>
</form>
