import { IBankAccount } from "@core/models/bank.model";
import { Error, ErrorValidator } from "@core/models/error.model";
import { Invoice, InvoiceDevisExpress, InvoiceLine } from "@core/models/invoice.model";
import { BankAccountService } from "@core/services/bank-account.service";
import { firstValueFrom } from "rxjs";

export interface MissingInvoiceLineNameError extends Error<InvoiceLine> {}
export interface MissingInvoiceLineQuantityError extends Error<InvoiceLine> {}
export interface MissingInvoiceLineUnitError extends Error<InvoiceLine> {}
export interface MissingInvoiceLineUnitPriceError extends Error<InvoiceLine> {}

export interface MissingBankAccountInfoError extends Error<Invoice> {}

export interface MissingBankAccountInfoExpressError extends Error<InvoiceDevisExpress> {}


export const missingInvoiceLineNameErrorValidator: ErrorValidator<MissingInvoiceLineNameError> = (value: InvoiceLine) => {
  return Promise.resolve(value.name ? null : {
    value,
    message: 'Une désigniation de ligne de facture est requise'
  })
};

export const missingInvoiceLineQuantityErrorValidator: ErrorValidator<MissingInvoiceLineQuantityError> = (value: InvoiceLine) => {
  return Promise.resolve(value.quantity !== null ? null : {
    value,
    message: `La quantité de la ligne de facture ${value.name} est requise`
  })
}

export const missingInvoiceLineUnitErrorValidator: ErrorValidator<MissingInvoiceLineUnitError> = (value: InvoiceLine) => {
  return Promise.resolve(value.unit ? null : {
    value,
    message: `L\'unité de la ligne de facture ${value.name} est requise`
  })
}

export const missingInvoiceLineUnitPriceErrorValidator: ErrorValidator<MissingInvoiceLineUnitPriceError> = (value: InvoiceLine) => {
  return Promise.resolve(value.unitPrice !== null ? null : {
    value,
    message: `Le prix unitaire de la ligne de facture ${value.name} est requis`
  })
}

export function missingBankAccountInfoErrorValidator(bankService: BankAccountService): ErrorValidator<MissingBankAccountInfoError> {
  return async (value: Invoice) => {
    if (!value.bankAccount) {
      return {
        value,
        message: 'Un compte bancaire est requis'
      }
    }
    const account: IBankAccount = await firstValueFrom(bankService.getBankAccountById(value.bankAccount));
    if (!account.name) {
      return {
        value,
        message: 'Un nom pour le compte bancaire est requis'
      }
    }
    if (!account.domiciliation) {
      return {
        value,
        message: 'Une domiciliation pour le compte bancaire est requise'
      }
    }
    if (!account.iban) {
      return {
        value,
        message: 'Un IBAN pour le compte bancaire est requis'
      }
    }
    if (!account.swift) {
      return {
        value,
        message: 'Un BIC pour le compte bancaire est requis'
      }
    }

    return null;
  }
}
