import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IChapter, IParagraphe } from '@core/models/chapters.models';
import { IPV } from '@core/models/dasboard.model';
import {
  ICopyProjetData,
  IProjectFacture,
  IProjet,
} from '@core/models/projet.models';
import { IArticle, IService } from '@core/models/service.models';
import { Task } from '@core/models/task.model';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ChaptersService } from './chapters.service';

@Injectable({
  providedIn: 'root',
})
export class ProjetsService {
  public currentProject: IProjet;
  private projectSub: Subscription;

  constructor(
    public chaptersService: ChaptersService,
    private authService: AuthService,
    private db: AngularFirestore,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {}

  loadCurrentProject(id: string) {
    this.projectSub = this.db
      .collection('projects')
      .doc(id)
      .get()
      .pipe(
        map((doc: DocumentSnapshot<DocumentData>) => {
          return { key: doc.id, ...doc.data() };
        }),
      )
      .subscribe((data) => {
        this.currentProject = data as any;
      });
  }

  destroyCurrentProject() {
    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
    this.currentProject = null;
  }

  public createProject(projet: IProjet, uid: string): Promise<string> {
    projet.added = {
      by: uid,
      date: new Date(),
    };
    return this.db
      .collection('projects')
      .add(projet)
      .then((doc) => {
        return doc.update({ key: doc.id }).then(() => {
          const increment = firebase.firestore.FieldValue.increment(1);
          this.db
            .doc('clients/' + projet.clientId)
            .update({ nbProjects: increment });
          this.db
            .doc('/stats/customStats')
            .update({ projectsCount: increment });
          return doc.id;
        });
      });
  }

  addProject(
    idClient: string,
    projectName: string,
    uid: string,
  ): Promise<string> {
    const dataToadd: IProjet = {
      added: {
        by: uid,
        date: new Date(),
      },
      clientId: idClient,
      name: projectName,
      projectTasker: null,
      validate: 0,
      entiteId: '',
    };
    return this.db
      .collection('projects')
      .add(dataToadd)
      .then((data) => {
        const increment = firebase.firestore.FieldValue.increment(1);
        this.db.doc('clients/' + idClient).update({ nbProjects: increment });
        this.db.doc('/stats/customStats').update({ projectsCount: increment });
        return data.id;
      });
  }

  updateProject(project: IProjet) {
    this.updateProjectTasker(project);
    this.db.doc('projects/' + project.key).update(project);
  }

  private updateProjectTasker(project: IProjet): Promise<void> {
    if (project.projectTasker) {
      const projectTasker: {
        entiteId: string;
        orderNumber: string;
      } = {
        entiteId: project.entiteId,
        orderNumber: project.numBonDeCommande,
      };
      return this.db
        .doc('projectsTasker/' + project.projectTasker)
        .update(projectTasker);
    }
  }

  updateProjectUnit(projectKey: string, units: string) {
    this.db.doc('projects/' + projectKey).update({ unit: units });
  }

  updateProjectClient(projectKey: string, clientId: string) {
    this.db.doc('projects/' + projectKey).update({ clientId: clientId });
  }

  updateProjectName(projectKey: string, newProjectName: string) {
    this.db.doc('projects/' + projectKey).update({ name: newProjectName });
  }

  updateProjectDateSignature(projectKey: string, newProjecDateSignature: Date) {
    this.db
      .doc('projects/' + projectKey)
      .update({ dateSignature: newProjecDateSignature });
  }

  updateProjectOrderForm(projectKey: string, projectOrderFrom: boolean) {
    this.db
      .doc('projects/' + projectKey)
      .update({ orderForm: projectOrderFrom });
  }

  updateProjectStatus(projectKey: string, projectStatus: string) {
    this.db.doc('projects/' + projectKey).update({ status: projectStatus });
  }

  updateProjectPrice(projectKey: string, projectPrice: Number) {
    this.db
      .doc('projects/' + projectKey)
      .update({ price: +projectPrice.toFixed(2) });
  }

  updateProjectRebond(
    projectKey: string,
    projectDemandeClient: boolean,
    projectRebond: boolean,
  ) {
    this.db.doc('projects/' + projectKey).update({ rebond: projectRebond });
    this.db
      .doc('projects/' + projectKey)
      .update({ demandeClient: projectDemandeClient });
  }

  updateProjectNumBonDeCommande(projectKey: string, numBonDeCommande: string) {
    this.db
      .doc('projects/' + projectKey)
      .update({ numBonDeCommande: numBonDeCommande });
  }

  updatePV(projectKey: string, pv: IPV) {
    this.db.doc('projects/' + projectKey + '/').update({ pv: pv });
  }

  updateProjectPriceAndDate(
    projectKey: string,
    totalPrice: Number,
    dateUpdated: Date,
  ) {
    this.db
      .doc('projects/' + projectKey)
      .update({ price: +totalPrice.toFixed(2) });
    this.db.doc('projects/' + projectKey).update({
      updated: {
        date: dateUpdated,
      },
    });
  }

  updateProjectUpdated(projectKey: string, uid: string) {
    this.db.doc('projects/' + projectKey).update({
      updated: {
        by: uid,
        date: new Date(),
      },
    });
  }

  getChapters(projectKey: string) {
    const promises = [];
    promises.push(this.getChapterFromProject(projectKey).toPromise());
    promises.push(this.getProjectById(projectKey).toPromise());
    promises.push(this.getShoppedServiceFromProject(projectKey).toPromise());
    return Promise.all(promises).then((result) => {
      const dataToReturn = {
        project: result[1],
        shoppedServices: result[2],
      } as ICopyProjetData;
      dataToReturn.chapters = result[0]
        ? result[0].map((chapter) => {
            return { chapter: chapter, paragraphs: [] };
          })
        : null;
      return dataToReturn;
    });
  }

  getDataFromProject(projectKey: string) {
    return this.getChapters(projectKey).then((data) => {
      const promises = [];
      for (let chapter of data.chapters) {
        promises.push(
          this.getParagraphesFromChapters(projectKey, chapter.chapter.key)
            .toPromise()
            .then((result) => {
              chapter.paragraphs = result;
            }),
        );
      }
      return Promise.all(promises).then((result) => {
        return data;
      });
    });
  }

  getProjectById(projectKey: string): Observable<IProjet> {
    return this.db
      .collection<IProjet>('projects')
      .doc(projectKey)
      .get()
      .pipe(
        map((snap: DocumentSnapshot<DocumentData>) => {
          return { key: snap.id, ...(snap.data() as any) } as IProjet;
        }),
      );
  }

  getAllproject() {
    return this.db
      .collection('projects')
      .get()
      .pipe(
        map((values: QuerySnapshot<DocumentData>) => {
          return values.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
            return {
              key: doc.id,
              ...doc.data(),
            } as IProjet;
          });
        }),
      );
  }

  getChapterFromProject(projectKey: string) {
    return this.db
      .collection('projects/' + projectKey + '/chapter/')
      .get()
      .pipe(
        map((values: QuerySnapshot<DocumentData>) => {
          return values.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
            return {
              key: doc.id,
              ...doc.data(),
            } as IChapter;
          });
        }),
      );
  }

  getParagraphesFromChapters(projectKey: string, chapterKey: string) {
    return this.db
      .collection(
        'projects/' + projectKey + '/chapter/' + chapterKey + '/paragraphes/',
      )
      .get()
      .pipe(
        map((values: QuerySnapshot<DocumentData>) => {
          return values.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
            return {
              key: doc.id,
              ...doc.data(),
            } as IParagraphe;
          });
        }),
      );
  }

  getShoppedServiceFromProject(projectKey: string) {
    return this.db
      .collection('shoppedServices', (ref) =>
        ref.where('projectID', '==', projectKey),
      )
      .get()
      .pipe(
        map((values: QuerySnapshot<DocumentData>) => {
          return values.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
            return {
              key: doc.id,
              ...doc.data(),
            } as IService;
          });
        }),
      );
  }

  addCopyProject(data: ICopyProjetData) {
    return this.addProject(
      data.newClientId,
      data.project.name,
      this.authService.userFB.uid,
    ).then((idProject) => {
      for (let shoppedService of data.shoppedServices) {
        shoppedService.projectID = idProject;
        this.db
          .collection('shoppedServices')
          .add(shoppedService)
          .then((docRef) => {
            this.db
              .doc('shoppedServices/' + docRef.id)
              .update({ key: docRef.id });
          });
      }
      for (let chapters of data.chapters) {
        this.db
          .collection('projects/' + idProject + '/chapter/')
          .add(chapters.chapter)
          .then((chapterRef) => {
            this.db
              .doc('projects/' + idProject + '/chapter/' + chapterRef.id)
              .update({ key: chapterRef.id });
            for (let paragraphe of chapters.paragraphs) {
              paragraphe.chapterKey = chapterRef.id;
              this.db
                .collection(
                  'projects/' +
                    idProject +
                    '/chapter/' +
                    chapterRef.id +
                    '/paragraphes/',
                )
                .add(paragraphe)
                .then((paragrapheRef) => {
                  this.db
                    .doc(
                      'projects/' +
                        idProject +
                        '/chapter/' +
                        chapterRef.id +
                        '/paragraphes/' +
                        paragrapheRef.id,
                    )
                    .update({ key: paragrapheRef.id });
                });
            }
          });
      }
    });
  }

  public deleteProject(id: string): Promise<void> {
    return this.db.collection('projects').doc(id).delete();
  }
  getProjectFacture(projectKey: string, notIssued?: boolean, limit?: number) {
    return this.db
      .collection<IProjectFacture>(
        'projects/' + projectKey + '/facture',
        (ref) => {
          let query = ref.orderBy('planningForecast', 'asc');
          if (notIssued) {
            query = query.where('issuedFacture', '==', false);
          }
          if (limit) {
            query = query.limit(limit);
          }
          return query;
        },
      )
      .get()
      .pipe(
        map((values: QuerySnapshot<DocumentData>) => {
          return values.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
            const res = {
              ...doc.data(),
              key: doc.id,
            } as IProjectFacture;
            if (res.planningForecast) {
              res.planningForecast = new Date(
                (res.planningForecast as any).seconds * 1000,
              );
            }
            return res;
          });
        }),
      );
  }

  public getProjectFactureById(
    projectId: string,
    factureId: string,
  ): Observable<IProjectFacture> {
    return this.db
      .collection<IProjectFacture>('projects/' + projectId + '/facture')
      .doc<IProjectFacture>(factureId)
      .get()
      .pipe(
        map(
          (doc: DocumentSnapshot<DocumentData>) =>
            ({ key: doc.id, ...doc.data() }) as IProjectFacture,
        ),
      );
  }

  public addFactureForProject(projectKey: string, facture: IProjectFacture) {
    return this.db
      .collection<IProjectFacture>(`projects/${projectKey}/facture`)
      .add(facture);
  }

  public updateFacture(projectKey: string, facture: IProjectFacture) {
    return this.db
      .doc('projects/' + projectKey + '/facture/' + facture.key)
      .update({
        typeFacture: facture.typeFacture,
        pourcent: facture.pourcent,
        amountHT: facture.amountHT,
        numberFacture: facture.numberFacture,
        planningForecast: facture.planningForecast,
        issuedFacture: facture.issuedFacture,
      });
  }

  updateIssuedFacture(
    projectKey: string,
    factureKey: string,
    issuedFacture: boolean,
  ) {
    this.db
      .doc('projects/' + projectKey + '/facture/' + factureKey + '/')
      .update({ issuedFacture: issuedFacture });
  }

  public async validateProject(
    project: IProjet,
    services: IService[],
  ): Promise<void> {
    const projectId: string = this.createProjectId(project.name);
    const ids: string[] = await firstValueFrom(
      this.getProjectsStartingWithId(projectId).pipe(first()),
    );
    const id: string = this.getMaxId(ids, projectId);
    const projectToSave: any = {
      increTask: services
        .map((service: IService) => service.articles.length)
        .reduce((acc, val) => acc + val, 0),
      added: project.added,
      clientId: project.clientId,
      updated: project.updated ? project.updated : null,
      name: project.name,
      visible: true,
      attachments: [],
      nbComments: 0,
      beginDate: null,
      endDate: null,
      projectID: id,
      team: '',
      description: '',
      guaranteedPeriod: 0,
      warrantyDate: null,
      times: {
        billed: 0,
        estimated: 0,
        assignated: 0,
        logged: 0,
      },
      entiteId: project.entiteId,
      orderNumber: project.numBonDeCommande ?? null,
    };

    const currentUserId: string = (await firstValueFrom(this.authService.user$))
      .key;
    await this.db.collection(`projectsTasker`).doc(id).set(projectToSave);
    const tasks: Task[] = this.buildTask(id, services, currentUserId);
    const batch = this.db.firestore.batch();
    tasks.forEach((task: Task, idx: number) => {
      const ref = this.db.collection('tasks').doc(id + '-' + (idx + 1));
      batch.set(ref.ref, task);
    });
    await batch.commit();
    return this.db
      .collection('projects')
      .doc(project.key)
      .update({ validate: 2, projectTasker: id, status: 'Validé' });
  }

  private buildTask(
    projetId: string,
    services: IService[],
    currentUser: string,
  ): Task[] {
    return services
      .map((service: IService) =>
        service.articles.map(
          (article: IArticle) =>
            ({
              added: {
                by: currentUser,
                date: new Date(),
              },
              archived: false,
              billable: true,
              closed: false,
              deviation: false,
              evolution: false,
              globalType: 'normal',
              increSubtask: 1,
              managed: false,
              name: article.name,
              priority: 'medium',
              projectId: projetId,
              state: 'toDo',
              type: null,
              warranty: false,
              times: {
                estimated: this.calculateEstimatedTime(service.unity, article),
                assigned: 0,
                billed: 0,
                logged: 0,
              },
            }) as Task,
        ),
      )
      .reduce((acc, val) => acc.concat(val), [])
      .reduce((acc, val) => acc.concat(val), []);
  }

  private calculateEstimatedTime(unit: string, article: IArticle): number {
    return article.amount * (unit === 'J' ? 7 : unit === 'M' ? 210 : 7);
  }

  private createProjectId(name: string): string {
    const nameAsArray = name.split(' ').filter((e) => e);
    let key: string = '';
    switch (nameAsArray.length) {
      case 1:
        key = name.substring(0, 3);
        break;
      case 2:
        key = nameAsArray[0][0] + nameAsArray[1][0];
        break;
      default:
        key = nameAsArray[0][0] + nameAsArray[1][0] + nameAsArray[2][0];
        break;
    }
    return key.toUpperCase();
  }

  private getProjectsStartingWithId(id: string): Observable<string[]> {
    return this.db
      .collection('projectsTasker')
      .get()
      .pipe(
        map((query: QuerySnapshot<DocumentData>) =>
          query.docs
            .filter((doc: QueryDocumentSnapshot<DocumentData>) =>
              doc.id.startsWith(id),
            )
            .map((doc: QueryDocumentSnapshot<DocumentData>) => doc.id),
        ),
      );
  }

  private getMaxId(names: string[], id: string, index?: number): string {
    if (names.length === 0) return id;
    const nameToTest = id + (index ? index : '');
    if (names.find((e) => e === nameToTest)) {
      return this.getMaxId(names, id, index ? index + 1 : 2);
    }
    return nameToTest;
  }

  addVariabletoFirebase(projectKey) {
    let nom: string;

    this.getDataFromProject(projectKey).then((data) => {
      nom = data.project.projectTasker;
    });

    this.getShoppedServiceFromProject(projectKey).forEach((element) => {
      for (var i = 0; i < element.length; i++) {
        if (element[i].articles.length == 0) {
          this.db
            .collection('tasks')
            .doc(nom + '-' + i)
            .set(element[i]);
        } else if (element[i].articles.length == 1) {
          this.db
            .collection('tasks')
            .doc(nom + '-' + i)
            .set(element[i].articles[0]);
        } else {
          var cpt = 0;
          this.db
            .collection('tasks')
            .doc(nom + '-' + i)
            .set(element[i]);
          element[i].articles.forEach((art) => {
            this.db
              .collection('subtasks')
              .doc(nom + '-' + i + '-' + cpt)
              .set(art);
            cpt++;
          });
        }
      }
    });
  }
}
