import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { InvoiceService } from "@core/services/invoice.service";
import { map } from "rxjs";

export function isInvoiceNumberValid(servcie: InvoiceService): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return servcie.isInvoiceNumberAlreadyUsed(control.value).pipe(
      map(isUsed => isUsed ? { invoiceNumberAlreadyUsed: true } : null),
    );
  }
}