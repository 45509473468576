import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Error, ErrorValidator, ThrowableValue, validateThrowableValue, validateThrowableValues } from '@core/models/error.model';
import { Invoice, InvoiceLine } from '@core/models/invoice.model';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { BankAccountService } from '@core/services/bank-account.service';
import { InvoiceDevisExpressService } from '@core/services/invoice-devis-express.service';
import { InvoiceService } from '@core/services/invoice.service';
import { missingBankAccountInfoErrorValidator, missingInvoiceLineNameErrorValidator, missingInvoiceLineQuantityErrorValidator, missingInvoiceLineUnitErrorValidator, missingInvoiceLineUnitPriceErrorValidator } from '@pages/projects/errors/generate-invoice.error';
import { invoiceNumberAlreadyExistsErrorValidator } from '@pages/projects/errors/validate-invoice.error';
import { ErrorModalComponent } from '@shared/modals/error-modal/error-modal.component';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-lines-card',
  templateUrl: './invoice-lines-card.component.html',
  styleUrls: ['./invoice-lines-card.component.scss']
})
export class InvoiceLinesCardComponent implements OnInit, OnDestroy {

  /** The invoice */
  @Input() public invoice: Invoice;

  /** The service */
  @Input() public service: IService;

  /** The project */
  @Input() public project: IProjet;

  /** Emit generate pdf event */
  @Output() public generatePdf: EventEmitter<void> = new EventEmitter<void>();

  /** Emit validate event */
  @Output() public validate: EventEmitter<void> = new EventEmitter<void>();

  /** Check if the invoice has validated */
  @Input() public hasInvoiceBeenValidated: boolean = true;

  /** is validating */
  public isValidating: boolean = false;

  public options: InvoiceLine[] = [];

  private _options: Subscription;

  /**
   * Creates an instance of InvoiceLinesCardComponent.
   * @param invoiceService of InvoiceService
   * @param snackBar of MatSnackBar
   * @param dialog of MatDialog
   * @param bankService of BankService
   */
  constructor(
    private readonly invoiceService: InvoiceService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly bankService: BankAccountService,
    private readonly devisExpressService: InvoiceDevisExpressService
  ) { }

  public ngOnInit(): void {
    if (environment.connectFrom === 'FIREBASE') {
      this._options = this.devisExpressService.getInvoiceDevisExpressLinesByProjectId(this.project.key).subscribe((lines: InvoiceLine[]) => {
        this.options = lines;
      });
    }
  }

  public ngOnDestroy(): void {
    this._options.unsubscribe();
  }


  /**
   * Adds a new line to the invoice's lines array.
   *
   * @returns {void}
   * @memberof DevisInvoiceH
   *
   */
  public addLine(): void {
    this.invoice.lines.push({
      id: null,
      name: '',
      unit: '',
      quantity: 0,
      unitPrice: 0,
      tvaRate: ''
    });
  }

  public async generate(): Promise<void> {
    const throwablesLines: ThrowableValue<InvoiceLine>[] = this.invoice.lines.map((line: InvoiceLine) => ({
      validators: this.GENERATE_LINES_VALIDATORS,
      value: line
    }));
    const throwablesInvoice: ThrowableValue<Invoice> = {
      validators: this.GENERATE_INVOICE_VALIDATORS,
      value: this.invoice
    };
    const errorsLines: Error<InvoiceLine>[] = await validateThrowableValues(throwablesLines);
    const errorsInvoice: Error<Invoice>[] = await validateThrowableValue(throwablesInvoice);
    if (errorsLines.length === 0 && errorsInvoice.length === 0) {
      return this.generatePdf.emit();
    } else {
      this.dialog.open(ErrorModalComponent, {
        data: [...errorsLines, ...errorsInvoice],
      });
      return;
    }
  }

  public async saveInvoice(): Promise<void | void[]> {
    return this.invoiceService.updateInvoice(this.project.key, this.invoice, true, this.calculateLineAmount).then(() => {
      this.snackBar.open('Facture sauvegardée', 'x', {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'end',
        panelClass: ['green-snackbar']
      });
    });
  }

  public async validateInvoice(): Promise<void> {
    this.isValidating = true;
    const throwable: ThrowableValue<Invoice> = {
      value: this.invoice,
      validators: this.VALIDATE_VALIDATORS
    };
    const errors: Error<Invoice>[] = await validateThrowableValue(throwable);
    if (errors.length > 0) {
      this.dialog.open(ErrorModalComponent, {
        data: errors,
      });
      this.isValidating = false;
      return;
    } else {
      if (confirm('Voulez-vous vraiment valider cette facture ?')) {
        this.validate.emit();
      }
    }
  }

  private calculateLineAmount(amount: number, unitPrice: number, unit: string): number {
    if(unit === 'P1') {
      return amount * unitPrice / 100;
    } else {
      return amount * unitPrice;
    }
  }

  public async deleteLine(line: InvoiceLine): Promise<void> {
    this.invoice.lines = this.invoice.lines.filter(l => l.id !== line.id);
    return line.id ? this.invoiceService.deleteLine(this.project.key, this.invoice.id, line.id) : Promise.resolve();
  }

  private get GENERATE_LINES_VALIDATORS(): ErrorValidator<Error<InvoiceLine>>[] {
    return [
      missingInvoiceLineNameErrorValidator,
      missingInvoiceLineQuantityErrorValidator,
      missingInvoiceLineUnitErrorValidator,
      missingInvoiceLineUnitPriceErrorValidator
    ];
  }

  private get GENERATE_INVOICE_VALIDATORS(): ErrorValidator<Error<Invoice>>[] {
    return [
      missingBankAccountInfoErrorValidator(this.bankService)
    ]
  }

  private get VALIDATE_VALIDATORS(): ErrorValidator<Error<Invoice>>[] {
    return [
      invoiceNumberAlreadyExistsErrorValidator(this.invoiceService)
    ];
  }

}
