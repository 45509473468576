import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Error } from '@core/models/error.model';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent<T> {

  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: Error<T>[]
  ) { }

}
