import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  IChapter,
  IChapterWithPars,
  IParagraphe
} from '@core/models/chapters.models';
import { IClient } from '@core/models/client.models';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { ChaptersService } from '@core/services/chapters.service';
import { ClientsService } from '@core/services/clients.service';
import { DowloadPdfService } from '@core/services/dowload-pdf.service';
import * as HtmlToPdfMake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFont from 'pdfmake/build/vfs_fonts';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { PDFCertificationLogo } from './pdfCertificationLogo';
import { PDFEmothepCover } from './pdfCover';
import { PDFEmothepLogo } from './pdfEmothepLogo';

interface IPdfDD {
  styles: any;
  footer: any;
  header: any;
  content: any[];
  images: any;
  pageMargins: any;
  defaultStyle: any;
  info?: any;
}

@Component({
  selector: 'app-show-pdf',
  templateUrl: './show-pdf.component.html',
  styleUrls: ['./show-pdf.component.scss'],
})
export class ShowPdfComponent implements OnInit {
  edge = false;
  loading = true;
  public sub;
  private greenColor = '#91b750';
  private doc;
  public dd: IPdfDD = {
    styles: {},
    content: [],
    footer: {},
    header: {},
    images: {},
    pageMargins: {},
    defaultStyle: {},
    info: {},
  };
  @Input() projet: IProjet;
  @Input() client: IClient;
  @Input() shoppedServices: IService[];
  @Input() chapters: IChapterWithPars[];

  refreshPdfSubscription: Subscription;

  constructor(
    private chapterServices: ChaptersService,
    private downloadPdfService: DowloadPdfService,
    private clientsService: ClientsService
  ) {
    this.refreshPdfSubscription = this.chapterServices.refreshPdfSubject
      .pipe(
        debounceTime(200),
        tap(() =>
          console.log('PDF Should be refreshed', new Date().toISOString())
        )
      )
      .subscribe(() => {
        this.getDoc();
      });
  }

  ngOnInit() {
    this.sub = this.downloadPdfService.pdfSubject.subscribe(() => {
      let date = formatDate(new Date(), 'ddMMyyyy', 'en');
      let client = this.clientsService.getAClient(this.projet.clientId);
      pdfMake
        .createPdf(this.dd, null, null, pdfFont.pdfMake.vfs)
        .download(
          '[' + client.name + ']' + this.projet.name + '_' + date + '.pdf'
        );
    });
    this.dd.images = {
      emothepLogo: new PDFEmothepLogo().field.Consultants,
      ISO14001: new PDFCertificationLogo().field.ISO14001,
      ISO9001: new PDFCertificationLogo().field.ISO9001
    };
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Light.ttf',
        bold: 'Roboto-Bold.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Bold-Italic.ttf',
      }
    };
    this.dd.pageMargins = [40, 120, 40, 60];
    this.dd.defaultStyle = {
      font: 'Roboto',
      fontSize: 12,
    };
    this.dd.footer = (currentPage, pageCount) => {
      if (currentPage == 1) {
        return {
          alignment: 'center',
          margin: [20, 20, 20, 20],
          fontSize: 10,
          color: this.greenColor,
          columns: [],
        };
      } else {
        return {
          alignment: 'center',
          margin: [20, 20, 20, 20],
          fontSize: 10,
          color: this.greenColor,
          columns: [
            {
              text: 'Notre métier, la proximité!',
              bold: true,
            },
            {
              width: 25,
              text: currentPage.toString() + '/' + pageCount.toString(),
            },
          ],
        };
      }
    };
    this.dd.header = (currentPage) => {
      if (currentPage !== 1)
        return {
          margin: [5, 5, 5, 5],
          columns: [
            {
              image: 'emothepLogo',
              width: 130,
            },
            {
              margin: [0, 30, 0, 0],
              stack: [
                {
                  text: this.projet.name,
                  alignment: 'right',
                  bold: true,
                  color: this.greenColor,
                },
              ],
            },
            {
              margin: [0, 30, 30, 0],
              stack: [
                {
                  text: new Date().toLocaleDateString(),
                  alignment: 'right',
                  bold: true,
                  color: this.greenColor,
                },
              ],
            },
          ],
        };
      else return {};
    };
    this.dd.styles = {
      h1: {
        fontSize: 20,
        color: this.greenColor,
        bold: true,
      },
      h2: {
        fontSize: 16,
      },
      h3: {
        fontSize: 14,
      },
    };
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.chapters && this.shoppedServices) {
      this.chapterServices.refreshPdfSubject.next();
    }
  }

  ngOnDestroy(): void {
    if (this.refreshPdfSubscription) {
      this.refreshPdfSubscription.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getDoc() {
    this.doc = this.generatePDF();
    if (!this.edge) {
      const callback = (url) => {
        const f: HTMLElement = document.getElementById('pdfViewer');
        if (f) {
          f.setAttribute('src', url);
        }
        this.loading = false;
      };
      this.doc.getDataUrl(callback, this.doc);
    } else {
      this.loading = false;
    }
  }

  generatePDF() {
    this.dd.content = [];
    this.coverPage();
    this.addToc();
    let chapterCounter = 0;
    this.chapters.forEach((chapter) => {
      chapterCounter++;
      this.addChapter(chapter, chapterCounter);
    });
    return pdfMake.createPdf(this.dd, null, null, pdfFont.pdfMake.vfs);
  }
  public pdfDownload() {
    this.doc.download();
  }

  downloadPDF() {
    let date = formatDate(new Date(), 'ddMMyyyy', 'en');
    let client: IClient = this.clientsService.getAClient(this.projet.clientId);
    pdfMake
      .createPdf(this.dd, null, null, pdfFont.pdfMake.vfs)
      .download(
        '[' + client.name + ']' + this.projet.name + '_' + date + '.pdf'
      );
  }

  private coverPage() {
    let client: IClient = this.clientsService.getAClient(this.projet.clientId);
    const cover = new PDFEmothepCover().field;
    this.dd.content.push({
      image: cover.emothepCover,
      width: 650,
      absolutePosition: { x: 0, y: 0 },
    });
    if (client.society?.logo) {
      this.dd.content.push({
        image: client.society.logo,
        width: 200,
        absolutePosition: { x: 200, y: 300 },
      });
    }
    this.dd.content.push({
      text: this.projet.name,
      absolutePosition: { x: 350, y: 550 },
      style: 'h1',
      pageBreak: 'after',
    });
  }

  private addToc() {
    this.dd.content.push({
      stack: [
        this.getTitle('TABLE DES MATIÈRES', 'h1'),
        { text: '\n' },
        {
          toc: {},
        },
      ],
    });
  }

  private getTitle(title: string, style: string) {
    return {
      stack: [{ text: title, style, tocItem: style === 'h1' }],
      margin: [0, 10, 0, 10],
    };
  }

  private addChapter(
    chapterWithPars: IChapterWithPars,
    chapterCounter: number
  ) {
    const chapter: IChapter = chapterWithPars.chapter;
    this.dd.content.push({
      stack: [this.getTitle(chapterCounter + ' ' + chapter.name, 'h1')],
      pageBreak: 'before',
    });
    let counter = 0;
    if (chapter.type === 'text') {
      chapterWithPars.pars.forEach((paragraphe, index) => {
        counter++;
        this.addContent(paragraphe, index + 1, chapterCounter);
      });
    } else if (chapter.type === 'article') {
      this.addPrestation(chapter, chapterCounter);
    } else if (chapter.type === 'recap') {
      this.addPricing(chapter, chapterCounter);
    }
  }

  private addContent(
    paragraphe: IParagraphe,
    index: number,
    chapterIndex: number
  ) {
    if (paragraphe.type === 'par') {
      this.addPara(paragraphe, index, chapterIndex);
    }
  }

  private addPara(
    paragraphe: IParagraphe,
    index: number,
    chapterCounter: number
  ) {
    if (paragraphe.name.length > 0) {
      this.dd.content.push({
        stack: [
          this.getTitle(
            chapterCounter + '.' + index + ' ' + paragraphe.name,
            'h2'
          ),
        ],
      });
    }
    this.dd.content.push(this.convertDeltaToDocDef(paragraphe.ops));
  }

  private convertDeltaToDocDef(delta) {
    var converter = new QuillDeltaToHtmlConverter(delta, {});
    var deltaHtml = converter.convert();
    var htmlDocdef = HtmlToPdfMake(deltaHtml);
    return htmlDocdef;
  }

  private addPricing(chapter: IChapter, chapterCounter: number) {
    let services: IService[] = [];
    if (!this.shoppedServices) {
      return;
    } else if (this.shoppedServices.length === 0) {
      return;
    } else if (!this.shoppedServices[0]['articles']) {
      const temp: IService[][] = this.shoppedServices as any as IService[][];
      services = temp[0];
    } else {
      services = this.shoppedServices;
    }

    let chargesTotal = 0;
    let mtTotal = 0;
    const body = [];
    body.push(['Phase', 'Profils', 'Charges', 'Tarif unitaire', 'Mt HT']);
    services = services.filter(
      (s) =>
        s.articles.map((a) => a.amount).reduce((acc, curr) => acc + curr) != 0
    );
    services.forEach((service) => {
      body.push([
        { text: service.name, bold: true },
        { text: '', colSpan: 4, fillColor: '#808080' },
      ]);
      service.articles
        .filter((article) => article.amount !== 0)
        .forEach((article) => {
          const amount: number = service.unity === '%'
            ? article.amount / 100
            : article.amount;
          chargesTotal = chargesTotal + article.amount;
          mtTotal = +(mtTotal + amount * article.cost).toFixed(2);
          body.push([
            { text: article.name },
            article.profilsPresta ? article.profilsPresta : '',
            { text: `${article.amount} ${service.unity === '%' ? '%' : ''}`},
            article.cost.toFixed(2) + '€',
            (amount * article.cost).toFixed(2) + '€',
          ]);
        });
    });
    body.push([
      { text: 'Total', colSpan: 2, bold: true },
      {},
      { text: chargesTotal.toString() },
      { text: '' },
      { text: mtTotal.toFixed(2) + '€' },
    ]);
    this.dd.content.push({
      stack: [
        { table: { widths: ['*', 'auto', 'auto', 'auto', 'auto'], body } },
      ],
    });
  }

  private addPrestation(chapter: IChapter, chapterIndex: number) {
    let services: IService[] = [];
    if (!this.shoppedServices) {
      return;
    } else if (this.shoppedServices.length === 0) {
      return;
    } else if (!this.shoppedServices[0]['articles']) {
      const temp: IService[][] = this.shoppedServices as any as IService[][];
      services = temp[0];
    } else {
      services = this.shoppedServices;
    }

    const greenColor = '#B0CE69';
    const monStack = [];
    monStack.push({ text: '\n', fontSize: 15 });
    services = services.filter((s) => s.articles.map((a) => a.amount).reduce((acc, curr) => acc + curr, 0) != 0);
    services.forEach((service, index) => {
      const newStack = [];
      newStack.push(
        this.getTitle(
          chapterIndex + '.' + (index + 1) + ' ' + service.name,
          'h2'
        )
      );
      service.articles
        .filter((article) => article.amount !== 0)
        .forEach((article, rowIndex) => {
          const body = [];
          body.push([
            {
              text: 'Prestation : ' + article.name,
              colSpan: 2,
              alignment: 'center',
              fillColor: '#44722D',
              color: '#FFFFFF',
            },
            '',
          ]);
          body.push([
            { text: 'OBJECTIFS', bold: true },
            { text: article.objectifs || '' },
          ]);
          body.push([
            { text: 'PRINCIPALES ACTIVITES', bold: true },
            { text: article.mainActivities || '' },
          ]);
          body.push([
            { text: 'LIVRABLES', bold: true },
            { text: article.deliverables || '' },
          ]);
          body.push([
            { text: 'PRE-REQUIS', bold: true },
            { text: article.requirements || '' },
          ]);
          body.push([
            { text: 'HORS PERIMETRE', bold: true },
            { text: article.outOfBounds || '' },
          ]);
          body.push([
            { text: 'MOYENS TECHNIQUES', bold: true },
            { text: article.technicalMeans || '' },
          ]);
          const body2 = [];
          body2.push(['Client', 'Presta']);
          body2.push([
            article.profilsClient
              ? article.profilsClient.toString().replace(',', '\n')
              : '',
            article.profilsPresta
              ? article.profilsPresta.toString().replace(',', '\n')
              : '',
          ]),
            body.push([
              { text: 'PROFILS DES INTERVENANTS ', bold: true },
              {
                layout: 'noBorders',
                table: {
                  widths: ['*', '*'],
                  body: body2,
                },
              },
            ]);
          body.push([
            { text: 'DUREE DE REALISATION ESTIMEE', bold: true },
            { text: `${article.amount} ${service.unity !== 'U' ? service.unity.toLowerCase() : '' }`  },
          ]);
          newStack.push({
            unbreakable: true,
            table: {
              widths: [160, '*'],
              body,
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex % 2 === 0 ? greenColor : null;
              },
            },
          });
          newStack.push({ text: '\n', fontSize: 20 });
        });
      monStack.push({ stack: newStack });
    });

    this.dd.content.push({ stack: monStack });
  }

  private getRichText(ops) {
    const text = [];
    if (ops) {
      ops.forEach((bloc) => {
        if (bloc.attributes) {
          const customStyle = {
            decoration: '',
          };
          if (bloc.attributes.underline) {
            customStyle.decoration = 'underline';
          }
          text.push({
            text: bloc.insert,
            bold: bloc.attributes.bold,
            italics: bloc.attributes.italic,
            color: bloc.attributes.color || 'black',
            background: bloc.attributes.background || 'white',
            style: customStyle,
          });
        } else {
          text.push({ text: bloc.insert });
        }
      });
    }
    return { text, alignment: 'justify' };
  }
}
