import { AbstractControl, ValidatorFn } from '@angular/forms';

const SWIFT_MAX_SIZE: number = 11;
const SWIFT_REGEXP: RegExp = /^[a-zA-Z0-9]*$/;

export function swiftValidator(): ValidatorFn {
    return (control: AbstractControl): { swift: string } | null => {
      if (!control.value) return null;
      const swift = control.value;
      const isFormatValid = SWIFT_REGEXP.test(swift);
      if (!isFormatValid) return { swift: 'Format invalide. Seuls des lettres sont autorisées.' };
      const isSizeValid = swift.length <= SWIFT_MAX_SIZE;
      return !isSizeValid ? { swift: `Taille max: ${SWIFT_MAX_SIZE} (actuelle : ${swift.length})`} : null;
    };
}