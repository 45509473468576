import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IBankAccount } from '@core/models/bank.model';
import { IClient } from '@core/models/client.models';
import { IEntity } from '@core/models/entity.model';
import { IFacturX, IFactureItem } from '@core/models/facture.model';
import { Invoice, InvoiceLine } from '@core/models/invoice.model';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { BankAccountService } from '@core/services/bank-account.service';
import { ClientsService } from '@core/services/clients.service';
import { CountryService } from '@core/services/country.service';
import { EntityService } from '@core/services/entity.service';
import { FacturXService } from '@core/services/factur-x.service';
import { InvoiceService } from '@core/services/invoice.service';
import { ProjetsService } from '@core/services/projets.service';
import { ServicesService } from '@core/services/services.service';
import moment from 'moment';
import { Subscription, combineLatest, firstValueFrom, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  public project: IProjet;
  private _project: Subscription;

  public invoice: Invoice;
  public client: IClient;
  public selectedEntity: IEntity;
  public entityLogo: string;
  public selectedBankAccount: IBankAccount;
  public service: IService;

  public dataUrl: string;

  public hasInvoiceBeenValidated: boolean = true;

  public isFetching: boolean = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly projectService: ProjetsService,
    private readonly invoiceService: InvoiceService,
    private readonly clientService: ClientsService,
    private readonly bankAccountService: BankAccountService,
    private readonly serviceServices: ServicesService,
    private readonly enitityService: EntityService,
    private readonly facturxService: FacturXService,
    private readonly countryService: CountryService,
    private readonly _snackBar: MatSnackBar
  ) { }

  public ngOnInit(): void {
    this._project = this.activatedRoute.paramMap.pipe(
      map((params: ParamMap) => [params.get('key'), params.get('invoiceKey')]),
      switchMap(([key, invoiceKey]) => {
        return combineLatest([
          this.projectService.getProjectById(key),
          this.invoiceService.getInvoiceById(key, invoiceKey),
          this.serviceServices.getProjectShoppedServices(key)
        ])
      }),
      switchMap(([project, invoice, service]) => {
        return combineLatest([
          of(project),
          of(invoice),
          of(service),
          this.clientService.getClientById(project.clientId)
        ])
      })
    ).subscribe(([project, invoice, service, client]) => {
      this.project = project;
      this.client = client;
      this.service = service;
      this.invoice = invoice;
      this.hasInvoiceBeenValidated = this.invoice.hasBeenSent;
      this.isFetching = false;
    });
  }

  public ngOnDestroy(): void {
    this._project?.unsubscribe();
  }

  public updateInvoice(invoice: Invoice): void {
    this.invoice = invoice;

  }

  public async onAccountChange(accountId: string): Promise<void> {
    this.selectedBankAccount = await firstValueFrom(this.bankAccountService.getBankAccountById(accountId));
  }

  public async onValidate(): Promise<void> {
    const facturX: IFacturX = await this.convertToFacturx();
    const xml: string = await firstValueFrom(this.invoiceService.generateFacturXml(facturX));
    this.entityLogo = await firstValueFrom(this.enitityService.getEntityLogo(this.selectedEntity));
    const pdf: any = await this.invoiceService.createPdf(this.invoice, this.project, this.client, this.selectedEntity, this.selectedBankAccount, this.entityLogo, this.calculateLineAmount);
    return this.invoiceService.generatePdf(pdf).getBuffer((data: string) => {
      return this.invoiceService.validateInvoice(this.project.key, this.invoice, this.calculateLineAmount).then(() => {
        // if (!environment.disableInvoiceSend) {
        //   return this.invoiceService.sendInvoice(this.invoice, this.selectedEntity, facturx).then(() => {
        //     this._snackBar.open('La facture a bien été validée et envoyée', 'Fermer', {
        //       duration: 4000,
        //       verticalPosition: 'top',
        //       horizontalPosition: 'right',
        //       panelClass: ['green-snackbar']
        //     });
        //   });
        //   return;
        // }
        this._snackBar.open('La facture a bien été validée', 'Fermer', {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'right',
          panelClass: ['green-snackbar']
        });
        return Promise.resolve();
      });
      // return firstValueFrom(this.facturxService.getFacturXInvoice(data, xml)).then((facturx: Blob) => {
      //   return this.invoiceService.validateInvoice(this.project.key, this.invoice, this.calculateLineAmount).then(() => {
      //     if (!environment.disableInvoiceSend) {
      //       return this.invoiceService.sendInvoice(this.invoice, this.selectedEntity, facturx).then(() => {
      //         this._snackBar.open('La facture a bien été validée et envoyée', 'Fermer', {
      //           duration: 4000,
      //           verticalPosition: 'top',
      //           horizontalPosition: 'right',
      //           panelClass: ['green-snackbar']
      //         });
      //       });
      //     }
      //     this._snackBar.open('La facture a bien été validée', 'Fermer', {
      //       duration: 4000,
      //       verticalPosition: 'top',
      //       horizontalPosition: 'right',
      //       panelClass: ['green-snackbar']
      //     });
      //     return Promise.resolve();
      //   });
      // });
    });
  }

  public async generatePdf(): Promise<void> {
    if(!this.selectedEntity || !this.selectedEntity.logo) {
      this._snackBar.open('Veuillez sélectionner une entité avec un logo', 'Fermer', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
      });
      return;
    }
    this.entityLogo = await firstValueFrom(this.enitityService.getEntityLogo(this.selectedEntity));
    const pdf: any = await this.invoiceService.createPdf(this.invoice, this.project, this.client, this.selectedEntity, this.selectedBankAccount,this.entityLogo, this.calculateLineAmount);
    return this.invoiceService.generatePdf(pdf).getDataUrl((dataUrl: string) => {
      this.dataUrl = dataUrl;
    });
  }

  public async generatePdfWithFacturx(): Promise<void> {
    const facturX: IFacturX = await this.convertToFacturx();
    const xml: string = await firstValueFrom(this.invoiceService.generateFacturXml(facturX));
    const pdf: any = await this.invoiceService.createPdf(this.invoice, this.project, this.client, this.selectedEntity, this.selectedBankAccount, this.entityLogo, this.calculateLineAmount);
    return this.invoiceService.generatePdf(pdf).getBuffer((buffer: string) => {
      const url = window.URL.createObjectURL(new Blob([buffer], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.invoice.label}.pdf`;
      link.click();
    });
    // return this.invoiceService.generatePdf(pdf).getBuffer((data: string) => {
    //     return firstValueFrom(this.facturxService.getFacturXInvoice(data, xml)).then((facturx: Blob) => {
    //       console.log(facturx);
    //       //Download the file
    //       const url = window.URL.createObjectURL(facturx);
    //       const link = document.createElement('a');
    //       link.href = url;
    //       link.download = `${this.invoice.label}.pdf`;
    //       link.click();
    //     });
    // });
  }

  private async convertToFacturx(): Promise<IFacturX> {
    const dueDate: Date = moment(this.invoice.plannedDate).add(this.invoice.due, 'days').toDate();
    const items: IFactureItem[] = this.getFacturXItems(this.invoice.lines, this.invoice.tvaRate);
    const baseTotal: number = this.getBaseTotal(items);
    const taxTotal: number = this.getTaxTotal(items);
    const clientCountryCode: string = await firstValueFrom(this.countryService.getCountryCodeByLabel(this.client.society.country));
    return {
      invoiceProfile: 'urn:cen.eu:en16931:2017#compliant#urn:factur-x.eu:1p0:basic',
      invoiceNum: this.invoice.invoiceNumber,
      invoiceCode: this.invoice.type,
      includedNotes: [],
      issuedDate: formatDate(this.invoice.plannedDate, 'yyyyMMdd', 'en-US'),
      items: items,
      sellerTradeParty: {
        name: this.selectedEntity.name,
        siret: this.selectedEntity.siret,
        zipCode: this.selectedEntity.zipCode,
        city: this.selectedEntity.city,
        countryId: this.selectedEntity.country === 'France' ? 'FR' : 'MA',
        vat: this.selectedEntity.intracommunautaire,
        addrLineOne: this.selectedEntity.addr1,
      },
      buyerTradeParty: {
        name: this.client.name || `${this.client.lastName} ${this.client.firstName}`,
        siret: this.client.society.siret,
        zipCode: this.client.society.zipCode,
        city: this.client.society.city,
        countryId: clientCountryCode,
        vat: this.client.society.intracommunautaire,
        addrLineOne: this.client.society.adress,
      },
      tradeSettlement: {
        currencyCode: 'EUR',
        paymentMean: this.invoice.paymentMean,
        iban: this.selectedBankAccount.iban,
        ref: this.invoice.invoiceNumber,
      },
      referencedDocument: {
        buyerAssignedId: this.invoice.invoiceNumber,
        contractAssignedId: this.invoice.contractNumber,
      },
      dueDate: formatDate(dueDate, 'yyyyMMdd', 'en-US'),
      monetarySummation: {
        lineTotal: baseTotal.toFixed(2),
        taxBasisTotal: baseTotal.toFixed(2),
        taxTotal: taxTotal.toFixed(2),
        grandTotal: (baseTotal + taxTotal).toFixed(2),
        totalPrepaid: '0',
        dueAmount: (baseTotal + taxTotal).toFixed(2)
      },
      tradeTax: {
        basisAmount: baseTotal.toFixed(2),
        calculatedAmount: taxTotal.toFixed(2),
        categoryCode: 'S',
        rate: this.invoice.tvaRate
      }
    };
  }

  public return(): Promise<boolean> {
    return this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  }

  private calculateLineAmount(amount: number, unitPrice: number, unit: string, tvaRate: string): number {
    if(unit === 'P1') {
      return +(amount * unitPrice / 100).toFixed(2) * (1 + Number(tvaRate) / 100);
    } else {
      return +(amount * unitPrice).toFixed(2) * (1 + Number(tvaRate) / 100);
    }
  }

  private getFacturXItems(lines: InvoiceLine[], tvaRate: number): IFactureItem[] {
    return lines.map((line: InvoiceLine, index: number) => ({
      id: index + 1,
      itemId: '',
      itemName: line.name,
      unitPrice: line.unitPrice,
      quantity: line.quantity,
      taxCategory: 'S',
      taxPercent: tvaRate,
      totalAmount: this.calculateLineAmount(line.quantity, line.unitPrice, line.unit, line.tvaRate),
      unitCode: line.unit
    }))
  }

  private getBaseTotal(items: IFactureItem[]): number {
    return items.reduce((acc, curr) => acc + curr.totalAmount, 0);
  }

  private getTaxTotal(items: IFactureItem[]): number {
    return items.reduce((acc, curr) => acc + ((curr.taxPercent/100) * curr.totalAmount), 0);
  }

}
