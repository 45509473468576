import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IClient } from '@core/models/client.models';
import { IProjet } from '@core/models/projet.models';
import { IService } from '@core/models/service.models';
import { ClientsService } from '@core/services/clients.service';
import { ProjetsService } from '@core/services/projets.service';
import { ServicesService } from '@core/services/services.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-validation-projects',
  templateUrl: './validation-projects.component.html',
  styleUrls: ['./validation-projects.component.scss']
})
export class ValidationProjectsComponent implements OnInit, OnDestroy {
  @Input() projet: IProjet;
  @Input() shoppedServices: IService[];
  public validHover = false;

  private client: IClient;
  private _client: Subscription;

  constructor(
    public projetsService: ProjetsService,
    public servicesService : ServicesService,
    private clientService: ClientsService,
    private router: Router) { }


  ngOnDestroy(): void {
    this._client?.unsubscribe();
  }

  ngOnInit(): void {
    this.projet = this.projetsService.currentProject;
    this._client = this.clientService.getClientById(this.projet?.clientId).subscribe((client) => this.client = client);
    this.shoppedServices = this.servicesService.shoppedServices;
  }

  getMontantTotalByService(service: IService) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return +(service.articles && service.articles.length ?
      service.articles.map(article => article.amount * article.cost).reduce(reducer) : 0).toFixed(2);
  }

  getChargeTotalByService(service: IService) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return (service.articles && service.articles.length ?
      service.articles.map(article => article.amount).reduce(reducer) : 0);
  }

  public async validateProject(): Promise<boolean> {
    let notNamedArticles: IService[] = this.shoppedServices.filter(element => element.articles.find(article => article.name === ''));
    if (notNamedArticles.length != 0) {
      let alertMessage = 'Veuillez vérifier les services suivant :\n';
      notNamedArticles.forEach(element => {
        alertMessage += "- " + element.name + "\n"
      });
      alertMessage += "Un article ou plus de leur collection ne possède pas de nom."
      alert(alertMessage)
    } else {
      if (confirm('Êtes vous sur de vouloir valider le projet ?')) {
        await this.projetsService.validateProject(this.projet, this.shoppedServices);
        return this.router.navigateByUrl(`project/${this.projet.key}`);
      }
    }

  }

  getLinkToTasker(){
    return `${environment.taskerURL}/home/Projets/${this.client?.key}/${this.projet?.projectTasker}`;
  }
}
