import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IService } from '@core/models/service.models';

@Component({
  selector: 'app-add-services',
  templateUrl: './add-services.component.html',
  styleUrls: ['./add-services.component.scss']
})
export class AddServicesComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public data : IService, private dialogRef: MatDialogRef<AddServicesComponent>) { }

  ngOnInit() {
    if (!this.data) {
      this.data = {
        name: null,
        unity: null,
        addedDate : null,
        updatedDate: null,
        addedBy : null,
        updatedBy : null,
        articles : null,
        disabled : null
      };
    }
  }
  onSubmit() {
    this.dialogRef.close(this.data);
  }
}


