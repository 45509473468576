import { IClient } from './client.models';
import { SupplierExtended } from './supplier.model';

export interface ClientSearch {
  resemblence: number;
  isInDatabase: boolean;
  client: Partial<IClient>;
}

export interface SupplierSearch {
  resemblence: number;
  isInDatabase: boolean;
  supplier: Partial<SupplierExtended>;
}

export interface PapperSearchResult {
  name: string;
  address?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  type?: string;
  situation?: string;
  siret: string;
  siren: string;
}

export function buildNewClient(result: PapperSearchResult): IClient {
  return {
    added: {
      by: null,
      date: new Date(),
    },
    comments: [],
    contact: null,
    inCharge: null,
    inactif: false,
    name: result.name,
    nbProjects: 0,
    reglementDate: 30,
    tvaRate: 20,
    society: {
      adress: result.address || '',
      city: result.city || '',
      siret: result.siret,
      zipCode: result.zipCode || '',
      country: result.country || '',
    }
  }
}

export function buildNewSupplier(result: PapperSearchResult): SupplierExtended {
  return {
    actif: true,
    added: {
      by: null,
      date: new Date(),
    },
    dueTerm: 30,
    referentAccountId: [],
    societyId: '',
    type: '',
    society: {
      address: result.address || '',
      bankAccountId: [],
      city: result.city || '',
      country: result.country || '',
      name: result.name,
      siren: result.siren,
      siret: result.siret,
      situation: result.situation || '',
      type: result.type || '',
      zipCode: result.zipCode || '',
    }
  }
}


