import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExternalAuthGuard } from '@core/guards/external-auth.guard';
import { AuthGuardService } from '@core/services/auth-guard.service';
import { environment } from 'src/environments/environment';

const GUARDS = environment.connectFrom === 'LDAP' ? [AuthGuardService] : [ExternalAuthGuard];

const routes: Routes = [

  { path: 'dashboard',canActivate: GUARDS, loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'client',canActivate: GUARDS, loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule) },
  { path: 'project',canActivate: GUARDS, loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule) },
  { path: 'services',canActivate: GUARDS, loadChildren: () => import('./gest-services/gest-services.module').then(m => m.GestServicesModule) },
  { path: 'paragraph',canActivate: GUARDS, loadChildren: () => import('./gest-paragraphes/gest-paragraphes.module').then(m => m.GestParagraphesModule) },
  { path: 'supplier',canActivate: GUARDS, loadChildren: () => import('./suppliers/suppliers.module').then(m => m.SuppliersModule) },
  { path: 'devis',canActivate: GUARDS, loadChildren: () => import('./devis-express/devis-express.module').then(m => m.DevisExpressModule) },
  { path: 'restricted-access', loadChildren: () => import('./restricted-access/restricted-access.module').then(m => m.RestrictedAccessModule) },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
