import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesRoutingModule } from './pages-routing.module';
import { ClientsModule } from './clients/clients.module';
import { ProjectsModule } from './projects/projects.module';
import { GestServicesModule } from './gest-services/gest-services.module';
import { GestParagraphesModule } from './gest-paragraphes/gest-paragraphes.module';
import { DevisExpressModule } from './devis-express/devis-express.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PagesRoutingModule,
    ClientsModule,
    ProjectsModule,
    GestServicesModule,
    GestParagraphesModule,
    DevisExpressModule
  ]
})
export class PagesModule { }
