import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IClient } from '@core/models/client.models';
import { IContact } from '@core/models/contacts.model';
import { AuthService } from '@core/services/auth.service';
import { ClientsService } from '@core/services/clients.service';
import { FilterClientsService } from '@core/services/filter-clients.service';
import { SocietyService } from '@core/services/society.service';
import { ClientCreationComponent } from '@pages/clients/modals/client-creation/client-creation.component';
import { FilterClientsComponent } from '@pages/clients/modals/filter-clients/filter-clients.component';
import { ResearchClientComponent } from '@shared/client/modal/research-client/research-client.component';
import moment from 'moment';
import { environment } from 'src/environments/environment';

export interface DialogDataClients {
  clientsSelect: IClient[];
}

@Component({
  selector: 'app-list-clients',
  templateUrl: './list-clients.component.html',
  styleUrls: ['./list-clients.component.scss'],
})
export class ListClientsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() rowTable;
  @Output() updateClient = new EventEmitter<IClient>();

  public displayedColumns: string[] = ['name'];
  public currentClient: IClient = null;
  public clientsSelect: IClient[] = [];
  public filterSelect = 'allProject';
  public dataSource: MatTableDataSource<IClient> =
    new MatTableDataSource<IClient>();
  private subscribeClientsChange;


  constructor(
    private clientsService: ClientsService,
    public filterClientsService: FilterClientsService,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private societyService: SocietyService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Clients par page';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 sur ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    if (this.rowTable) {
      this.displayedColumns.push('nbProjects', 'dateAjout');
    }
    this.subscribeClientsChange = this.clientsService.clientsChange.subscribe(
      () => {
        this.setDatasource();
      }
    );
    this.setDatasource();
  }

  ngOnDestroy() {
    if (this.subscribeClientsChange) {
      this.subscribeClientsChange.unsubscribe();
    }
  }

  private setDatasource() {
    this.clientsSelect = this.filterClientsService.getAllClientFiltered(
      JSON.parse(localStorage.getItem('clientsSelect'))
    );
    this.dataSource.data = this.clientsSelect;
    this.dataSource.filterPredicate = (data: IClient, filter: string) => {
      return data.name.toLowerCase().includes(filter);
    };
    this.dataSource.sortData = (data: IClient[], sort: MatSort) => {
      return data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name':
            return this.compare(a.name, b.name, isAsc);
          default:
            return 0;
        }
      });
    }
  }

  selectClient(client: IClient) {
    this.currentClient = client;
    if (!this.currentClient.contact)
      this.currentClient.contact = {} as IContact;
    this.updateClient.emit(this.currentClient);
  }

  showClient(key: string) {
    this.router.navigateByUrl(`project/client/${key}`);
  }

  public applyFilter(search: Event): void {
    const filterValue: string = (search.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) this.dataSource.paginator.firstPage();
  }

  addClient() {
    this.dialog.open(ClientCreationComponent, {
      width: '60%',
      height: '80%'
    });
  }

  addFilterClient(): void {
    let dialogRef = this.dialog.open(FilterClientsComponent, {
      width: '400px',
      data: { filter: this.clientsSelect },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.clientsSelect =
        this.filterClientsService.getAllClientFiltered(result);
      this.setDatasource();
    });
  }

  stopProp(event) {
    event.stopPropagation();
  }

  public sortData(sort: MatSort): void {
    const data: IClient[] = this.dataSource.data.slice();
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'nbProjects':
          return this.compare(a.nbProjects, b.nbProjects, isAsc);
        case 'dateAjout':
          return this.compareDate(a.added.date, b.added.date, isAsc);
        default:
          return 0;
      }
    });
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private compareDate(a: Date, b: Date, isAsc: boolean) {
    return (moment(a).isBefore(b) ? -1 : a === b ? 0 : 1) * (isAsc ? 1 : -1);
  }

  public researcherClient(): void {
    const dialogRef: MatDialogRef<ResearchClientComponent> = this.dialog.open(
      ResearchClientComponent,
      {
        width: '60%',
        data: '',
      }
    );
    dialogRef
      .afterClosed()
      .pipe()
      .subscribe((client: IClient) => {
        this.societyService.updateSocietyList();
        this.clientsService.updateClients();
        this.selectClient(client);
      });
  }

  public navigateToDevisExpress() {
    this.router.navigate(['devis-express'], { relativeTo: this.activatedRoute });
  }

  public getClientName(client: IClient): string {
    return client.name && client.name.length > 0 ? client.name : `${client.lastName} ${client.firstName}`;
  }

}
