import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevisExpressComponent } from './devis-express.component';
import { ListClientsComponent } from '@pages/clients/components/list-clients/list-clients.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    component: environment.connectFrom === 'LDAP' ? ListClientsComponent :  DevisExpressComponent
  },
  {
    path: 'devis-express',
    component: DevisExpressComponent
  },
  {
    path: 'devis-express/:id',
    component: DevisExpressComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevisExpressRoutingModule { }
