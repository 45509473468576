import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, DocumentSnapshot, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { IEntity } from '@core/models/entity.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  private readonly COLLECTION_NAME = 'entity';

  public entities$$: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  constructor(
    private http: HttpClient,
    private readonly firestore: AngularFirestore,
    private readonly storage: AngularFireStorage
  ) { }

  public get entities$(): Observable<IEntity[]> {
    return this.entities$$.pipe(
      switchMap(() => this.getEntities())
    );
  }

  public updateEntities(): void {
    this.entities$$.next(null);
  }

  public getEntities(): Observable<IEntity[]> {
    return this.firestore.collection<IEntity>(this.COLLECTION_NAME).get().pipe(
      map((entities: QuerySnapshot<DocumentData>) => entities.docs.map((entity: QueryDocumentSnapshot<DocumentData>) =>({
        ...entity.data(),
        key: entity.id
      } as IEntity)))
    );
  }

  public getEntity(id: string): Observable<IEntity> {
    return this.firestore.doc<IEntity>(`${this.COLLECTION_NAME}/${id}`).get().pipe(
      map((entity: DocumentSnapshot<DocumentData>) => ({
        ...entity.data(),
        key: entity.id
      } as IEntity))
    );
  }

  public getEntityLogo(entity: IEntity): Observable<string> {
    if (environment.connectFrom === 'FIREBASE') {
      const reference =  this.storage.ref(entity.logo);
      return reference.getDownloadURL();
    } else {
      const url: string = `${environment.nodeProtocol}://${environment.nodeURL}/files/base64?path=${encodeURIComponent(entity.logo)}`;
      return this.http.get<string>(url).pipe(
        map((result: string) => `data:image/png;base64,${result}`)
      );
    }
  }


}
