import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClientSearch } from '@core/models/search.model';
import { ClientsService } from '@core/services/clients.service';

@Component({
  selector: 'app-client-creation-search',
  templateUrl: './client-creation-search.component.html',
  styleUrls: ['./client-creation-search.component.scss']
})
export class ClientCreationSearchComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() public searchClient: FormGroup;

  @Output() public goToClientClick: EventEmitter<ClientSearch> = new EventEmitter<ClientSearch>();

  @Output() public clientSelection: EventEmitter<ClientSearch> = new EventEmitter<ClientSearch>();

  public hasSearch: boolean = false;
  public searchDataSource: MatTableDataSource<ClientSearch> = new MatTableDataSource<ClientSearch>();
  public selection: SelectionModel<ClientSearch> = new SelectionModel<ClientSearch>(false, []);
  public searchDisplayedColumns: string[] = ['name', 'siret', 'city', 'zipCode', 'inDatabase', 'resemblence', 'actions'];

  public constructor(
    private readonly clientService: ClientsService,
  ) {}

  public get isSearchValid(): boolean {
    return (this.searchClient.get('name').value || this.searchClient.get('siret').value) && !(this.searchClient.get('name').value && this.searchClient.get('siret').value);
  }

  public async searchClientFn(): Promise<void> {
    this.hasSearch = true;
    const firebaseResult: ClientSearch[] = await this.clientService.searchFirebaseClient(this.searchClient.get('siret').value, this.searchClient.get('name').value);
    const pappersResult: ClientSearch[] = await this.clientService.searchPappersClient(this.searchClient.get('siret').value, this.searchClient.get('name').value);
    const pappersResultFiltered = pappersResult.filter((papper) => !firebaseResult.some((firebase) => firebase.client?.society?.siret && firebase.client?.society.siret === papper.client?.society.siret));
    this.searchDataSource.data = [...firebaseResult, ...pappersResultFiltered];
    this.searchDataSource.paginator = this.paginator;
  }

  public goToClient(row: ClientSearch): void {
    this.goToClientClick.emit(row);
  
  }

  public isSelected(row: ClientSearch): boolean {
    return this.selection.isSelected(row);
  }

  public selectClient(row: ClientSearch): void {
    this.selection.toggle(row);
    const isToggled = this.selection.isSelected(row);
    this.clientSelection.emit(isToggled ? row : null);
  }

}