import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { InvoiceDevisExpressLine, InvoiceLine } from '@core/models/invoice.model';
import { IArticle, IService } from '@core/models/service.models';
import { UnitCode, UnitLabel } from '@core/models/units.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-devis-invoice-line',
  templateUrl: './devis-invoice-line.component.html',
  styleUrls: ['./devis-invoice-line.component.scss']
})
export class DevisInvoiceLineComponent {

  /** The lines */
  @Input() public line: InvoiceDevisExpressLine;

  /** The service */
  @Input() public service: IService;

  /** Emit delete line event */
  @Output() public deleteLine: EventEmitter<InvoiceLine> = new EventEmitter<InvoiceLine>();

  /** The name control */
  public nameControl: FormControl = new FormControl();

  /** The articles filtered */
  public filteredArticles$: Observable<string[]>;

  /** The unit code values */
  public UNIT_CODE_VALUES: ReadonlyArray<UnitCode> = UnitLabel.map((unit) => unit.value);

  /** Creates an instance of DevisInvoiceLineComponent */
  constructor() {
    this.filteredArticles$ = this.nameControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value))
    )
  }

  /**
   * Filters the articles based on the input value.
   *
   * @param value - The input value to filter articles by.
   * @returns An array of filtered article names.
   * @memberof DevisInvoiceLineComponent
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.service.articles.filter((option: IArticle) => option.name.toLowerCase().includes(filterValue)).map((article: IArticle) => article.name);
  }

  /**
   * Handles the selection of an article from the autocomplete list.
   *
   * @param event - The MatAutocompleteSelectedEvent object containing the selected option.
   * @memberof DevisInvoiceLineComponent
   */
  public onArticleSelect(event: MatAutocompleteSelectedEvent): void {
    const article: IArticle = this.service.articles.find(article => article.name === event.option.value);
    this.line.unit = this.service.unity === 'J' ? UnitLabel.find((unit) => unit.label === 'Jour').value : UnitLabel.find((unit) => unit.label === 'Mois').value;
    this.line.unitPrice = article.cost;
    this.line.quantity = 0;
  }

  /**
   * Removes the current line from the invoice.
   *
   * Emits a deleteLine event with the current line as the payload.
   *
   * @returns {void}
   * @memberof DevisInvoiceLineComponent
   */
  public removeLine(): void {
    this.deleteLine.emit(this.line);
  }

}
