import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IClient } from '@core/models/client.models';
import { IContact } from '@core/models/contacts.model';
import { IEntity } from '@core/models/entity.model';
import {
  Error,
  ErrorValidator,
  ThrowableValue,
  validateThrowableValue,
} from '@core/models/error.model';
import { Invoice } from '@core/models/invoice.model';
import { IFacturePDF, IProjet } from '@core/models/projet.models';
import { IUser } from '@core/models/users.models';
import { AuthService } from '@core/services/auth.service';
import { ClientsService } from '@core/services/clients.service';
import { ContactsService } from '@core/services/contacts.service';
import { EntityService } from '@core/services/entity.service';
import { InvoiceService } from '@core/services/invoice.service';
import { ProjetsService } from '@core/services/projets.service';
import { UserService } from '@core/services/user.service';
import {
  missingClientAddressErrorValidator,
  missingClientCityErrorValidator,
  missingClientCountryErrorValidator,
  missingClientIntracommunautaireErrorValidator,
  missingClientNameErrorValidator,
  missingClientReglementDateErrorValidator,
  missingClientSiretErrorValidator,
  missingClientZipCodeErrorValidator,
} from '@pages/projects/errors/invoice.error';
import { AddDueDateComponent } from '@pages/projects/modals/add-due-date/add-due-date.component';
import { ClientErrorModalComponent } from '@pages/projects/modals/client-error-modal/client-error-modal.component';
import { firestore } from 'firebase';
import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  firstValueFrom,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-gestion-projet',
  templateUrl: './gestion-projet.component.html',
  styleUrls: ['./gestion-projet.component.scss'],
})
export class GestionProjetComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public isLoading: boolean = false;
  public project: IProjet;
  public _project: Subscription;
  public remainsToBeBilled: number = 0;
  public projectPercente: number = 0;
  public projectExist: boolean = false;
  private _projectExist: Subscription;
  public billed: number = 0;
  public clients: IClient[];
  public selectedClient: IClient;
  public _clients: Subscription;
  public _selectedClient: Subscription;

  public projectForm: FormGroup;

  public entity: IEntity;

  public entityList: IEntity[] = [];

  public _invoices: Subscription[] = [];

  public displayedColumns: string[] = [
    'name',
    'amountHT',
    'numberFacture',
    'issuedFacture',
    'planningForecast',
    'editer',
    'update',
    'delete',
  ];
  public dataSource: MatTableDataSource<Invoice> =
    new MatTableDataSource<Invoice>([]);

  private invoices$$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null,
  );
  public _projectFacture: Subscription;
  public projectFacture: Invoice[] = [];
  public pdf: IFacturePDF;

  private oldInvoice: Invoice[] = [];

  public contacts: IContact[] = [];

  public currentUser: IUser;
  private _currentUser: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjetsService,
    private clientService: ClientsService,
    private userService: UserService,
    private authService: AuthService,
    private readonly invoiceService: InvoiceService,
    private dialog: MatDialog,
    private readonly functions: AngularFireFunctions,
    private readonly contactService: ContactsService,
    private readonly entityService: EntityService,
  ) {}

  public ngOnInit(): void {
    this._currentUser = this.authService.user$.subscribe(
      (user) => (this.currentUser = user),
    );
    this.initForm();
    this._projectExist = this.activatedRoute.paramMap.subscribe((param) => {
      this.projectExist = (param.get('key') as string) !== 'addProject';
      this._clients = this.clientService.getAllClient().subscribe((clients) => {
        this.clients = clients;
        if (!this.projectExist) {
          const client = this.router.url.split('?client=');
          if (client.length >= 2) {
            this.projectForm
              .get('clientId')
              .patchValue(
                this.clients.find((e) => e.name === client[1]).key ?? null,
              );
            this.changeClient(
              new MatSelectChange(
                null,
                this.clients.find((e) => e.name === client[1]).key ?? null,
              ),
            );
          }
        }
      });

      if (this.projectExist) {
        this.fetchProject();
      }

      this.entityService.getEntities().subscribe((entities) => {
        this.entityList = entities;
        this.setDefaultEntity();
      });
    });
  }

  private setDefaultEntity(): void {
    const defaultEntity = this.entityList.find(
      (entity) => entity.value === 'FA',
    );
    this.projectForm
      .get('entity')
      .setValue(defaultEntity ? defaultEntity.key : null);
  }

  public ngOnDestroy(): void {
    this._currentUser.unsubscribe();
    this.unSub();
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  private unSub(): void {
    this._project && this._project.unsubscribe();
    this._clients && this._clients.unsubscribe();
    this._projectExist && this._projectExist.unsubscribe();
    this._selectedClient && this._selectedClient.unsubscribe();
    this._projectFacture && this._projectFacture.unsubscribe();
    this._invoices.length > 0 && this._invoices.forEach((s) => s.unsubscribe());
  }

  public fetchProject(): void {
    this._project = this.projectService
      .getProjectById(this.activatedRoute.snapshot.params.key)
      .pipe(
        switchMap((project: IProjet) => {
          this.invoices$$.next(project.key);
          return combineLatest([
            of(project),
            this.clientService.getClientById(project.clientId),
            this.invocies$,
          ]);
        }),
      )
      .subscribe(async ([projet, client, invoices]) => {
        if (!this.project) {
          this.project = projet;
          this.projectService.currentProject = this.project;
          this.selectedClient = client;
          this.contacts = await firstValueFrom(this.getContacts(client.key));
          const incharge: IUser = this.userService.getUsersWithKey(
            this.selectedClient.inCharge,
          );
          const creator: IUser = this.userService.getUsersWithKey(
            this.project.added.by,
          );
          this.projectForm.get('name').patchValue(this.project.name);
          this.projectForm.get('rebound').patchValue(this.project.rebond);
          this.projectForm
            .get('clientDemand')
            .patchValue(this.project.demandeClient);
          this.projectForm.get('state').patchValue(this.project.status);
          this.projectForm.get('amountHT').patchValue(this.project.price);
          this.projectForm
            .get('signatureDate')
            .patchValue(
              this.dateToFormat(this.project.dateSignature, 'YYYY-MM-DD'),
            );
          this.projectForm
            .get('orderReceived')
            .patchValue(this.project.orderForm);
          this.projectForm
            .get('orderNum')
            .patchValue(this.project.numBonDeCommande);
          this.projectForm
            .get('numContract')
            .patchValue(this.project.numContract);
          this.projectForm
            .get('receivedPV')
            .patchValue(this.project.pv?.receivedPV);
          this.projectForm
            .get('pvDate')
            .patchValue(
              this.dateToFormat(this.project.pv?.datePV, 'YYYY-MM-DD'),
            );
          this.projectForm.get('clientId').patchValue(this.selectedClient.key);
          this.projectForm
            .get('contact')
            .patchValue(this.project.projectContactId);
          this.projectForm
            .get('address')
            .patchValue(this.selectedClient?.society?.adress);
          this.projectForm
            .get('clientManager')
            .patchValue(`${incharge?.firstName} ${incharge?.lastName}`);
          this.projectForm.get('entity').patchValue(this.project.entiteId);
          this.projectForm
            .get('projectCreator')
            .patchValue(
              creator ? `${creator.firstName} ${creator.lastName}` : '',
            );
          this.project.pv?.receivedPV
            ? this.projectForm.get('pvDate').enable()
            : this.projectForm.get('pvDate').disable();
          this.project.demandeClient
            ? this.projectForm.get('rebound').disable()
            : this.projectForm.get('rebound').enable();
          this.project.rebond
            ? this.projectForm.get('clientDemand').disable()
            : this.projectForm.get('clientDemand').enable();
          this.projectForm.updateValueAndValidity();
        }
        this.projectFacture = invoices;
        const totalFacture = this.projectFacture
          .filter((invoice) => invoice.hasBeenSent && invoice.type === 380)
          .reduce((acc, curr) => acc + curr.amount, 0);
        const totalAvoir = this.projectFacture
          .filter((invoice) => invoice.hasBeenSent && invoice.type === 381)
          .reduce((acc, curr) => acc + curr.amount, 0);
        this.remainsToBeBilled = this.project.price - totalFacture + totalAvoir;
        this.projectPercente = Math.round(
          (this.remainsToBeBilled / this.project.price) * 100,
        );
        this.dataSource.data = this.projectFacture.sort(
          (a, b) => b.plannedDate.getTime() - a.plannedDate.getTime(),
        );
      });
  }

  private getContacts(parentId: string): Observable<IContact[]> {
    return this.contactService
      .getContactByParentId(parentId)
      .pipe(
        map((contacts: IContact[]) =>
          contacts.filter((contact) => contact.type === 'Opérationnel'),
        ),
      );
  }

  public initForm(): void {
    this.projectForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      rebound: new FormControl(false, [Validators.nullValidator]),
      clientDemand: new FormControl(false, [Validators.nullValidator]),
      state: new FormControl('Opportunité', [
        Validators.nullValidator,
        Validators.required,
      ]),
      amountHT: new FormControl(0, [Validators.nullValidator]),
      signatureDate: new FormControl('', [Validators.nullValidator]),
      orderReceived: new FormControl(false, [Validators.nullValidator]),
      orderNum: new FormControl('', [Validators.nullValidator]),
      numContract: new FormControl('', [Validators.nullValidator]),
      receivedPV: new FormControl(false, [Validators.nullValidator]),
      pvDate: new FormControl('', [Validators.nullValidator]),
      clientId: new FormControl(null, [Validators.nullValidator]),
      contact: new FormControl('', [Validators.nullValidator]),
      address: new FormControl('', [Validators.nullValidator]),
      clientManager: new FormControl('', [Validators.nullValidator]),
      entity: new FormControl('', [Validators.required]),
      projectCreator: new FormControl('', [Validators.nullValidator]),
    });
  }

  public getProjectFromForm(): IProjet {
    return {
      added: this.project ? (this.project.added ?? null) : null,
      clientId: this.projectForm.get('clientId').value ?? null,
      name: this.projectForm.get('name').value ?? null,
      projectTasker: this.project ? this.project.projectTasker : null,
      validate: this.project ? this.project.validate : 0,
      dateSignature:
        this.convertDate(this.projectForm.get('signatureDate').value) ?? null,
      demandeClient: this.projectForm.get('clientDemand').value ?? null,
      key: this.project ? (this.project.key ?? null) : null,
      numBonDeCommande: this.projectForm.get('orderNum').value ?? null,
      orderForm: this.projectForm.get('orderReceived').value ?? null,
      price: this.projectForm.get('amountHT').value ?? null,
      pv: {
        receivedPV: this.projectForm.get('receivedPV').value ?? null,
        datePV: this.convertDate(this.projectForm.get('pvDate').value) ?? null,
      },
      rebond: this.projectForm.get('rebound').value ?? null,
      status: this.projectForm.get('state').value ?? null,
      updated: {
        by: this.authService.user?.key ?? null,
        date: new Date(),
      },
      projectContactId: this.projectForm.get('contact').value ?? null,
      numContract: this.projectForm.get('numContract').value ?? null,
      entiteId: this.projectForm.get('entity').value,
    };
  }
  public async submit(): Promise<void> {
    if (this.projectExist) {
      if (
        this.projectForm.get('state').value === 'Terminé' &&
        !window.confirm(
          'Êtes-vous sûr de vouloir terminer ce projet ? Toutes les tâches et sous-tâches seront archivées.',
        )
      )
        return;
      if (this.projectForm.get('state').value === 'Terminé') {
        if (this.project.projectTasker) {
          await firstValueFrom(
            this.functions.httpsCallable('archiveProject')(
              this.project.projectTasker,
            ),
          );
        }
      }
      this.projectService.updateProject(this.getProjectFromForm());
    } else {
      this.projectService
        .createProject(this.getProjectFromForm(), this.currentUser.key ?? null)
        .then((id) => {
          this.router.navigate([`project/${id}`], {
            relativeTo: this.activatedRoute,
          });
        });
    }
  }

  public updateIssuedFacture(
    invoice: Invoice,
    event: MatCheckboxChange,
  ): Promise<void | void[]> {
    if (event.checked) {
      return this.invoiceService.validateInvoice(
        this.project.key,
        invoice,
        this.calculateLineAmount,
      );
    }
  }

  private calculateLineAmount(
    amount: number,
    unitPrice: number,
    unit: string,
  ): number {
    if (unit === 'P1') {
      return (amount * unitPrice) / 100;
    } else {
      return amount * unitPrice;
    }
  }

  public updateFacture(element: Invoice): void {
    this._invoices.push(
      this.dialog
        .open(AddDueDateComponent, {
          width: '50%',
          height: 'auto',
          data: {
            project: this.project,
            remainsToBeBilled: this.remainsToBeBilled,
            create: false,
            client: this.selectedClient,
            invoice: element,
          },
        })
        .afterClosed()
        .subscribe(() => {
          this.invoices$$.next(this.project.key);
        }),
    );
  }

  public async deleteInvoice(element: Invoice): Promise<void> {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette échéance ?')) {
      return this.invoiceService
        .deleteInvoice(this.project.key, element.id)
        .then(() => this.invoices$$.next(this.project.key));
    }
  }

  public editerFacture(element: Invoice): void {
    this.router.navigate(['invoice', element.id], {
      relativeTo: this.activatedRoute,
    });
  }

  public async addDueDate(): Promise<void> {
    const throwableValue: ThrowableValue<IClient> = {
      value: this.selectedClient,
      validators: this.INVOICE_VALIDATORS,
    };
    const errors: Error<IClient>[] =
      await validateThrowableValue(throwableValue);
    if (errors.length === 0) {
      this._invoices.push(
        this.dialog
          .open(AddDueDateComponent, {
            data: {
              project: this.project,
              remainsToBeBilled: this.remainsToBeBilled,
              create: true,
              due: this.selectedClient.reglementDate.toString(),
              tvaRate: this.selectedClient.tvaRate ?? 20,
              client: this.selectedClient,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.invoices$$.next(this.project.key);
          }),
      );
    } else {
      this.dialog.open(ClientErrorModalComponent, {
        data: {
          client: this.selectedClient,
          errors,
        },
      });
    }
  }

  private get INVOICE_VALIDATORS(): ErrorValidator<Error<IClient>>[] {
    return [
      missingClientNameErrorValidator,
      missingClientAddressErrorValidator,
      missingClientCityErrorValidator,
      missingClientCountryErrorValidator,
      missingClientReglementDateErrorValidator,
      missingClientIntracommunautaireErrorValidator,
      // missingClientSiretErrorValidator,
      missingClientZipCodeErrorValidator,
    ];
  }

  public changePV(event: MatCheckboxChange): void {
    event.checked
      ? this.projectForm.get('pvDate').enable()
      : this.projectForm.get('pvDate').disable();
  }

  public async changeClient(event: MatSelectChange): Promise<void> {
    const client: IClient = this.clients.find((c) => c.key === event.value);
    const incharge: IUser = this.userService.getUsersWithKey(client.inCharge);
    this.contacts = await firstValueFrom(this.getContacts(client.key));
    this.projectForm.patchValue({
      contact: '',
      address: client.society?.adress ?? '',
      clientManager: `${incharge?.firstName ?? ''} ${incharge?.lastName ?? ''}`,
    });
    this.projectForm.updateValueAndValidity();
  }

  public checkRebound(event: MatCheckboxChange): void {
    event.checked
      ? this.projectForm.get('clientDemand').disable()
      : this.projectForm.get('clientDemand').enable();
  }

  public checkDemandClient(event: MatCheckboxChange): void {
    event.checked
      ? this.projectForm.get('rebound').disable()
      : this.projectForm.get('rebound').enable();
  }

  public returnToDashboard(): Promise<boolean> {
    return this.router.navigate(['dashboard'], {
      relativeTo: this.activatedRoute,
    });
  }

  public makeDevis(): void {
    this.router.navigateByUrl(`project/${this.project.key}/devis/0`, {
      state: { data: this.project },
    });
  }

  private convertDate(date: Date): Date {
    return date
      ? (date as any).seconds
        ? new firestore.Timestamp((date as any).seconds, 0).toDate()
        : moment(date).toDate()
      : null;
  }

  private dateToFormat(date: Date, format: string): string {
    const convertedDate = this.convertDate(date);
    return convertedDate ? moment(convertedDate).format(format) : null;
  }

  private get invocies$(): Observable<Invoice[]> {
    return this.invoices$$.asObservable().pipe(
      switchMap((projectKey: string) => {
        return this.invoiceService.getInvoices(projectKey);
      }),
    );
  }

  public sortData(sort: Sort): void {
    this.dataSource.data.sort((a, b) => {
      return sort.direction === 'asc'
        ? a.plannedDate.getTime() - b.plannedDate.getTime()
        : b.plannedDate.getTime() - a.plannedDate.getTime();
    });
  }

  public get entityList$(): Observable<IEntity[]> {
    return this.entityService.entities$;
  }

  public get entityControl(): AbstractControl {
    return this.projectForm.get('entity');
  }

  public getClientName(client: IClient): string {
    return client.name && client.name.length > 0
      ? client.name
      : `${client.lastName} ${client.firstName}`;
  }

  public async deleteProject(): Promise<boolean> {
    if (!confirm('Voulez-vous vraiment supprimer ce projet ?')) return;
    await this.projectService.deleteProject(this.project.key);
    return this.returnToDashboard();
  }
}
