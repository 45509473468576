<div *ngIf="projectsService.currentProject" [ngSwitch]="projectsService.currentProject.validate">
  <div *ngSwitchCase="0">
    <div fxLayout="row">
      <div class="container" fxFlex id=gauche>
        <div fxLayout="row" fxLayoutAlign="center center">
          <h1>Services du projet</h1>
        </div>
        <app-services-project (sendCurrentService)="setCurrentService($event)" [projet]="projectsService.currentProject"
          [shoppedServices]="serviceService.shoppedServices" [multi]="false"></app-services-project>
      </div>
      <div class="container" fxFlex id=droite>
        <div fxLayout="column" fxLayoutAlign="center center">
          <app-modify-services (updateProject)="updateProjectPrice()" [inCurrentProject]="true" [currentService]="currentService"></app-modify-services>
        </div>
      </div>
    </div>
  </div>
  <p class="projectValidate" *ngSwitchCase="1">Le projet est en cours de validation et ne peut donc plus être
    modifié.</p>
  <p class="projectValidate" *ngSwitchCase="2">Le projet est déjà validé et ne peut donc plus être modifié.</p>
</div>
