<div class="header-content">
  <div class="btn">
    <button mat-raised-button (click)="addClient()" type="button" color="primary">Ajouter un client</button>

  </div>
  <mat-form-field appearance="outline">
    <mat-label>Rechercher un client</mat-label>
    <input matInput (keyup)="applyFilter($event)" />
  </mat-form-field>
</div>
<div class="mat-elevation-z8 table">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDisableClear matSortDirection="asc" (matSortChange)="sortData($event)">
    <ng-container matColumnDef="name">
      <th class="test" mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let element"> {{ getClientName(element) }} </td>
    </ng-container>

    <ng-container *ngIf="rowTable">
      <ng-container matColumnDef="nbProjects">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de projets</th>
        <td mat-cell *matCellDef="let element"> {{ element.nbProjects }} </td>
      </ng-container>

      <ng-container matColumnDef="dateAjout">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date d'ajout</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.added">{{ element.added.date.seconds * 1000 | date:'dd/MM/yyyy' }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showClient(row.key)" class="row">
      </tr>
    </ng-container>

    <ng-container *ngIf="!rowTable">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectClient(row)"
        [ngClass]="{'chapter-box': true, 'selected': row === currentClient}" class="row"></tr>
    </ng-container>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
