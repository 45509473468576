<div class="content">
  <button mat-raised-button type="button" color="primary" (click)="addContact()">Ajouter un contact</button>
  <div class="table">
    <table mat-table [dataSource]="this.dataSource" matSort matSortActive="name" matSortDirection="asc" (matSortChange)="sortData($event)">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
        <td mat-cell *matCellDef="let contact"> {{ this.getFullName(contact) }}</td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let contact"> {{contact.type}}</td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="2">Aucun contact trouvé !</td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let rowData; columns: displayedColumns" class="table-row" (click)="selectContact(rowData)"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5,10,25]"></mat-paginator>
  </div>
</div>
