import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentData, DocumentReference, DocumentSnapshot, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import moment from 'moment';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IBankAccount } from '../models/bank.model';
import { firestore } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  public bankAccounts$$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  public bankAccountsOwner$$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private readonly COLLECTION_NAME: string = 'bank-account';

  public devises: string[] = ['EUR', 'MAD', 'USD'];

  constructor(
    private http: HttpClient,
    private readonly firestore: AngularFirestore
  ) { }

  public get bankAccounts$(): Observable<IBankAccount[]> {
    return this.bankAccounts$$.pipe(
      switchMap(() => this.getBankAccount())
    )
  }

  public get bankAccountsByOwner$(): Observable<IBankAccount[]> {
    return this.bankAccountsOwner$$.pipe(
      switchMap((owner: string) => this.getBankAccountByOwner(owner))
    );
  }

  public updateBankAccountOwner(owner: string): void {
    this.bankAccountsOwner$$.next(owner);
  }

  public updateBankAccountList(): void {
    this.bankAccounts$$.next(null);
  }

  public getBankAccount(): Observable<IBankAccount[]> {
    return this.bankCollection.valueChanges({idField: 'id'});
  }

  public getBankAccountById(id: string): Observable<IBankAccount> {
    return this.firestore.collection<IBankAccount>(this.COLLECTION_NAME).doc(id).get().pipe(
      map((bankAccount: DocumentSnapshot<DocumentData>) => ({
        ...bankAccount.data(),
        id: bankAccount.id
      } as IBankAccount))
    );
  }

  public addBankAccount(bankAccount: IBankAccount): Observable<IBankAccount> {
    if(bankAccount) {
      return from(this.bankCollection.add({...bankAccount})).pipe(
        switchMap((ref: DocumentReference) => from(ref.get()).pipe(
          map((doc: DocumentSnapshot<DocumentData>) => ({
            ...doc.data(),
            id: doc.id
          } as IBankAccount))
        ))
      )
    }
    return null;
  }

  public updateBankAccount(bankAccount: IBankAccount): Observable<void> {
    const id: string = bankAccount.id;
    delete bankAccount.id;
    return from(this.bankCollection.doc(id).update({...bankAccount}));
  }

  public deleteBankAccount(id: string): Observable<void> {
    return from(this.bankCollection.doc(id).delete());
  }

  public getBankAccountByOwner(owner: string): Observable<IBankAccount[]> {
    return this.firestore.collection<IBankAccount>(this.COLLECTION_NAME).get().pipe(
      map((bankAccounts: QuerySnapshot<DocumentData>) => bankAccounts.docs.map((bankAccount: QueryDocumentSnapshot<DocumentData>) => ({
        ...bankAccount.data(),
        startDate: bankAccount.data().startDate ? new firestore.Timestamp(bankAccount.data().startDate.seconds, bankAccount.data().startDate.nanoseconds).toDate() : null,
        endDate: bankAccount.data().endDate ? new firestore.Timestamp(bankAccount.data().endDate.seconds, bankAccount.data().endDate.nanoseconds).toDate() : null,
        id: bankAccount.id
      } as IBankAccount))),
      map((banks: IBankAccount[]) => banks.filter((bank: IBankAccount) => bank.ownerId === owner)),
    )
  }

  private get bankCollection(): AngularFirestoreCollection<IBankAccount> {
    return this.firestore.collection<IBankAccount>(this.COLLECTION_NAME);
  }

}

