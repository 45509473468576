import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @HostBinding('class') componentCssClass;
  constructor(private themeService: ThemeService, public deviceDetectorService: DeviceDetectorService, private authService: AuthService) {
  }

  ngOnInit() {
    const isDesktop = this.deviceDetectorService.isDesktop();
    this.themeService.loadThemeInfo(!isDesktop);
    this.componentCssClass = this.themeService.getTheme();
    this.themeService.subscribeThemeChange().subscribe((theme) => {
      this.componentCssClass = theme;
    });
  }
}
