import { Component, Input, OnInit } from '@angular/core';
import { IService } from '@core/models/service.models';

@Component({
  selector: 'app-gest-serv',
  templateUrl: './gest-serv.component.html',
  styleUrls: ['./gest-serv.component.scss']
})
export class GestServComponent implements OnInit {
  public currentService: IService = null;
  constructor() { }

  ngOnInit(): void {
  }

  setCurrentService(service : IService) {
    this.currentService = service;
  }

}
