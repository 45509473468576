import { NgModule } from '@angular/core';

import { EditPdfRoutingModule } from './edit-pdf-routing.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { EditPdfComponent } from './components/edit-pdf/edit-pdf.component';
import { ShowPdfComponent } from './components/show-pdf/show-pdf.component';
import { SharedModule } from '@shared/shared.module';
import { GestParagraphesModule } from '@pages/gest-paragraphes/gest-paragraphes.module';


@NgModule({
  declarations: [EditPdfComponent, ShowPdfComponent],
  imports: [
    SharedModule,
    MatProgressSpinnerModule,
    EditPdfRoutingModule,
    GestParagraphesModule
  ]
})
export class EditPdfModule { }
