import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IClient, ISociete, ISociety } from '@core/models/client.models';
import { AuthService } from '@core/services/auth.service';
import { ClientsService } from '@core/services/clients.service';
import { SocietyService } from '@core/services/society.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-research-client',
  templateUrl: './research-client.component.html',
  styleUrls: ['./research-client.component.scss'],
})
export class ResearchClientComponent implements OnInit {
  public clients: IClient[] = [];
  public _clients: Subscription;
  public societies: ISociete[] = [];
  public _societies: Subscription;

  public researchDone: boolean = false;

  public selectedClient: ISociete;

  public displayedColumns: string[] = [
    'name',
    'address',
    'city',
    'zipCode',
    'type',
  ];

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public dataSource: MatTableDataSource<ISociety>;

  public _createClient: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<ResearchClientComponent>,
    private clientService: ClientsService,
    private societyService: SocietyService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this._clients = this.societyService.research$.subscribe((societies) => {
      this.societies = societies;
      this.dataSource = new MatTableDataSource<ISociety>(societies);
      this.dataSource.paginator = this.paginator;
    });
    this.selectedClient = null;
  }

  public ngOnDestroy(): void {
    this._clients && this._clients.unsubscribe();
    this._createClient && this._createClient.unsubscribe();
    this._societies && this._societies.unsubscribe();
  }

  public research(): void {
    this.societyService.updateResearchList(this.data);
    this.researchDone = true;
  }

  public selectClient(client: ISociete): void {
    this.selectedClient = client;
  }

  public async onSubmit(): Promise<void> {
    if (this.selectedClient) {
      const client: IClient = {
        added: {
          by: this.authService.userFB.uid,
          date: new Date(),
        },
        name: this.selectedClient.name,
        comments: [],
        contact: null,
        inactif: false,
        nbProjects: 0,
        reglementDate: 30,
        society: {
          adress: this.selectedClient.address || '',
          city: this.selectedClient.city || '',
          country: this.selectedClient.country || '',
          intracommunautaire: '',
          logo: '',
          siret: this.selectedClient.siret || '',
          specialMention: '',
          typeResidence: '',
          zipCode: this.selectedClient.zipCode || '',
        },
        inCharge: '',
        tvaRate: 20,
        key: '',
      };
      const addedClient: IClient = await this.clientService.addClients(client);
      this.dialogRef.close(addedClient);
    } else {
      console.error('Aucun client sélectionné.');
    }
  }
}
