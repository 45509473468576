import { NgModule } from '@angular/core';

import { GestServicesRoutingModule } from './gest-services-routing.module';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { ModifyServicesComponent } from './components/modify-services/modify-services.component';
import { GestServComponent } from './components/gest-serv/gest-serv.component';
import { AddServicesComponent } from './modals/add-services/add-services.component';
import { SharedModule } from '@shared/shared.module';
import { ArticleEditComponent } from './components/article-edit/article-edit.component';
import { AddArticlesComponent } from './modals/add-articles/add-articles.component';
import { EditServicesProjectComponent } from './components/edit-services-project/edit-services-project.component';
import { ServicesProjectComponent } from './components/services-project/services-project.component';
import { ArticleComponent } from './components/article/article.component';
import { ShopServicesComponent } from './components/shop-services/shop-services.component';
import { ModifyUnityComponent } from './modals/modify-unity/modify-unity.component';


@NgModule({
  declarations: [ServicesListComponent,ServicesProjectComponent, ModifyServicesComponent, GestServComponent, AddServicesComponent, ArticleEditComponent, AddArticlesComponent, EditServicesProjectComponent, ArticleComponent, ShopServicesComponent, ModifyUnityComponent],
  imports: [
    SharedModule,
    GestServicesRoutingModule
  ],
  exports : [
    ServicesProjectComponent,
    ModifyServicesComponent,
    ArticleComponent,
    ShopServicesComponent
  ]
})
export class GestServicesModule { }
