import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ISociete } from '@core/models/client.models';
import { SocietyService } from '@core/services/society.service';
import { Subscription, firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-reseach-society',
  templateUrl: './reseach-society.component.html',
  styleUrls: ['./reseach-society.component.scss']
})
export class ReseachSocietyComponent implements OnInit, OnDestroy {

  public societies: ISociete[] = [];
  public _societies: Subscription;

  public researchDone: boolean = false;

  public selectedSociety: ISociete;

  public displayedColumns: string[] = ['name','address','city','zipCode','type'];

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public dataSource: MatTableDataSource<ISociete>;

  public _createSociety: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<ReseachSocietyComponent>,
    private societyService: SocietyService) { }

  public ngOnInit(): void {
    this._societies = this.societyService.research$.subscribe(societies => {
      this.societies = societies
      this.dataSource = new MatTableDataSource<ISociete>(societies);
      this.dataSource.paginator = this.paginator;
    });
    this.selectedSociety = null;
  }

  public ngOnDestroy(): void {
    this._societies && this._societies.unsubscribe();
    this._createSociety && this._createSociety.unsubscribe();
  }

  public research(): void {
    this.societyService.updateResearchList(this.data);    
    this.researchDone = true;
  }

  public selectSociety(society: ISociete): void {
    this.selectedSociety = society;
  }

  public onSubmit(): Promise<void> {
    return firstValueFrom(this.societyService.addSociety(this.selectedSociety).pipe(
      map((society: ISociete) => this.dialogRef.close(society))
    ));
  }

}
