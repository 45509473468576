import { AbstractControl, ValidatorFn } from '@angular/forms';

// const VAT_NUMBER_MAX_SIZE: number = 13;
const VAT_NUMBER_REGEXP: RegExp = /^[A-Z]{2}\d*$/;

export function vatNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { vatNumber: string } | null => {
    if (!control.value) return null;
    const vatNumber = control.value;
    // const isSizeValid = vatNumber.length === VAT_NUMBER_MAX_SIZE;
    const isFormatValid = VAT_NUMBER_REGEXP.test(vatNumber);
    if (!isFormatValid) return { vatNumber: 'Format invalide. (FR0000000000)' };
    return null;
    // return isSizeValid ? null : { vatNumber: `La taille doit être de ${VAT_NUMBER_MAX_SIZE}. Taille actuelle : ${vatNumber.length}.` };
  }
}