<h1 mat-dialog-title>Modifier un contact</h1>
<form mat-dialog-content [formGroup]="contactForm" (ngSubmit)="contactForm.valid && onSubmit()">
  <div class="column">
    <div class="row">
      <mat-form-field>
        <mat-label>Nom</mat-label>
        <input matInput formControlName="lastName" autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Prénom</mat-label>
        <input matInput formControlName="firstName" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Type de contact</mat-label>
        <mat-select matInput formControlName="type" autocomplete="off">
          <mat-option *ngFor="let type of this.types" [value]="type">{{type}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Téléphone</mat-label>
        <input matInput formControlName="phone" autocomplete="off">
        <mat-error *ngIf="phoneControl.invalid" [matTooltip]="getPhoneErrorMessage()">{{ getPhoneErrorMessage() }}</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="email-field">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email" autocomplete="off">
      <mat-error *ngIf="mailControl.invalid" [matTooltip]="getMailErrorMessage()">{{ getMailErrorMessage() }}</mat-error>
    </mat-form-field>
    <div class="row">
      <button mat-button (click)="onCancel()" type="button">Annuler</button>
      <a mat-button href="mailto:{{this.data.email}}">Envoyer un mail</a>
      <button mat-button (click)="onDelete()" color="warn" type="button">Supprimer</button>
      <button mat-button color="primary" type="submit">Modifier</button>
    </div>
  </div>
</form>
