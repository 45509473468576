import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { AccountingAccount } from '@core/models/accounting-account.model';
import { IBankAccount } from '@core/models/bank.model';
import { IClient } from '@core/models/client.models';
import { IEntity } from '@core/models/entity.model';
import { Invoice, InvoiceType, InvoiceTypeLabel } from '@core/models/invoice.model';
import { IProjet } from '@core/models/projet.models';
import { AccountingAccountService } from '@core/services/accounting-account.service';
import { BankAccountService } from '@core/services/bank-account.service';
import { EntityService } from '@core/services/entity.service';
import { InvoiceService } from '@core/services/invoice.service';
import { isInvoiceNumberValid } from '@core/validators/invoiceNumber.validator';
import { Subscription, combineLatest } from 'rxjs';

export const DueValueLabel: readonly {value: string, label: string}[] = [
  {value: '0', label: 'Comptant'},
  {value: '30', label: '30 jours'},
  {value: '45', label: '45 jours'},
  {value: '60', label: '60 jours'},
];

export type DueValue = typeof DueValueLabel[number]['value'];

export const PaymentMeansLabel: readonly {value: string, label: string}[] = [
  { value: 'ZZZ', label: 'Moyen défini préalablement par les parties' },
  { value: '10', label: 'Espèces' },
  { value: '20', label: 'Chèque' },
  { value: '30', label: 'Virement' },
  { value: '42', label: 'Paiement sur compte bancaire' },
  { value: '48', label: 'Paiement par carte bancaire' },
  { value: '49', label: 'Paiement par prélèvement' },
  { value: '57', label: 'Paiement déjà défini par les parties' },
  { value: '58', label: 'Virement SEPA' },
  { value: '59', label: 'Prélèvement SEPA' },
  { value: '97', label: 'Report' },
];
export type PaymentMeans = typeof PaymentMeansLabel[number]['value'];


export const TvaValueLabel: readonly {value: number, label: string}[] = [
  {value: 0, label: 'Exonéré'},
  {value: 6, label: '6%'},
  {value: 12, label: '12%'},
  {value: 21, label: '21%'},
];

export type TvaValue = typeof TvaValueLabel[number]['value'];

@Component({
  selector: 'app-invoice-header',
  templateUrl: './invoice-header.component.html',
  styleUrls: ['./invoice-header.component.scss']
})
export class InvoiceHeaderComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public invoice: Invoice;
  @Input() public client: IClient;
  @Input() public project: IProjet;

  @Output() public invoiceChange: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output() public selectedEntity: EventEmitter<IEntity> = new EventEmitter<IEntity>();
  @Output() public selectedBankAccount: EventEmitter<string> = new EventEmitter<string>();

  public entities: IEntity[] = [];
  public bankAccounts: IBankAccount[] = [];
  public accountingAccounts: AccountingAccount[] = [];

  public DUE_VALUES: ReadonlyArray<DueValue> = DueValueLabel.map((due) => due.value);
  public TYPES_VALUES: ReadonlyArray<InvoiceType> = InvoiceTypeLabel.map((type) => type.value);
  public MEANS_VALUES: ReadonlyArray<PaymentMeans> = PaymentMeansLabel.map((mean) => mean.value);
  public TVA_VALUES: ReadonlyArray<TvaValue> = TvaValueLabel.map((tva) => tva.value);

  private _data: Subscription;
  private _bankAccount: Subscription;

  public isEntitySelected = false;

  public invoiceNumberControl: FormControl = new FormControl('',{
      asyncValidators: [isInvoiceNumberValid(this.invoiceService)]
  });

  public clientName: string = '';

  public hasDataBeenLoaded = false;

  constructor(
    private readonly entityService: EntityService,
    private readonly bankAccountService: BankAccountService,
    private readonly invoiceService: InvoiceService,
    private readonly accountingAccountService: AccountingAccountService
  ) { }

  public ngOnInit(): void {
    this.clientName = this.client?.name || `${this.client?.lastName} ${this.client?.firstName}`;
    this._data = combineLatest([
      this.entityService.getEntities(),
      this.accountingAccountService.accountingAccounts
    ]).subscribe(([entities, accountingAccount]) => {
      if (this.hasDataBeenLoaded) {
        return;
      }
      this.hasDataBeenLoaded = true;
      this.entities = entities;
      this.accountingAccounts = accountingAccount;
      if(this.invoice?.entity) {
        this.isEntitySelected = true;
        this.bankAccountService.updateBankAccountOwner(this.invoice.entity);
        const selectedEntity: IEntity = this.entities.find(e => e.key === this.invoice.entity);
        this.selectedEntity.emit(selectedEntity);
      } else if(this.entities.length === 1) {
        this.invoice.entity = this.entities[0].key;
        this.isEntitySelected = true;
        this.bankAccountService.updateBankAccountOwner(this.invoice.entity);
        this.selectedEntity.emit(this.entities[0]);
      }
    });
    this._bankAccount = this.bankAccountService.bankAccountsByOwner$.subscribe((accounts: IBankAccount[]) => {
      this.bankAccounts = accounts;
      if(this.bankAccounts.length === 1) {
        this.invoice.bankAccount = this.bankAccounts[0].id;
        this.selectedBankAccount.emit(this.bankAccounts[0].id);
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes['invoice'].currentValue !== changes['invoice'].previousValue) {
      if(this.invoice?.entity) {
        this.isEntitySelected = true;
        this.bankAccountService.updateBankAccountOwner(this.invoice.entity);
        this.selectedBankAccount.emit(this.invoice.bankAccount);
        const selectedEntity: IEntity = this.entities.find(e => e.key === this.invoice.entity);
        this.selectedEntity.emit(selectedEntity);
      }
    } else if (changes['client'].currentValue !== changes['client'].previousValue) {
      this.clientName = this.client?.name || `${this.client?.lastName} ${this.client?.firstName}`;
    }
  }


  public ngOnDestroy(): void {
    this._data?.unsubscribe();
    this._bankAccount?.unsubscribe();
  }

  public onInvoiceChange(): void {
    this.invoiceChange.emit(this.invoice);
  }

  public onEntityChange(entity: MatSelectChange): void {
    const selectedEntity: IEntity = this.entities.find(e => e.key === entity.value);
    this.isEntitySelected = true;
    this.bankAccountService.updateBankAccountOwner(entity.value);
    if (selectedEntity.defaultBankAccountId) {
      this.invoice.bankAccount = selectedEntity.defaultBankAccountId;
      this.selectedBankAccount.emit(selectedEntity.defaultBankAccountId);
    }
    this.selectedEntity.emit(selectedEntity);
    this.udpateInvoicePrefix();
  }

  public onBankAccountChange(account: MatSelectChange): void {
    this.selectedBankAccount.emit(account.value);
  }

  public udpateInvoicePrefix(): void {
    const prefix: string = InvoiceTypeLabel.find((type) => type.value === this.invoice.type)?.prefix || '';
    const month: number = new Date().getMonth()+1;
    const year: number = new Date().getFullYear();
    const entity: IEntity = this.entities.find((e) => e.key === this.invoice.entity);
    this.invoice.invoiceNumber = this.getEntityPrefix(prefix, entity) + year.toString().substring(2) + (month < 10 ? `0${month}` : month) + '-';
  }

  private getEntityPrefix(prefix: string, entity: IEntity): string {
    return prefix + entity.value.substring(2);
  }

  public onInvoiceNumberChange(): void {
    this.invoice.label = `${this.invoice.invoiceNumber}_${this.client.name}_${this.project.name}_${this.invoice.dueLabel}`;
  }

  public get deliveryNumber(): string {
    return this.invoice.deliveryNumber || this.project.numBonDeCommande || '';
  }

  public set deliveryNumber(value: string) {
    this.invoice.deliveryNumber = value;
  }

  public get contractNumber(): string {
    return this.invoice.contractNumber || this.project.numContract || '';
  }

  public set contractNumber(value: string) {
    this.invoice.contractNumber = value;
  }

}
