<div class="headerContent" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxFlex>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
      <div>
        <h5 (click)="goBack()" style="cursor: pointer;"> <i class="fas fa-undo"></i> Client : <span
            *ngIf="currentClient()">{{ currentClient().name }} </span></h5>
        <h5><i></i>Projet : <span *ngIf="this.project">{{ this.project.name }} </span></h5>
      </div>
      <div>
        <h5>Montant total :
          {{ (shoppedServices && shoppedServices.length > 0  ? getMontantTotalShoppedServices() : 0) }} € </h5>
        <h5>
          Charges total : {{ (shoppedServices && shoppedServices.length > 0  ? getChargeTotalShoppedServicesForUnityDay() : 0) }}
          J , {{ (shoppedServices && shoppedServices.length > 0  ? getChargeTotalShoppedServicesForUnityMonth() : 0) }} M </h5>
      </div>
      <div>
        <h5>Nombre de services : {{ (shoppedServices && shoppedServices.length > 0 ? shoppedServices.length : 0) }}
        </h5>
        <h5>Nombre d'articles : {{ (shoppedServices  && shoppedServices.length > 0 ? getNombresArticles() : 0) }}</h5>
      </div>
    </div>
  </div>
</div>
