import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  alternativeTheme = false;
  themeChanged: Subject<string>;
  isMobile: boolean;

  constructor(public overlayContainer: OverlayContainer) {
  }

  public changeTheme(theme) {
    localStorage.setItem('alternativeTheme', theme);
    this.themeChanged.next(theme);
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.overlayContainer.getContainerElement().classList.remove(
      theme === 'default-theme' ?
        'theme-alternate' :
        'default-theme');
    this.alternativeTheme = theme === 'theme-alternate';
  }

  public loadThemeInfo(isMobile?: boolean) {
    this.isMobile = isMobile ? true : false;
    this.themeChanged = new Subject();
    if (localStorage.getItem('alternativeTheme')) {
      this.changeTheme(localStorage.getItem('alternativeTheme'));
    } else {
      this.changeTheme('default-theme');
    }
  }

  getTheme(): string {
    return localStorage.getItem('alternativeTheme') + (this.isMobile ? '-mobile' : '');
  }

  subscribeThemeChange(): Observable<string> {
    return this.themeChanged.asObservable();
  }
}
