<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sideNav [mode]="'side'">
    <mat-nav-list>
      <mat-list-item [routerLink]="['/paragraph']">
        <i mat-list-icon class="fas fa-file-alt"></i>
        <h4 mat-line>Gestion Paragraphes</h4>
      </mat-list-item>
      <mat-list-item [routerLink]="['/services']">
        <i mat-list-icon class="fas fa-shapes"></i>
        <h4 mat-line>Gestion Services</h4>
      </mat-list-item>
      <mat-list-item [routerLink]="['/client/manage']">
        <i mat-list-icon class="fas fa-address-book"></i>
        <h4 mat-line>Gestion Client</h4>
      </mat-list-item>
      <mat-list-item [routerLink]="['/supplier']">
        <i mat-list-icon class="fas fa-address-book"></i>
        <h4 mat-line>Gestion Fournisseur</h4>
      </mat-list-item>
      <mat-list-item [routerLink]="['/devis']">
        <i mat-list-icon class="fas fa-file-signature"></i>
        <h4 mat-line>Devis</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
