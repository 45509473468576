<div fxLayout="row" fxLayoutAlign="center start" class="over">
  <mat-accordion [multi]="true" id="catalogueContent" class="content" cdkDropList
    [cdkDropListData]="servicesService.services" cdkDropListConnectedTo="shopContent"
    [cdkDropListEnterPredicate]="noReturnPredicate">
    <mat-expansion-panel *ngFor="let service of servicesService.services" [cdkDragData]="service" cdkDrag>
      <mat-expansion-panel-header cdkDragHandle *ngIf="!service.disabled">
        <mat-panel-title>
          <b>{{ service.name }}</b>
        </mat-panel-title>
        <mat-panel-description *ngIf="service.articles">
          <span class="panel-description"
            *ngIf="service && service.articles && service.articles.length > 0">{{ service.articles.length }}
            articles
            ({{ getMontantTotalByService(service) }} €,
            {{ getChargeTotalByService(service) }} {{service.unity}})</span>
          <span class="panel-description" *ngIf="!service || !service.articles || service.articles.length === 0">0
            articles (0 €, 0 J)</span>
        </mat-panel-description>
        <mat-panel-description *ngIf="!service.articles">
          <span class="panel-description">Aucun article</span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-article [service]="service" [articles]="service.articles"></app-article>
    </mat-expansion-panel>
  </mat-accordion>
</div>
