<mat-card *ngIf="invoice">
  <mat-card-content>
    <button mat-flat-button color="primary" (click)="addLine()">Ajouter une ligne à la facture</button>
    <div class="lines-container">
      <ng-container *ngIf="invoice.lines.length > 0; else noLines">
        <app-invoice-line *ngFor="let line of invoice.lines" [line]="line" [service]="service" (deleteLine)="deleteLine($event)" [options]="options"></app-invoice-line>
      </ng-container>
      <ng-template #noLines>
        <span class="no-lines">Aucune ligne de facture</span>
      </ng-template>
    </div>
    <div class="buttons">
      <button mat-flat-button color="primary" (click)="generate()">Générer la facture</button>
      <button mat-flat-button color="primary" (click)="saveInvoice()" [disabled]="hasInvoiceBeenValidated">Sauvegarder la facture</button>
      <button mat-flat-button color="primary" (click)="validateInvoice()" [disabled]="hasInvoiceBeenValidated || isValidating">Valider la facture</button>
    </div>
  </mat-card-content>
</mat-card>
