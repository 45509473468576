import { Pipe, PipeTransform } from '@angular/core';
import { ISociete } from '@core/models/client.models';
import { SocietyService } from '@core/services/society.service';

@Pipe({
  name: 'society'
})
export class SocietyPipe implements PipeTransform {

  public society: ISociete[] = [];

  constructor(private societyService: SocietyService) {
    this.societyService.society$.subscribe(society => this.society = society);
  }

  public transform(value: string): string {
    const society: ISociete = this.society ? this.society.find(society => society.id === value) : null;
    console.log(society,this.society);
    return society ? society.name : value;
  }

}
