<mat-card>
  <mat-card-content>
    <button mat-flat-button color="primary" (click)="addLine()">Ajouter une ligne au devis</button>
    <div class="lines-container">
      <ng-container *ngIf="invoice?.lines.length > 0; else noLines">
        <app-devis-invoice-line *ngFor="let line of invoice.lines" [line]="line" [service]="service" (deleteLine)="deleteLine($event)"></app-devis-invoice-line>
      </ng-container>
      <ng-template #noLines>
        <span class="no-lines">Aucune ligne de devis</span>
      </ng-template>
    </div>
    <div class="buttons">
      <button mat-flat-button color="primary" (click)="generate()" [disabled]="isAmountEqualToZero">Générer le devis</button>
      <button mat-flat-button color="primary" (click)="saveInvoice()">Sauvegarder le devis</button>
      <!-- <button mat-flat-button color="primary" (click)=">Valider le devis</button> -->
    </div>
  </mat-card-content>
</mat-card>
