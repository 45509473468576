<form [formGroup]="searchClient" class="search-input">
  <mat-form-field appearance="outline">
    <mat-label>Nom</mat-label>
    <input matInput formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Numéro de Siret</mat-label>
    <input matInput formControlName="siret" />
  </mat-form-field>
  <button mat-fab color="primary" [disabled]="!isSearchValid" (click)="searchClientFn()" matTooltip="Rechercher">
    <mat-icon>search</mat-icon>
  </button>
</form>
<span *ngIf="!isSearchValid" class="search-info">Merci de remplir un seul des deux champs pour effectuer une recherche!</span>
<table mat-table [dataSource]="searchDataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Nom </th>
    <td mat-cell *matCellDef="let element"> {{ element.client?.name | tableNullValue }} </td>
  </ng-container>
  <ng-container matColumnDef="siret">
    <th mat-header-cell *matHeaderCellDef> Siret </th>
    <td mat-cell *matCellDef="let element"> {{ element.client?.society?.siret | tableNullValue }} </td>
  </ng-container>
  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef> Ville </th>
    <td mat-cell *matCellDef="let element"> {{ element.client?.society?.city | tableNullValue }} </td>
  </ng-container>
  <ng-container matColumnDef="zipCode">
    <th mat-header-cell *matHeaderCellDef> Code postal </th>
    <td mat-cell *matCellDef="let element"> {{ element.client?.society?.zipCode | tableNullValue }} </td>
  </ng-container>
  <ng-container matColumnDef="inDatabase">
    <th mat-header-cell *matHeaderCellDef> Existant </th>
    <td mat-cell *matCellDef="let element">
      <div class="in-database-cell">
        <mat-checkbox [checked]="element.isInDatabase" disabled color="primary"></mat-checkbox>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="resemblence">
    <th mat-header-cell *matHeaderCellDef> Taux rapprochement </th>
    <td mat-cell *matCellDef="let element">
      <div class="resemblence-cell">
        {{ element.resemblence | percent: '1.0-2' | tableNullValue }}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.isInDatabase; else notInDatabase">
        <button mat-icon-button matTooltip="Accèder à la fiche client" matTooltipPosition="after" (click)="goToClient(element)">
          <mat-icon>visibility</mat-icon>
        </button>
      </ng-container>
      <ng-template #notInDatabase>
        <mat-checkbox color="primary" [checked]="isSelected(element)" (change)="selectClient(element)"></mat-checkbox>
      </ng-template>
    </td>
  </ng-container>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="5">
      {{ hasSearch ? 'Aucun résultat trouvé' : 'Aucune recherche effectuée' }}
    </td>
  </tr>
  <tr mat-header-row *matHeaderRowDef="searchDisplayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: searchDisplayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>