import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-devis-invoice-preview',
  templateUrl: './devis-invoice-preview.component.html',
  styleUrls: ['./devis-invoice-preview.component.scss']
})
export class DevisInvoicePreviewComponent implements OnChanges {

  @Input() public dataUrl: string;
  @Output() public generateInvoice: EventEmitter<void> = new EventEmitter<void>();

  constructor(
  ) { }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if(changes['dataUrl'].currentValue) {
      const element: HTMLElement = document.getElementById('pdfPreview');
      element?.setAttribute('src', `${this.dataUrl}#toolbar=0`);
    }
  }

  public onGenerateInvoice(): void {
    this.generateInvoice.emit();
  }
}
