import { AbstractControl, ValidatorFn } from '@angular/forms';

const SIRET_MAX_SIZE: number = 14;
const SIRET_REGEXP: RegExp = /^\d*$/;

export function siretValidator(): ValidatorFn {
  return (control: AbstractControl): { siret: string } | null => {
    if (!control.value) return null;
    const siret = control.value;
    const isFormatValid = SIRET_REGEXP.test(siret);
    if (!isFormatValid) return { siret: 'Format invalide. Seuls des chiffres sont autorisés.' };
    return siret.length === SIRET_MAX_SIZE ?  null :  { siret: `Taille max: ${SIRET_MAX_SIZE} (actuelle : ${siret.length})` };
  }
}