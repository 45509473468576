import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IBankAccount } from '@core/models/bank.model';
import { BankAccountService } from '@core/services/bank-account.service';
import { AddBankComponent } from '@shared/banks/modals/add-bank/add-bank.component';
import { EditBankComponent } from '@shared/banks/modals/edit-bank/edit-bank.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-bank',
  templateUrl: './list-bank.component.html',
  styleUrls: ['./list-bank.component.scss']
})
export class ListBankComponent implements OnInit, OnDestroy {

  @Input() public ownerId: string;
  @Input() public ownerType: string;
  @Input() public isSupplier: boolean = false;
  @Output() public bankChange: EventEmitter<IBankAccount[]> =  new EventEmitter<IBankAccount[]>();

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  public dataSource: MatTableDataSource<IBankAccount>;

  public displayedColumns: string[] = ['name', 'iban'];

  public bankAccounts: IBankAccount[] = [];
  public _bankAccounts: Subscription;

  constructor(private bankService: BankAccountService,
    private dialog: MatDialog) { }

  public ngOnInit(): void {
    this._bankAccounts = this.bankService.bankAccountsByOwner$.subscribe(bankAccounts => {
      this.bankAccounts = bankAccounts
      this.dataSource = new MatTableDataSource<IBankAccount>(bankAccounts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public ngOnDestroy(): void {
    this._bankAccounts && this._bankAccounts.unsubscribe();
  }


  public addBankAccount(): void {
    const dialogRef: MatDialogRef<AddBankComponent> = this.dialog.open(AddBankComponent, {
      width: '500px',
      data: {
        ownerId: this.ownerId,
        ownerType: this.ownerType,
        isSupplier: this.isSupplier
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bankAccounts.push(result);
        this.bankChange.emit(this.bankAccounts);
        this.bankService.updateBankAccountOwner(this.ownerId);
      }
    });
  }

  public selectAccount(bankAccount: IBankAccount): void {
    const dialogRef: MatDialogRef<EditBankComponent> = this.dialog.open(EditBankComponent, {
      width: '500px',
      data: {
        bank: bankAccount,
        isSupplier: this.isSupplier
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.bankAccounts.splice(this.bankAccounts.indexOf(bankAccount), 1);
        this.bankChange.emit(this.bankAccounts);
      }
      this.bankService.updateBankAccountOwner(this.ownerId);
    });
  }

  public sortData(sort: MatSort): void {
    const data = this.dataSource.data.slice();
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name.toLowerCase(), b.name.toLowerCase(), isAsc);
        default: return 0;
      }
    });
  }

  private compare(a: string, b:string, isAsc: boolean): number {
    return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
  }
}
