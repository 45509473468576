import { Component, OnInit } from '@angular/core';
import { IChapter } from '@core/models/chapters.models';
import { ChaptersService } from '@core/services/chapters.service';
import { ProjetsService } from '@core/services/projets.service';
import { ServicesService } from '@core/services/services.service';

@Component({
  selector: 'app-edit-pdf',
  templateUrl: './edit-pdf.component.html',
  styleUrls: ['./edit-pdf.component.scss']
})
export class EditPdfComponent implements OnInit {

  public currentChapter : IChapter

  constructor(public chaptersService: ChaptersService, public projectService : ProjetsService, public servicesService : ServicesService) { }

  ngOnInit(): void {
  }

  setCurrentChapter(chapter : IChapter) {
    this.currentChapter = chapter;
  }

}
