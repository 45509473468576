import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IClient } from '@core/models/client.models';
import { ClientsService } from '@core/services/clients.service';

@Component({
  selector: 'app-copy-projects',
  templateUrl: './copy-projects.component.html',
  styleUrls: ['./copy-projects.component.scss']
})
export class CopyProjectsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public displayedColumns: string[] = ['name'];
  public dataSource: MatTableDataSource<IClient>;
  private subscribeClientsChange;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CopyProjectsComponent>,private clientsService: ClientsService) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.paginator._intl.itemsPerPageLabel = 'Clients par page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 sur ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
    this.dataSource.paginator = this.paginator;

  }


  ngOnInit() {
    this.subscribeClientsChange = this.clientsService.clientsChange.subscribe(() => {
      this.setDatasource();
    });
    this.setDatasource();
  }

  ngOnDestroy() {
    this.subscribeClientsChange.unsubscribe();
  }
  private setDatasource() {
    this.dataSource = new MatTableDataSource(this.clientsService.clients);
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = (text: { name: string }, filterValue: string) =>
      text.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  }
  onSubmit() {
    this.dialogRef.close(this.data);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  selectClient(clientName,clientID){
    const clientData = {
      nameClient : clientName,
      idClient : clientID,
    }
     this.dialogRef.close(clientData);
  }
}
