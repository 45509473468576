import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public constructor(
    private readonly db: AngularFirestore
  ) {}

  public async compareStrings<T>(values: T[], valueKey: keyof T, target: string): Promise<{
    value: T;
    distance: number;
  }[]> {
    const threshold: number = await this.getCompareThreshold();
    return values
      .map((value: T) => ({ value, distance: this.levenshteinDistance(value[valueKey].toString(), target) }))
      .filter((value) => value.distance >= threshold);
  }

  public async compareObjectString<T, K extends keyof T>(values: T[], objectKey: K, valueKey: keyof T[K], target: string): Promise<{
    value: T,
    distance: number;
  }[]> {
    const threshold: number = await this.getCompareThreshold();
    return values
      .map((value: T) => ({ value, distance: this.levenshteinDistance(value[objectKey][valueKey].toString(), target) }))
      .filter((value) => value.distance >= threshold);
  }

  public levenshteinDistance(string1: string, string2: string): number {
    let longestString: string = string1;
    let shortestString: string = string2;
    if (string1.length < string2.length) {
      longestString = string2;
      shortestString = string1;
    }
    const longestLength: number = longestString.length;
    if (longestLength === 0) {
      return 1.0;
    }
  
    return (longestLength - this.calculateDistance(longestString, shortestString)) / longestLength;
  }
  
  private calculateDistance(string1: string, string2: string): number {
    string1 = string1.toLowerCase();
    string2 = string2.toLowerCase();
  
    const costs: number[] = [];
  
    for (let i = 0, len = string1.length; i <= len; i++) {
      let lastValue: number = i;
  
      for (let j = 0, jLen = string2.length; j <= jLen; j++) {
        if (i === 0) costs[j] = j;
        else if (j > 0) {
          let newValue: number = costs[j - 1];
          if (string1.charAt(i - 1) !== string2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
  
      if (i > 0) costs[string2.length] = lastValue;
    }
  
    return costs[string2.length];
  }

  private async getCompareThreshold(): Promise<number> {
    const query = await firstValueFrom(this.db.collectionGroup('ocr', ref => ref.where('key', '==', 'threshold')).get());
    return +(query.docs[0].data().value);

  }
}