import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-services-project',
  templateUrl: './edit-services-project.component.html',
  styleUrls: ['./edit-services-project.component.scss']
})
export class EditServicesProjectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
