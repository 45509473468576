import { DueValue } from "@pages/projects/components/invoice/invoice-header/invoice-header.component";

export type Invoice = {
  id: string;
  entity: string;
  client: string;
  bankAccount: string;
  due: DueValue;
  type: InvoiceType;
  invoiceNumber: string;
  label: string;
  deliveryNumber: string;
  contractNumber: string;
  supplierNumber: string;
  tvaRate: number;
  lines: InvoiceLine[];
  hasBeenSent: boolean;
  plannedDate: Date;
  dueLabel: string;
  amount: number;
  paymentMean: string;
  accountingAccount: string;
  comment?: string;
};

export type InvoiceLine = {
  id: string;
  name: string;
  quantity: number;
  unitPrice: number;
  unit: string;
  tvaRate: string;
};

export type TresoInvoice = {
  bankAccountId: string;
  clientId: string;
  codeEcheance: string;
  commentaire?: string;
  dateEcheance: Date;
  dateFacturation: Date;
  datePaiement: Date;
  devise: string;
  entityId: string;
  etat: string;
  expectedPercent: number;
  libelle: string;
  numFacture: string;
  statusRelance: string;
  totalHT: number;
  totalTTC: number;
  totalTVA: number;
  tva: string;
  accountingAccountId: string;
};

export const InvoiceTypeLabel: readonly {value: number, label: string, prefix: string}[] = [
  {value: 380, label: 'FACTURE', prefix: 'FA'},
  {value: 381, label: 'AVOIR', prefix: 'AV'},
  {value: 382, label: 'DEVIS', prefix: 'DE'},
];

export type InvoiceType = typeof InvoiceTypeLabel[number]['value'];


export type InvoiceDevisExpress = {
  id: string;
  entity: string;
  client: string;
  bankAccount: string;
  due?: DueValue;
  type?: InvoiceType;
  invoiceNumber?: string;
  label: string;
  deliveryNumber?: string;
  contractNumber?: string;
  clientNumber?: string;
  lines: InvoiceDevisExpressLine[];
  hasBeenSent: boolean;
  plannedDate: Date;
  dueLabel: string;
  amount: number;
  paymentMean?: string;
  comment?: string;
  accountingAccount?: string;
};

export type InvoiceDevisExpressLine = {
  id: string;
  name: string;
  quantity: number;
  unitPrice: number;
  unit: string;
  tvaRate: string;
};
