import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountingAccount } from '@core/models/accounting-account.model';
import { IClient } from '@core/models/client.models';
import { Country } from '@core/models/country.model';
import { IFilter, IUser } from '@core/models/users.models';
import { AccountingAccountService } from '@core/services/accounting-account.service';
import { CountryService } from '@core/services/country.service';
import { UserService } from '@core/services/user.service';
import { Observable, Subscription, map, startWith } from 'rxjs';

@Component({
  selector: 'app-client-creation-form',
  templateUrl: './client-creation-form.component.html',
  styleUrls: ['./client-creation-form.component.scss']
})
export class ClientCreationFormComponent implements OnInit, OnDestroy {

  @Input() public clientForm: FormGroup;

  public quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { background: [] }],
      ['clean'],
      ['direction', { align: [] }],
      ['link', 'image'],
    ],
    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/,
        },
      },
    },
  };

  public tvaRates: { value: number; label: string }[] = [
    {
      value: 0,
      label: 'Exonéré',
    },
    {
      value: 5.5,
      label: '5.5%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 20,
      label: '20%',
    },
  ];

  public echeances: { value: number; label: string }[] = [
    {
      value: 0,
      label: 'Comptant',
    },
    {
      value: 30,
      label: '30 jours',
    },
    {
      value: 45,
      label: '45 jours',
    },
    {
      value: 60,
      label: '60 jours',
    },
  ];

  public countries: Country[] = [];
  private _countries: Subscription;
  public filteredCountries: Observable<Country[]>;

  public accountingAccounts: AccountingAccount[] = [];
  private _accountingAccounts: Subscription;
  public filteredAccountingAccounts: Observable<AccountingAccount[]>;

  public users: IUser[] = [];
  private _users: Subscription;
  public usersOptions: IFilter[] = [];

  public userConfig = {
    displayKey: 'value',
    searchOnKey: 'value',
    search: true,
    moreText: ' Autres utilisateurs',
    noResultsFound: 'Aucun utilisateur trouvé',
    searchPlaceholder: 'Recherche',
    placeholder: 'Recherche',
    height: '300px',
  };


  public constructor(
    private readonly countryService: CountryService,
    private readonly userService: UserService,
    private readonly snackBar: MatSnackBar,
    private readonly accountingAccountService: AccountingAccountService,
  ) {}

  public ngOnInit(): void {
    this._countries = this.countryService.getCountries().subscribe(countries => {
      this.countries = countries;
      this.filteredCountries = this.clientForm.get('country').valueChanges.pipe(
        startWith(''),
        map((value: string) => this.countries.filter((c) => c.label.toLowerCase().includes(value.toLowerCase())))
      );
    });
    this._accountingAccounts = this.accountingAccountService.accountingAccounts.subscribe((accountingAccounts) => {
      this.accountingAccounts = accountingAccounts;
      this.filteredAccountingAccounts = this.clientForm.get('defaultAccountingAccount').valueChanges.pipe(
        startWith(''),
        map((value: string) => this.accountingAccounts.filter((option: AccountingAccount) => {
          const fullLabel: string = this.getAccountingAccountFn(option);
          const compareTo: string = typeof value === 'string' ? value : this.getAccountingAccountFn(value);
          return fullLabel.toLowerCase().includes(compareTo.toLowerCase());
        }))
      );
    });
    this._users = this.userService.users$.subscribe(users => {
      this.users = users;
      this.usersOptions = users.map(user => ({
        type: 'collaborateur',
        value: user.firstName + ' ' + user.lastName,
        key: user.key,
      }));
    });
  }

  public ngOnDestroy(): void {
    this._countries?.unsubscribe();
    this._users?.unsubscribe();
  }

  public modifyLogo(event): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const file: File = event.target.files[0];
      if (file.size > 1000000) {
        this.snackBar.open('Le fichier est trop lourd, veuillez choisir un fichier de moins de 1Mo', 'x', {
          duration: 4000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['red-snackbar']
        });
        return;
      }
      reader.readAsDataURL(event.target.files[0]);

      reader.onloadend = (event) => {
        this.logoControl.patchValue(reader.result.toString());
      };
    }
  }

  public deleteLogo(): void {
    this.logoControl.reset();
  }

  public get logoControl(): AbstractControl {
    return this.clientForm.get('logo');
  }

  public get siretControl(): AbstractControl {
    return this.clientForm.get('siret');
  }

  public getSiretErrorMessage(): string {
    if (this.siretControl.hasError('required')) {
      return 'Le SIRET est obligatoire';
    } else if (this.siretControl.hasError('siret')) {
      return this.siretControl.errors.siret;
    }
  }

  public get vatNumberControl(): AbstractControl {
    return this.clientForm.get('vatNumber');
  }

  public getVatNumberErrorMessage(): string {
    if (this.vatNumberControl.hasError('vatNumber')) {
      return this.vatNumberControl.errors.vatNumber;
    }
  }

  public get phoneControl(): AbstractControl {
    return this.clientForm.get('phone');
  }

  public getPhoneErrorMessage(): string {
    if (this.phoneControl.hasError('phone')) {
      return this.phoneControl.errors.phone;
    }
  }

  public get phoneFixControl(): AbstractControl {
    return this.clientForm.get('phoneFix');
  }

  public getPhoneFixErrorMessage(): string {
    if (this.phoneFixControl.hasError('phone')) {
      return this.phoneFixControl.errors.phone;
    }
  }

  public get mailControl(): AbstractControl {
    return this.clientForm.get('mail');
  }

  public getMailErrorMessage(): string {
    if (this.mailControl.hasError('email')) {
      return '- Format de mail invalide (exemple@gmail.com)';
    }
  }

  public getAccountingAccountFn(accountingAccount: AccountingAccount): string {
    return accountingAccount ? `${accountingAccount.code} - ${accountingAccount.name}` : '';
  }
}
