import { AbstractControl, ValidatorFn } from '@angular/forms';

const IBAN_MAX_SIZE: number = 34;
const IBAN_FORMAT_REGEXP: RegExp = /^\w*$/;

export function ibanValidator(): ValidatorFn {
  return (control: AbstractControl): { iban: string } | null => {
    if (!control.value) return null;
    const iban: string = control.value;
    const isFormatValid = IBAN_FORMAT_REGEXP.test(iban);
    if (!isFormatValid) return { iban: 'Format invalide. Seuls des lettres majuscules et des chiffres sont autorisés.' };
    return  iban.length <= IBAN_MAX_SIZE ? null : { iban: `Taille max: ${IBAN_MAX_SIZE} (actuelle: ${iban.length})` };
  }
}