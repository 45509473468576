import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { AccountingAccount } from '@core/models/accounting-account.model';
import { IClient, ISociety } from '@core/models/client.models';
import { IContact } from '@core/models/contacts.model';
import { Country } from '@core/models/country.model';
import { IFilter, IUser } from '@core/models/users.models';
import { AccountingAccountService } from '@core/services/accounting-account.service';
import { AuthService } from '@core/services/auth.service';
import { ClientsService } from '@core/services/clients.service';
import { CountryService } from '@core/services/country.service';
import { UserService } from '@core/services/user.service';
import { countryValidator } from '@shared/validators/country.validator';
import { siretValidator } from '@shared/validators/siret.validator';
import { vatNumberValidator } from '@shared/validators/vat-number.validator';
import { Observable, Subscription, map, startWith } from 'rxjs';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
})
export class ClientDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() currentClient: IClient = null;
  @Input() users: IUser[] = [];

  @Output() deleteClient: EventEmitter<IClient> = new EventEmitter<IClient>();

  public clientForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    buldingInfo: new FormControl(''),
    address: new FormControl('', [Validators.required]),
    additionalAddress: new FormControl(''),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    country: new FormControl('', [Validators.required], [countryValidator(this.countryService)]),
    siret: new FormControl('', [siretValidator()]),
    vatNumber: new FormControl('', [vatNumberValidator()]),
    dueTerm: new FormControl(0, [Validators.required]),
    tvaRate: new FormControl(0),
    comments: new FormControl([]),
    inactif: new FormControl(false),
    incharge: new FormControl({}),
    defaultAccountingAccount: new FormControl(''),
  });


  public usersOptions: IFilter[] = [];
  private usersSub: Subscription;
  private subscribeClientsChange;
  public checkboxColor = 'warn';
  public countries: Country[] = [];
  private _countries: Subscription;
  public selectedCountry: string;
  public filteredCountries: Observable<Country[]>;
  public userSelected: IFilter;
  public selectedContact: string[] = ['serviceComptabilite']

  public accountingAccounts: AccountingAccount[] = [];
  public filteredAccountingAccounts: Observable<AccountingAccount[]>;
  private _accountingAccounts: Subscription;

  public tvaRates: { value: number; label: string }[] = [
    {
      value: 0,
      label: 'Exonéré',
    },
    {
      value: 6,
      label: '6%',
    },
    {
      value: 12,
      label: '12%',
    },
    {
      value: 21,
      label: '21%',
    }
  ];

  public echeances: { value: number; label: string }[] = [
    {
      value: 0,
      label: 'Comptant',
    },
    {
      value: 30,
      label: '30 jours',
    },
    {
      value: 45,
      label: '45 jours',
    },
    {
      value: 60,
      label: '60 jours',
    },
  ];

  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ color: [] }, { background: [] }],
      ['clean'],
      ['direction', { align: [] }],
      ['link', 'image'],
    ],
    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/,
        },
      },
    },
  };

  public userConfig = {
    displayKey: 'value',
    searchOnKey: 'value',
    search: true,
    moreText: ' Autres utilisateurs',
    noResultsFound: 'Aucun utilisateur trouvé',
    searchPlaceholder: 'Recherche',
    placeholder: 'Recherche',
    height: '300px',
  };

  public user: IUser;
  private _user: Subscription;


  constructor(
    private clientsService: ClientsService,
    public userService: UserService,
    private readonly countryService: CountryService,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly accountingAccountService: AccountingAccountService
  ) {}

  public ngOnInit(): void {
    this.loadFilterUsers();
    if (!!this.currentClient) {
      if (!this.currentClient?.society) {
        this.currentClient.society = {} as ISociety;
      }
      if (this.currentClient.inactif === null) {
        this.currentClient.inactif = false;
      }
      this.fillForm();
    }
    this._countries = this.countryService.getCountries().subscribe((countries) => {
      this.countries = countries;
      this.filteredCountries = this.clientForm.get('country').valueChanges.pipe(
        startWith(''),
        map((value: string) => this.countries.filter((option: Country) => option.label.toLowerCase().includes(value?.toLowerCase())))
      );
    });
    this._accountingAccounts = this.accountingAccountService.accountingAccounts.subscribe((accountingAccounts) => {
      this.accountingAccounts = accountingAccounts.filter((account) => account.code.toString().startsWith('7')).sort((a, b) => a.code - b.code);

      this.fillForm();
      this.filteredAccountingAccounts = this.clientForm.get('defaultAccountingAccount').valueChanges.pipe(
        startWith(''),
        map((value: string) => this.accountingAccounts.filter((option: AccountingAccount) => {
          const fullLabel: string = this.getAccountingAccountFn(option);
          const compareTo: string = typeof value === 'string' ? value : this.getAccountingAccountFn(value);
          return fullLabel.toLowerCase().includes(compareTo.toLowerCase());
        }))
      );
    });
    this._user = this.authService.user$.subscribe(user => this.user = user);
  }

  public ngOnDestroy(): void {
    this._user?.unsubscribe();
    this.usersSub?.unsubscribe();
    this.subscribeClientsChange?.unsubscribe();
    this._countries?.unsubscribe();
  }

  public ngOnChanges(simpleChange: SimpleChanges) {
    if (simpleChange.users) {
      this.loadFilterUsers();
    }
    if (simpleChange.currentClient) {
      if (!!this.currentClient && !this.currentClient?.society) {
        this.currentClient.society = {} as ISociety;
      }
      this.fillForm();
    }
  }

  private fillForm(): void {
    const user: IUser = this.currentClient?.inCharge ? this.userService.getUsersWithKey(this.currentClient.inCharge) : undefined;
    this.clientForm.patchValue({
      name: this.currentClient?.name,
      firstName: this.currentClient?.firstName,
      lastName: this.currentClient?.lastName,
      buldingInfo: this.currentClient?.society?.typeResidence,
      address: this.currentClient?.society?.adress,
      additionalAddress: this.currentClient?.society?.specialMention,
      zipCode: this.currentClient?.society?.zipCode,
      city: this.currentClient?.society?.city,
      country: this.currentClient?.society?.country,
      siret: this.currentClient?.society?.siret,
      vatNumber: this.currentClient?.society?.intracommunautaire,
      dueTerm: this.currentClient?.reglementDate,
      tvaRate: this.currentClient?.tvaRate,
      comments: this.currentClient?.comments,
      inactif: this.currentClient?.inactif,
      incharge: user ? {
        type: 'collaborateur',
        value: user.firstName + " " + user.lastName,
        key: user.key,
      } : null,
      defaultAccountingAccount: this.accountingAccounts.find((a) => a.id === this.currentClient?.defaultAccountingAccountId) ?? '',
    });
  }


  public deleteLogo(): void {
    this.currentClient.society.logo = null;
  }

  public modifyLogo(event): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const file: File = event.target.files[0];
      if (file.size > 1000000) {
        this.snackBar.open('Le fichier est trop lourd, veuillez choisir un fichier de moins de 1Mo', 'x', {
          duration: 4000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: ['red-snackbar']
        });
        return;
      }

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event) => {
        this.currentClient.society.logo = reader.result.toString();
      };
    }
  }

  public selectionChanged(): void {
    this.currentClient.inCharge = this.userSelected?.key;

  }

  public saveClientData(): Promise<void> {
    const client: IClient = this.clientFromForm;
    const snackbarConfig: MatSnackBarConfig = {
      panelClass: ['green-snackbar'],
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3000
    }
    return this.clientsService.updateClient(client)
      .then(() => {this.snackBar.open('Client mis à jour', 'X', snackbarConfig)})
      .catch(() => {this.snackBar.open('Erreur lors de la mise à jour du client', 'X', {...snackbarConfig, panelClass: ['red-snackbar']})});
  }


  private get clientFromForm(): IClient {
    return {
      key: this.currentClient.key,
      added: this.currentClient.added ?? null,
      comments: this.clientForm.get('comments').value ?? null,
      contact: {
        firstName: this.clientForm.get('firstName')?.value ?? null,
        job: this.clientForm.get('job')?.value ?? null,
        lastName: this.clientForm.get('lastName')?.value ?? null,
        email: this.clientForm.get('mail')?.value ?? null,
        phone: this.clientForm.get('phoneFix')?.value ?? null,
        type: this.clientForm.get('type')?.value ?? null,
        actif: this.clientForm.get('inactif')?.value ?? null
      },
      inCharge: this.clientForm.get('incharge').value?.key ?? null,
      inactif: this.clientForm.get('inactif').value ?? this.currentClient.inactif ?? false,
      name: this.clientForm.get('name').value ?? null,
      reglementDate: this.clientForm.get('dueTerm').value ?? null,
      society: {
        adress: this.clientForm.get('address').value ?? null,
        city: this.clientForm.get('city').value ?? null,
        country: this.clientForm.get('country').value ?? null,
        intracommunautaire: this.clientForm.get('vatNumber').value ?? null,
        siret: this.clientForm.get('siret').value ?? null,
        specialMention: this.clientForm.get('additionalAddress').value ?? null,
        typeResidence: this.clientForm.get('buldingInfo').value ?? null,
        zipCode: this.clientForm.get('zipCode').value ?? null,
        logo: this.currentClient.society.logo ?? null,
      },
      nbProjects: this.currentClient.nbProjects ?? null,
      tvaRate: this.clientForm.get('tvaRate').value ?? null,
      defaultAccountingAccountId: this.clientForm.get('defaultAccountingAccount').value?.id ?? null,
    }
  }

  public loadFilterUsers(): void {
    this.usersOptions = this.userService.users?.filter((e) => !e.disabled)
      .map((e) => {
        return {
          type: 'collaborateur',
          value: e.firstName + ' ' + e.lastName,
          key: e.key,
        };
      }) ?? [];
  }


  public onCountrySelected(event: any): void {
    this.currentClient.society.country = event.option.value;
  }

  public get canDeleteClient(): boolean {
    return this.user?.role === 'headof' || this.user?.role === 'supportAdmin';
  }

  public deleteCurrentClient(): void {
    if (confirm('Voulez-vous vraiment supprimer ce client ?')) {
      this.deleteClient.emit(this.currentClient);
    }
  }

  public get siretControl(): AbstractControl {
    return this.clientForm.get('siret');
  }

  public getSiretErrorMessage(): string {
    if (this.siretControl.hasError('required')) {
      return 'Le SIRET est obligatoire';
    } else if (this.siretControl.hasError('siret')) {
      return this.siretControl.errors.siret;
    }
  }

  public get phoneControl(): AbstractControl {
    return this.clientForm.get('phone');
  }

  public getPhoneErrorMessage(): string {
    if (this.phoneControl.hasError('phone')) {
      return this.phoneControl.errors.phone;
    }
  }

  public get mailControl(): AbstractControl {
    return this.clientForm.get('mail');
  }

  public getMailErrorMessage(): string {
    if (this.mailControl.hasError('email')) {
      return 'Format de mail invalide (exemple@gmail.com)';
    }
  }

  public get phoneFixControl(): AbstractControl {
    return this.clientForm.get('phoneFix');
  }

  public getPhoneFixErrorMessage(): string {
    if (this.phoneFixControl.hasError('phone')) {
      return this.phoneFixControl.errors.phone;
    }
  }

  public get vatNumberControl(): AbstractControl {
    return this.clientForm.get('vatNumber');
  }

  public getVatNumberErrorMessage(): string {
    if (this.vatNumberControl.hasError('vatNumber')) {
      return this.vatNumberControl.errors.vatNumber;
    }
  }

  public updateContactList(contacts: IContact[]): void {
    this.currentClient.contactsId = contacts.map(contact => contact.id)
    this.saveClientData();
  }

  public get countryControl(): AbstractControl {
    return this.clientForm.get('country');
  }

  public getCountryErrorMessage(): string {
    if (this.countryControl.hasError('required')) {
      return 'Le pays est obligatoire';
    } else if (this.countryControl.hasError('country')) {
      return this.countryControl.errors.country;
    }
  }

  public getAccountingAccountFn(accountingAccount: AccountingAccount): string {
    return accountingAccount ? `${accountingAccount.code} - ${accountingAccount.name}` : '';
  }

}
