import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IClient } from '@core/models/client.models';
import { IEntity } from '@core/models/entity.model';
import { Invoice, InvoiceTypeLabel } from '@core/models/invoice.model';
import { IProjectFacture, IProjet } from '@core/models/projet.models';
import { EntityService } from '@core/services/entity.service';
import { InvoiceService } from '@core/services/invoice.service';
import moment from 'moment';
import { Subscription, firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'app-add-due-date',
  templateUrl: './add-due-date.component.html',
  styleUrls: ['./add-due-date.component.scss']
})
export class AddDueDateComponent implements OnInit, OnDestroy {

  public dueDateForm: FormGroup;

  public projectFacture: IProjectFacture;
  public _projectFacture: Subscription;

  private defaultEntity: IEntity;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {project: IProjet, remainsToBeBilled: number, create: boolean, due: string, tvaRate: number, client:IClient, invoice?: Invoice},
    private readonly entityServic: EntityService,
    private dialog: MatDialogRef<AddDueDateComponent>,
    private invoiceService: InvoiceService,
  ) { }

  public async ngOnInit(): Promise<void> {
    this.initForm();
    if(!this.data.create) {
      this.dueDateForm.patchValue({
        type: this.data.invoice?.dueLabel,
        num: this.data.invoice?.invoiceNumber,
        amount: this.data.invoice?.amount,
        send: this.data.invoice?.hasBeenSent,
        forecastPlan: moment(this.data.invoice?.plannedDate).format('YYYY-MM-DD')
      });
      this.dueDateForm.updateValueAndValidity();
    }
    this.defaultEntity = await firstValueFrom(this.entityServic.entities$.pipe(
      map((entities: IEntity[]) => entities.find((entity: IEntity) => entity.value === 'FA'))
    ));
  }

  public ngOnDestroy(): void {
    this._projectFacture && this._projectFacture.unsubscribe();
  }

  public initForm(): void {
    this.dueDateForm = new FormGroup({
      type: new FormControl('', [Validators.required]),
      num: new FormControl('', [Validators.nullValidator]),
      amount: new FormControl(0, [Validators.nullValidator]),
      send: new FormControl(false, [Validators.required]),
      forecastPlan: new FormControl(moment(new Date()).format('YYYY-MM-DD'), [Validators.required])
    });
  }

  public getProjectFactureFromForm(): Invoice {
    return {
      id: this.data.invoice?.id || '',
      entity: this.data.invoice?.entity || this.defaultEntity?.key || '',
      client: this.data.invoice?.client || this.data.project.clientId,
      bankAccount: this.data.invoice?.bankAccount || (this.defaultEntity?.defaultBankAccountId ??  ''),
      due: this.data.invoice?.due || this.data.due,
      type: this.data.invoice?.type || InvoiceTypeLabel.find((type) => type.label === 'FACTURE')?.value,
      invoiceNumber: this.dueDateForm.get('num').value || this.defaultEntity ? this.defaultInvoiceNumber : '',
      label: this.data.invoice?.label || '',
      deliveryNumber: this.data.invoice?.deliveryNumber || this.data.project.numBonDeCommande || '',
      contractNumber: this.data.invoice?.contractNumber || (this.data.project.numContract ?? ''),
      supplierNumber: this.data.invoice?.supplierNumber || '',
      tvaRate: (this.data.invoice?.tvaRate || this.data.tvaRate) ?? 0,
      lines: this.data.invoice?.lines || [
        {
          name: '',
          quantity: 0,
          unit: '',
          unitPrice: 0,
          id: '',
          tvaRate: ''
        }
      ],
      hasBeenSent: this.dueDateForm.get('send').value,
      plannedDate: new Date(this.dueDateForm.get('forecastPlan').value),
      dueLabel: this.dueDateForm.get('type').value,
      amount: this.dueDateForm.get('amount').value ?? 0,
      paymentMean: this.data.invoice?.paymentMean || '30',
      accountingAccount: this.data.invoice?.accountingAccount || this.data.client.defaultAccountingAccountId || '',
    }
  }

  public get defaultInvoiceNumber(): string {
    const month: number = new Date(this.dueDateForm.get('forecastPlan').value).getMonth()+1;
    const year: number = new Date(this.dueDateForm.get('forecastPlan').value).getFullYear();
    const prefix: string = InvoiceTypeLabel.find((type) => type.label === 'FACTURE')?.prefix;
    return this.getEntityPrefix(prefix, this.defaultEntity) + year.toString().substring(2) + (month < 10 ? `0${month}` : month) + '-'
  }

  private getEntityPrefix(prefix: string, entity: IEntity): string {
    return prefix + entity.value.substring(2);
  }

  // public async submit(): Promise<void> {
  //   if(this.dueDateForm.valid) {
  //     const invoice: Invoice = this.getProjectFactureFromForm();
  //     console.log('compoenent', invoice)
  //     if(this.data.create) {
  //       return this.invoiceService.addInvoice(this.data.project.key, invoice).then(() => this.dialog.close());
  //     } else {
  //       return this.invoiceService.updateInvoice(this.data.project.key, invoice, false).then(() => this.dialog.close());
  //     }
  //   }
  // }

  public async submit(): Promise<void> {
    if(this.dueDateForm.valid) {
      const invoice: Invoice = this.getProjectFactureFromForm();
      if(this.data.create) {
        return this.invoiceService.addInvoice(this.data.project.key, invoice).then(() => {
          this.dueDateForm.reset();
          this.dialog.close();
        });
      } else {
        return this.invoiceService.updateInvoice(this.data.project.key, invoice, false).then(() => {
          this.dueDateForm.reset();
          this.dialog.close();
        });
      }
    }
  }
}
