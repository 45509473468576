import { Component, Inject, OnInit } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProjectFacture } from '@core/models/projet.models';
import { ProjetsService } from '@core/services/projets.service';

@Component({
  selector: 'app-add-project-facture',
  templateUrl: './add-project-facture.component.html',
  styleUrls: ['./add-project-facture.component.scss']
})
export class AddProjectFactureComponent implements OnInit {
  public projectFacture: IProjectFacture = {
    typeFacture: null,
    pourcent: null,
    amountHT: null,
    numberFacture: null,
    issuedFacture: false,
    planningForecast: null
  };
  public checkboxColor = "warn";
  private projectKey: string;
  public projectAmount: number;
  public update = false;

  public pourcentage: boolean = true;
  public montant: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA)
    public data: any,
    private projectService: ProjetsService,
    private dialogRef: MatDialogRef<AddProjectFactureComponent>) { }

  ngOnInit(): void {
    this.projectKey = this.data.key;
    this.projectAmount = this.data.amount;
    this.update = this.data.update ? true : false
    if (this.update) {
      this.projectFacture.key = this.data.factureKey;
      this.projectFacture.typeFacture = this.data.typeFacture;
      this.projectFacture.numberFacture = this.data.numberFacture;
      this.projectFacture.issuedFacture = this.data.issuedFacture;
      this.projectFacture.pourcent = this.data.pourcent;
      this.projectFacture.planningForecast = this.data.planningForecast;
      this.projectFacture.amountHT = this.projectAmount * this.projectFacture.pourcent / 100;
    }

    if((this.projectFacture.amountHT !=0 && this.projectFacture.amountHT != null) && (this.projectFacture.pourcent !=0 && this.projectFacture.pourcent != null)){
      this.montant = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    let ok = true;
    if((this.projectFacture.amountHT >= 0 && this.projectFacture.amountHT <= this.projectAmount) && (this.projectFacture.pourcent >= 0 && this.projectFacture.pourcent <= 100)){
      if(this.projectFacture.amountHT == null && this.projectFacture.pourcent == null){
        window.alert("Veuillez remplir le champ pourcentage ou montant");
        ok = false
      }
      else if((this.projectFacture.amountHT !=0 && this.projectFacture.amountHT != null) && (this.projectFacture.pourcent !=0 && this.projectFacture.pourcent != null)){
        this.projectFacture.pourcent = this.projectFacture.amountHT * 100 / this.projectAmount;
      }
      else if(this.projectFacture.amountHT == null || (this.projectFacture.amountHT == 0 && this.projectFacture.pourcent !=0)){
        this.projectFacture.amountHT = this.projectAmount * this.projectFacture.pourcent / 100;
      }else if(this.projectFacture.pourcent == null || (this.projectFacture.amountHT != 0 && this.projectFacture.pourcent ==0)){
        this.projectFacture.pourcent = this.projectFacture.amountHT * 100 / this.projectAmount;
        this.projectFacture.pourcent = Math.trunc(this.projectFacture.pourcent);
      }
      if(this.projectFacture.amountHT == 0 && this.projectFacture.pourcent == null){
        this.projectFacture.amountHT = 0;
        this.projectFacture.pourcent = 0;
      }
    } else{
      window.alert("Erreur de saisie (pourcentage ou montant)");
      ok = false
    }

    if(ok){
      if(!!this.projectFacture.typeFacture && !!this.projectFacture.planningForecast){
        if (!this.update) {
          this.projectService.addFactureForProject(this.projectKey, this.projectFacture);
        } else {
          this.projectService.updateFacture(this.projectKey, this.projectFacture)
        }
        this.dialogRef.close();
      }
      else window.alert("Nom de la facture ou planning prévisionnel incomplet");
    }
  }

  dateChange(event: MatDatepickerInputEvent<Date>) {
    this.projectFacture.planningForecast = event.value
  }
}
