import { NgModule } from '@angular/core';

import { ValidationProjectsRoutingModule } from './validation-projects-routing.module';
import { ValidationProjectsComponent } from './components/validation-projects/validation-projects.component';
import { SharedModule } from '@shared/shared.module';
import { GestServicesModule } from '@pages/gest-services/gest-services.module';


@NgModule({
  declarations: [ValidationProjectsComponent],
  imports: [
    SharedModule,
    ValidationProjectsRoutingModule,
    GestServicesModule
  ]
})
export class ValidationProjectsModule { }
