import { NgModule } from '@angular/core';

import { EditArticleRoutingModule } from './edit-article-routing.module';
import { EditArticleComponent } from './components/edit-article/edit-article.component';
import { SharedModule } from '@shared/shared.module';
import { GestServicesModule } from '@pages/gest-services/gest-services.module';


@NgModule({
  declarations: [EditArticleComponent],
  imports: [
    SharedModule,
    EditArticleRoutingModule,
    GestServicesModule
  ]
})
export class EditArticleModule { }
