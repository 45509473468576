import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IClient } from '@core/models/client.models';
import { Error } from '@core/models/error.model';

@Component({
  selector: 'app-client-error-modal',
  templateUrl: './client-error-modal.component.html',
  styleUrls: ['./client-error-modal.component.scss']
})
export class ClientErrorModalComponent {

  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      client: IClient,
      errors: Error<IClient>[]
    },
    private readonly router: Router
  ) { }

  public get errors(): Error<IClient>[] {
    return this.data.errors;
  }

  public get client(): IClient {
    return this.data.client;
  }

  public goToClient(): Promise<boolean> {
    return this.router.navigateByUrl(`client/manage/${this.client.key}`);
  }

  public getClientName(): string {
    return this.client.name || `${this.client.firstName} ${this.client.lastName}`;
  }

}
