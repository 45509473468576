<mat-form-field class="inputPlaceHolder"><input matInput (keyup)="applyFilter($event.target.value)" placeholder="Recherche clients"></mat-form-field>
<div class="mat-elevation-z8 table">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
    <ng-container matColumnDef="name">
      <th class="test" mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
      <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"(click)="selectClient(row.name,row.key)" class="row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
  <button mat-button mat-dialog-close type="button">Annuler</button>
</div>
