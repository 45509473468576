import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IContact } from '@core/models/contacts.model';
import { ContactsService } from '@core/services/contacts.service';
import { AddContactsComponent } from '@shared/contacts/modals/add-contacts/add-contacts.component';
import { EditContactsComponent, EditContactsResultEnum } from '@shared/contacts/modals/edit-contacts/edit-contacts.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-contacts',
  templateUrl: './list-contacts.component.html',
  styleUrls: ['./list-contacts.component.scss']
})
export class ListContactsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public parentId: string;
  @Input() public parentType: string;

  @Output() public contactsChange: EventEmitter<IContact[]> = new EventEmitter<IContact[]>();

  public displayedColumns: string[] = ['name','type'];

  public contacts: IContact[] = [];
  public _contacts: Subscription;

  @ViewChild(MatPaginator) public paginator: MatPaginator;
  @ViewChild(MatSort) public sort: MatSort;
  public dataSource: MatTableDataSource<IContact>;

  constructor(private contactService: ContactsService,
    private dialog: MatDialog) { }

  public ngOnInit(): void {
    this._contacts = this.contactService.contactByParent$.subscribe(contacts => {
      this.contacts = contacts
      this.dataSource = new MatTableDataSource<IContact>(this.contacts);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public ngOnDestroy(): void {
    this._contacts && this._contacts.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.parentId) {
      this.contactService.updateContactsByParent(changes.parentId.currentValue);
    }
  }

  public addContact(): void {
    const dialogRef: MatDialogRef<AddContactsComponent> = this.dialog.open(AddContactsComponent, {
      width: '450px',
      data: {parent: this.parentId, parentType: this.parentType}
    });
    dialogRef.afterClosed().subscribe(contact => {
      if(contact) {
        this.contacts.push(contact);
        this.contactsChange.emit(this.contacts);
        this.contactService.updateContactsByParent(this.parentId);
      }
    });
  }

  public selectContact(contact: IContact): void {
    const dialogRef: MatDialogRef<EditContactsComponent, EditContactsResultEnum> = this.dialog.open(EditContactsComponent, {
      width: '450px',
      data: contact
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result === EditContactsResultEnum.DELETE) {
        this.contacts.splice(this.contacts.indexOf(contact), 1);
      }
      if (result === EditContactsResultEnum.UPDATE || result === EditContactsResultEnum.DELETE) {
        this.contactsChange.emit(this.contacts);
        this.contactService.updateContactsByParent(this.parentId);
      }
    });

  }

  public getFullName(contact: IContact): string {
    return `${contact.firstName} ${contact.lastName}`;
  }

  public sortData(sort: MatSort): void {
    const data = this.dataSource.data.slice();
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(this.getFullName(a), this.getFullName(b), isAsc);
        default: return 0;
      }
    });
  }

  private compare(a: string | number, b: string | number, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
