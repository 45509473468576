import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IProjet } from '@core/models/projet.models';
import { AuthService } from '@core/services/auth.service';
import { ClientsService } from '@core/services/clients.service';
import { ProjetsService } from '@core/services/projets.service';
import { AddProjectComponent } from '@pages/projects/modals/add-project/add-project.component';
import { CopyProjectsComponent } from '@pages/projects/modals/copy-projects/copy-projects.component';
import { DeleteProjectComponent } from '@pages/projects/modals/delete-project/delete-project.component';
import { UpdateProjectComponent } from '@pages/projects/modals/update-project/update-project.component';

@Component({
  selector: 'app-list-projects',
  templateUrl: './list-projects.component.html',
  styleUrls: ['./list-projects.component.scss'],
})
export class ListProjectsComponent implements OnInit {
  private projetsSubscribe;
  public clientId: string;
  displayedColumns: string[] = ['name', 'dateAjout', 'dateUpdate'];
  dataSource: MatTableDataSource<IProjet>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientsService: ClientsService,
    private db: AngularFirestore,
    private snackBar: MatSnackBar,
    private projetsService: ProjetsService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Projets par page';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number,
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 sur ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.clientId = this.route.snapshot.paramMap.get('key');
    this.projetsSubscribe = this.db
      .collection<IProjet>('projects', (ref) =>
        ref.where('clientId', '==', this.clientId),
      )
      .valueChanges({ idField: 'key' })
      .subscribe(
        (data) => {
          this.setDatasource(data);
        },
        (error) => {
          this.snackBar.open(error, 'x', {
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: ['red-snackbar'],
          });
        },
      );
  }
  ngOnDestroy() {
    this.projetsSubscribe.unsubscribe();
  }
  addProjet() {
    const dialogRef = this.dialog.open(AddProjectComponent, {
      width: '300px',
      data: '',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.projetsService.addProject(
          this.clientId,
          result,
          this.authService.userFB.uid,
        );
      }
    });
  }

  onDelete(projectKey: string) {
    const dialogRef = this.dialog.open(DeleteProjectComponent, {
      width: '500px',
      data: '',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.projetsService.deleteProject(projectKey);
      }
    });
  }

  onUpdate(projectKey: string) {
    const dialogRef = this.dialog.open(UpdateProjectComponent, {
      width: '300px',
      data: '',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.projetsService.updateProjectName(projectKey, result);
      }
    });
  }

  public copyProject(projectKey: string): Promise<boolean> {
    return this.projetsService.getDataFromProject(projectKey).then((data) => {
      const dialogRef = this.dialog.open(CopyProjectsComponent, {
        width: '500px',
        data: '',
      });
      return dialogRef
        .afterClosed()
        .toPromise()
        .then((result) => {
          if (result) {
            alert(
              'Le projet ' +
                data.project.name +
                ' est copié chez le client ' +
                result.nameClient,
            );
            data.newClientId = result.idClient;
            return this.projetsService.addCopyProject(data).then(() => {
              return this.router.navigate([`../${data.newClientId}`], {
                relativeTo: this.route,
              });
            });
          }
        });
    });
  }

  private setDatasource(projets: IProjet[]) {
    this.dataSource = new MatTableDataSource(projets);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator.firstPage();
    this.dataSource.filterPredicate = (
      text: { name: string },
      filterValue: string,
    ) => text.name.trim().toLowerCase().indexOf(filterValue) !== -1;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.paginator.firstPage();
  }

  showProjet(key: string) {
    this.router.navigateByUrl(`project/${key}/devis/0`);
  }

  currentClient() {
    return this.clientsService.clients.filter(
      (client) => client.key === this.clientId,
    )[0];
  }

  stopProp(event) {
    event.stopPropagation();
  }
}
