import { IClient } from "@core/models/client.models";
import { Error } from "@core/models/error.model";

export interface MissingClientNameError extends Error<IClient> {}
export interface MissingClientReglementDateError extends Error<IClient> {}
export interface MissingClientAdressError extends Error<IClient> {}
export interface MissingClientCityError extends Error<IClient> {}
export interface MissingClientZipCodeError extends Error<IClient> {}
export interface MissingClientCountryError extends Error<IClient> {}
export interface MissingClientSiretError extends Error<IClient> {}
export interface MissingClientIntracommunautaireError extends Error<IClient> {}

export const missingClientNameErrorValidator = (value: IClient) => {
  return Promise.resolve(value.name || (value.firstName && value.lastName) ? null : {
    value,
    message: 'Le nom du client est requis'
  })
};

export const missingClientReglementDateErrorValidator = (value: IClient) => {
  return Promise.resolve(value.reglementDate !== null ? null : {
    value,
    message: 'Le terme de paiement du client est requis'
  })
}

export const missingClientAddressErrorValidator = (value: IClient) => {
  return Promise.resolve(value.society.adress ? null : {
    value,
    message: 'L\'adresse du client est requis'
  })
}

export const missingClientCityErrorValidator = (value: IClient) => {
  return Promise.resolve(value.society.city ? null : {
    value,
    message: 'La ville du client est requis'
  })
}

export const missingClientZipCodeErrorValidator = (value: IClient) => {
  return Promise.resolve(value.society.zipCode ? null : {
    value,
    message: 'Le code postal du client est requis'
  })
}

export const missingClientCountryErrorValidator = (value: IClient) => {
  return Promise.resolve(value.society.country ? null : {
    value,
    message: 'Le pays du client est requis'
  })
}

export const missingClientSiretErrorValidator = (value: IClient) => {
  return Promise.resolve(value.society.siret ? null : {
    value,
    message: 'Le numéro de siret du client est requis'
  })
}

export const missingClientIntracommunautaireErrorValidator = (value: IClient) => {
  return Promise.resolve(value.society.intracommunautaire ? null : {
    value,
    message: 'Le numéro intracommunautaire du client est requis'
  })
}