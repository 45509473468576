import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IService } from '@core/models/service.models';

@Component({
  selector: 'app-modify-unity',
  templateUrl: './modify-unity.component.html',
  styleUrls: ['./modify-unity.component.scss']
})
export class ModifyUnityComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: IService) { }
  public unity = null;

  ngOnInit(): void {
  }

}
