import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, provideRoutes } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalAuthGuard implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly auth: AngularFireAuth,
    private readonly router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const token = route.queryParams.token;
    if (token) {
      return this.authService.signInWithToken(token).then(() => this.router.navigate(route.url));
    }
    return new Promise((resolve) => {
      return this.auth.onAuthStateChanged((user) => {
        if (user) {
          resolve(true);
        } else {
          if (this.authService.firstCookieLog) {
            this.authService.logFromCookie().then(() => {
              return this.canActivate(route, state);
            });
          } else {
            resolve(false);
          }
        }
      })
    });
  }
  
}