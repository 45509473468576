import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableNullValue',
  pure: true
})
export class TableNullValuePipe implements PipeTransform {
  
  public transform(value: any): any {
    return value === null || value === undefined ? '-' : value;
  }
}