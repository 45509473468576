<div class="tableau">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" >

    <ng-container matColumnDef="points">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button class="points" mat-icon-button (click)="modifyUnity(this.currentService)">
          <i class="fas fa-ellipsis-v"></i>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nom du service</th>
      <td mat-cell *matCellDef="let element">
        <div class="truncated">{{ element.name }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="contenu">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >Contenu du service</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.articles" class="articles">
          <span
            class="panel-description"
            *ngIf="element && element.articles && element.articles.length > 0"
            >{{ element.articles.length }} article(s) ({{
              getMontantTotalByService(element)
            }}
            € / {{ getChargeTotalByServiceDay(element) }}
            {{ element.unity }})</span
          >
          <span
            class="panel-description"
            *ngIf="
              !element || !element.articles || element.articles.length === 0
            "
            >0 article (0 € / 0 J)</span
          >
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let index = dataIndex; columns: displayedColumns" class="row" (click)="setCurrentService(row)"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[4, 8, 12]" showFirstLastButtons></mat-paginator>
</div>

<button mat-raised-button class="addButtonInPanel" (click)="addCustomService()">
  Ajouter un service
</button>
