<h2>Choisissez votre unité de temps :</h2>
<mat-form-field appearance="outline" class="choix">
  <mat-select [(value)]="unity" >
    <mat-option value = "J">Jours</mat-option>
    <mat-option value = "M">Mois</mat-option>
  </mat-select>
</mat-form-field>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-around center">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button color="primary" [mat-dialog-close]="unity">Enregistrer</button>
</div>
