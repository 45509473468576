import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-projects',
  templateUrl: './client-projects.component.html',
  styleUrls: ['./client-projects.component.scss']
})
export class ClientProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
