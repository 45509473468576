import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentChangeAction,
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot
} from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IClient, ISociety } from '@core/models/client.models';
import { IPieChart } from '@core/models/dasboard.model';
import { ClientSearch, PapperSearchResult, buildNewClient } from '@core/models/search.model';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { BehaviorSubject, Observable, Subject, firstValueFrom, lastValueFrom, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SearchService } from './search.service';
import { AngularFireFunctions } from '@angular/fire/functions';


type ISell = {
  clientId: string;
  accountingAccountId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  public clients: IClient[] = [];
  clientsChange = new Subject<void>();
  public dataPiechart: IPieChart[] = [];
  public clientsSubscribe;

  public clients$$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  public researchName$$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  private readonly COLLECTION_NAME: string = 'clients';

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private snackBar: MatSnackBar,
    private readonly searchService: SearchService,
    private readonly functions: AngularFireFunctions
  ) {}


  init() {
    this.cancelSubscribe();
    this.clientsSubscribe = this.db
      .collection('clients')
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<unknown>[]) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const key = a.payload.doc.id;
            if (data && key) {
              data.key = key;
            }
            return { key, ...data };
          })
        )
      )
      .subscribe(
        (data) => {
          this.clients = data;
          this.clientsChange.next();
        },
        (error) => {
          this.snackBar.open(error, 'x', {
            duration: 4000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: ['red-snackbar'],
          });
        }
      );
  }

  cancelSubscribe() {
    if (this.clientsSubscribe) {
      this.clientsSubscribe.unsubscribe();
    }
  }

  public getClientById(id: string): Observable<IClient> {
    return this.db.collection<IClient>('clients').doc(id).get().pipe(
      map((client: DocumentSnapshot<DocumentData>) => ({
        ...client.data(),
        key: client.id
      } as IClient))
    );
  }

  getAllClient() {
    return this.db
      .collection('clients')
      .get()
      .pipe(
        map((values: QuerySnapshot<DocumentData>) => {
          return values.docs.map((doc) => {
            return {
              key: doc.id,
              ...doc.data(),
            } as IClient;
          });
        }),
        map((clients: IClient[]) => clients.sort((a, b) => a.name.localeCompare(b.name)))
      );
  }

  addClient(clientName: string, uid: string) {

    const dataToadd: IClient = {
      name: clientName,
      society: {},
      nbProjects: 0,
      added: {
        by: uid,
        date: new Date(),
      },
      comments: [],
      contact: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        type: '',
        actif: false
      },
      inactif: false,
      reglementDate: 0,
    };
    this.db
      .collection('clients')
      .add(dataToadd)
      .then(() => {
        const increment = firebase.firestore.FieldValue.increment(1);
        this.db.doc('/stats/customStats').update({ clientsCount: increment });
      });
  }


  public async addClients({ ...client }: IClient): Promise<IClient> {
    const clientDoc: AngularFirestoreDocument<IClient> = this.db.doc<IClient>(`${this.COLLECTION_NAME}/${this.db.createId()}`);
    await clientDoc.set(client);
    return { ...client, key: clientDoc.ref.id };
  }



  public async updateClient(client: IClient): Promise<void> {
    if (client.comments) {
      client.comments = client.comments.map((op) => {
        return {
          ...op,
        };
      });
    }

    await this.db.doc('clients/' + client.key).update({
      ...client
    });
    return lastValueFrom(this.updateAllSells(client));
  }

  public updateAllSells(client: IClient): Observable<void> {
    return this.db.collection('ventes').get().pipe(
      switchMap((querySnapshot: QuerySnapshot<ISell>) => {
        const docs: QueryDocumentSnapshot<ISell>[] = querySnapshot.docs;
        const batch = this.db.firestore.batch();
        docs.forEach((doc) => {
          const sell: ISell = doc.data();
          if (sell.clientId === client.key && !sell.accountingAccountId) { // Only update if the sell has no accountingAccountId
            batch.update(doc.ref, { accountingAccountId: client.defaultAccountingAccountId });
          }
        });
        return batch.commit();
      })
    );
  }

  public updateClients(): void {
    this.clients$$.next();
  }

  onDelete(clientKey: string) {
    this.db.doc('clients/' + clientKey).delete();
  }

  getClientNameAndNbProject(idClient: string[]) {
    if (this.dataPiechart) {
      this.dataPiechart = [];
    }
    for (let client of idClient) {
      let dataClient = this.getAClient(client);
      let pieChartData = {
        nameClient: dataClient.name,
        nbProject: dataClient.nbProjects,
      } as IPieChart;
      this.dataPiechart.push(pieChartData);
    }
    return this.dataPiechart;
  }

  public updateClientList(): void {
    this.clients$$.next(null);
  }

  public updateResearchList(name: string): void {
    this.researchName$$.next(name);
  }



  getAClient(key: string) {
    return this.clients.filter((client) => client.key === key)[0] || null;
  }

  public get research$(): Observable<IClient[]> {
    return this.researchName$$.pipe(
      switchMap((name: string) => (name ? this.researchClientByName(name) : []))
    );
  }

  public researchClientByName(name: string): Observable<IClient[]> {
    const url: string = `${environment.nodeProtocol}://${environment.nodeURL}/papper/name/${name}`;
    return this.http.get<IClient[]>(url);
  }


  public createClientWithSociety(society: ISociety, userId: string): Promise<IClient> {
    const uid = userId;

    const dataToAdd: IClient = {
      name: '',
      society: {
        typeResidence: society.typeResidence || '',
        adress: society.adress || '',
        zipCode: society.zipCode || '',
        city: society.city || '',
        country: society.country || '',
        siret: society.siret || '',
        specialMention: society.specialMention || '',
        intracommunautaire: society.intracommunautaire || '',
        cedexCode: society.cedexCode || '',
        cedexLabel: society.cedexLabel || '',
        logo: society.logo || '',
      },
      nbProjects: 0,
      added: {
        by: uid,
        date: new Date(),
      },
      comments: [],
      contact: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        type: '',
        actif: false
      },
      inactif: false,
      reglementDate: 0,
    };
    return this.addClients(dataToAdd);

  }

  public deleteClient(client: IClient): Promise<void> {
    return this.db.collection(this.COLLECTION_NAME).doc(client.key).delete();
  }

  public async searchFirebaseClient(siret?: string, name?: string): Promise<ClientSearch[]> {
    const clients: IClient[] = await firstValueFrom(this.getAllClient());
    if (siret) {
      return clients
        .filter((c) => c.society && c.society.siret)
        .filter((c) => c.society.siret === siret)
        .map((c) => ({ resemblence: 1, client: c, isInDatabase: true }));
    }
    if (name) {
      return (await this.searchService.compareStrings(clients, 'name', name)).map((c) => ({ resemblence: c.distance, client: c.value, isInDatabase: true }));
    }
  }

  public async searchPappersClient(siret?: string, name?: string): Promise<ClientSearch[]> {
    if (environment.connectFrom === 'FIREBASE') return this.searchPapperClientForExternal(siret, name);
    let url: string = `${environment.nodeProtocol}://${environment.nodeURL}/papper`;
    if (siret) {
      url += `/siret/${siret}`;
      return firstValueFrom(this.http.get<PapperSearchResult>(url).pipe(
        map<PapperSearchResult, ClientSearch[]>((results: PapperSearchResult) => ([
          {
            resemblence: 1,
            client: buildNewClient(results),
            isInDatabase: false,
          } as ClientSearch
        ]))
      ));
    } else if (name) {
      url += `/name/${name}`;
      return firstValueFrom(this.http.get<PapperSearchResult[]>(url).pipe(
        map<PapperSearchResult[], ClientSearch[]>((results: PapperSearchResult[]) => results.map((result) => ({
          resemblence: null,
          client: buildNewClient(result),
          isInDatabase: false,
        })))
       ));
    }
  }

  private async searchPapperClientForExternal(siret?: string, name?: string): Promise<ClientSearch[]> {
    const result: PapperSearchResult[] = (await firstValueFrom(this.functions.httpsCallable<{type: 'siret' | 'name', value: string}, PapperSearchResult[]>('searchPappers')({ type: siret ? 'siret' : 'name', value: siret || name })));
    if (siret) {
      return [{
        resemblence: 1,
        client: buildNewClient(result[0]),
        isInDatabase: false,
      }];
    } else {
      return result.map((r) => ({
        resemblence: null,
        client: buildNewClient(r),
        isInDatabase: false,
      }));
    }
  }

}

