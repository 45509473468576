<form [formGroup]="devisForm">
  <div class="grid-container">
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Entité</mat-label>
      <mat-select formControlName="entity" required (selectionChange)="onEntityChange($event)">
        <mat-option *ngFor="let entity of entities" [value]="entity.key">
          {{entity.name}} ( {{ entity.value }})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Client</mat-label>
      <mat-select formControlName="client" required panelClass="client-select-panel" (selectionChange)="onClientChange($event)">
        <mat-option *ngFor="let client of clients" [value]="client.key">
          {{ getClientName(client) }}
        </mat-option>
        <div class="add-client-option">
          <button mat-menu-item (click)="addClient()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Compte en banque</mat-label>
      <mat-select formControlName="bankAccount" (selectionChange)="onBankAccountChange($event)">
        <mat-option *ngFor="let account of bankAccounts" [value]="account.id">
          {{account.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Validité du devis</mat-label>
      <mat-select formControlName="due">
        <mat-option *ngFor="let due of DueValueLabel" [value]="due.value">
          {{ due.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Type de document</mat-label>
      <mat-select formControlName="type">
        <mat-option value="DEVIS">Devis</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Numéro de devis</mat-label>
      <input matInput placeholder="Numéro de devis" formControlName="invoiceNumber">
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Nom du devis</mat-label>
      <input matInput placeholder="Nom du devis" formControlName="label" required>
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Numéro de commande</mat-label>
      <input matInput placeholder="Numéro de commande" formControlName="deliveryNumber">
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Numéro de contrat</mat-label>
      <input matInput placeholder="Numéro de contrat" formControlName="contractNumber">
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item">
      <mat-label>Numéro du client</mat-label>
      <input matInput placeholder="Numéro du client" formControlName="clientNumber">
    </mat-form-field>
    <mat-form-field appearance="outline" class="grid-item grid-item-full">
      <mat-label>Commentaire</mat-label>
      <input matInput placeholder="Commentaire" formControlName="comment">
    </mat-form-field>
  </div>
</form>


