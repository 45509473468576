import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, DocumentReference, DocumentSnapshot, QueryDocumentSnapshot, QuerySnapshot} from '@angular/fire/firestore';
import { IEntity } from '@core/models/entity.model';
import { Invoice, InvoiceDevisExpress, InvoiceDevisExpressLine, InvoiceLine, TresoInvoice} from '@core/models/invoice.model';
import { firestore } from 'firebase';
import { Observable, firstValueFrom, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DueValueLabel } from '@pages/projects/components/invoice/invoice-header/invoice-header.component';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { getDevisExpressTemplate } from 'src/assets/pdfTemplates/devisExpressTemplate';
import { IClient } from '@core/models/client.models';
import { IBankAccount } from '@core/models/bank.model';
import { IProjet } from '@core/models/projet.models';
import { AuthService } from './auth.service';
import { IUser } from '@core/models/users.models';

/**
 * Invoice devis express service
 *
 * @description
 * Service for managing invoice devis express
 *
 * @exports
 * @class InvoiceDevisExpressService
 */
@Injectable({
  providedIn: 'root'
})
export class InvoiceDevisExpressService {

  /** The projects collection name */
  private readonly COLLECTION_NAME_PROJECT = 'projects';

  /** The lines collection name */
  private readonly COLLECTION_NAME_LINE = 'lines';

  /** The devis express collection name */
  private readonly EXPRESS_COLLECTION_NAME = 'devis-express';

  /** The devis collection name */
  private readonly COLLECTION_NAME_DEVIS = 'devis';

  /** The interceptor HTTP */
  private bypassInterceptorHttp: HttpClient = new HttpClient(this.httpBackend);


  /**
   * Constructor
   *
   * @param firestore - The AngularFirestore service
   * @param http - The HttpClient service
   * @param httpBackend - The HttpBackend service"
   */
  constructor(
    private readonly firestore: AngularFirestore,
    private readonly httpBackend: HttpBackend,
    private readonly authService: AuthService
  ) { }



  /**
   * Retrieves an invoice devis express by its ID.
   *
   * @param id - The ID of the invoice devis express to retrieve.
   *
   * @returns An Observable emitting the retrieved invoice devis express.
   * @memberof InvoiceDevisExpressService
   */
  public getDevisExpressById(id: string): Observable<InvoiceDevisExpress> {
    if (!id) {
      console.error('ID is null or empty');
      return of(null);
    }
    return this.firestore
      .collection(this.EXPRESS_COLLECTION_NAME)
      .doc<InvoiceDevisExpress>(id)
      .get()
      .pipe(
        map((result: DocumentSnapshot<DocumentData>) => ({
          ...result.data(),
          plannedDate: new firestore.Timestamp(
            (result.data().plannedDate as any).seconds,
            0
          ).toDate(),
          id: result.id,
        })),
        switchMap((invoice: InvoiceDevisExpress) =>
          this.firestore
            .collection(this.EXPRESS_COLLECTION_NAME)
            .doc<InvoiceDevisExpress>(id)
            .collection<InvoiceDevisExpressLine>(this.COLLECTION_NAME_LINE)
            .get()
            .pipe(
              map((lines: QuerySnapshot<InvoiceDevisExpressLine>) => ({
                ...invoice,
                lines: lines.docs.map(
                  (line: QueryDocumentSnapshot<InvoiceDevisExpressLine>) => ({
                    ...line.data(),
                    id: line.id,
                  })
                ),
              }))
            )
        )
      );
  }

  /**
   * Generates a unique line ID for the invoice lines.
   *
   * @param lines - The array of invoice lines.
   * @returns The generated line ID as a string.
   */
  private createLineId(lines: InvoiceLine[]): string {
    // Find the max id + 1
    const ids: number[] = lines
      .filter((line) => line.id)
      .map((line: InvoiceLine) => parseInt(line.id, 10));

    if (ids.length === 0) {
      return '0';
    }

    const maxId: number = Math.max(...ids);
    return (maxId + 1).toString();
  }

  /**
   * Creates a PDF for the invoice devis express.
   *
   * @param invoice - The invoice devis express data.
   * @param client - The client data.
   * @param entity - The entity data.
   * @param account - The bank account data.
   * @param entityLogo - The URL or path to the entity's logo.
   * @param amountFunc - A function to calculate the amount based on quantity, unit price, and unit.
   *
   * @returns A Promise that resolves to the generated PDF data.
   * @memberof InvoiceDevisExpressService
   */
  public createPdfDevisExpress(invoice: InvoiceDevisExpress, client: IClient, entity: IEntity, account: IBankAccount, entityLogo: string, amountFunc: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number): Promise<any> {
    return getDevisExpressTemplate(
      invoice,
      client,
      entity,
      account,
      entityLogo,
      amountFunc
    );
  }

  /**
   * Upserts (creates or updates) an invoice devis express document in Firestore.
   *
   * @param devis - The invoice devis express data to upsert.
   * @param updateLine - A flag indicating whether to update the lines collection.
   * @param calcFunc - An optional function to calculate the amount based on quantity, unit price, and unit.
   * @param invoiceHeaderData - An optional object containing additional invoice header data to merge with the upserted data.
   *
   * @returns A Promise that resolves when the upsert operation is complete.
   * @memberof InvoiceDevisExpressService
   */
  public async upsertDevisExpress(
    devis: InvoiceDevisExpress,
    updateLine: boolean,
    calcFunc?: (amount: number, unitPrice: number, unit: string) => number,
    invoiceHeaderData?: InvoiceDevisExpress
  ): Promise<void> {
    const id: string = devis.id;

    if (id && id.trim() !== '') {
      await this.updateDevisExpress(
        devis,
        updateLine,
        calcFunc,
        invoiceHeaderData
      );
    } else {
      await this.addDevisExpress(
        devis,
        updateLine,
        calcFunc,
        invoiceHeaderData
      );
    }
  }


  /**
   * Private method to add a new invoice devis express document to Firestore.
   *
   * @param devis - The invoice devis express data to add.
   * @param updateLine - A flag indicating whether to update the lines collection.
   * @param calcFunc - An optional function to calculate the amount based on quantity, unit price, and unit.
   * @param invoiceHeaderData - An optional object containing additional invoice header data to merge with the added data.
   *
   * @returns A Promise that resolves when the add operation is complete.
   * @memberof InvoiceDevisExpressService
   */
  private async addDevisExpress(devis: InvoiceDevisExpress, updateLine: boolean,calcFunc?: (amount: number, unitPrice: number, unit: string) => number,invoiceHeaderData?: InvoiceDevisExpress): Promise<void> {
    const devisToAdd: InvoiceDevisExpress = { ...devis };
    const lines: InvoiceLine[] = devisToAdd.lines || [];

    if (calcFunc && lines.length > 0) {
      const amount: number = lines.reduce(
        (acc: number, line: InvoiceLine) =>
          acc + calcFunc(line.quantity, line.unitPrice, line.unit),
        0
      );
      devisToAdd.amount = amount;
    }
    delete devisToAdd.id;
    delete devisToAdd.lines;

    const docRef: DocumentReference = await this.firestore
      .collection(this.EXPRESS_COLLECTION_NAME)
      .add(devisToAdd);
    if (updateLine) {
      await Promise.all(
        lines.map((line: InvoiceLine) => {
          const lineToUpdate: InvoiceLine = { ...line };
          const lineId: string = lineToUpdate.id
            ? lineToUpdate.id
            : this.createLineId(lines);

          if (!lineId || lineId.trim() === '') {
            throw new Error('Invalid Id.');
          }
          line.id = lineId;
          delete lineToUpdate.id;
          return this.firestore
            .collection(this.EXPRESS_COLLECTION_NAME)
            .doc(docRef.id)
            .collection(this.COLLECTION_NAME_LINE)
            .doc(lineId)
            .set(lineToUpdate, { merge: true });
        })
      );
    }
    if (invoiceHeaderData) {
      await this.firestore
        .collection(this.EXPRESS_COLLECTION_NAME)
        .doc(docRef.id)
        .set(
          {
            ...devisToAdd,
            ...invoiceHeaderData,
          },
          { merge: true }
        );
    }
  }

  /**
   * Private method to update an existing invoice devis express document in Firestore.
   *
   * @param devis - The invoice devis express data to update.
   * @param updateLine - A flag indicating whether to update the lines collection.
   * @param calcFunc - An optional function to calculate the amount based on quantity, unit price, and unit.
   * @param invoiceHeaderData - An optional object containing additional invoice header data to merge with the updated data.
   *
   * @returns A Promise that resolves when the update operation is complete.
   * @memberof InvoiceDevisExpressService
   */
  private async updateDevisExpress(devis: InvoiceDevisExpress, updateLine: boolean,calcFunc?: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number, invoiceHeaderData?: InvoiceDevisExpress): Promise<void> {
    const devisToUpdate: InvoiceDevisExpress = { ...devis };
    const id: string = devisToUpdate.id;
    const lines: InvoiceLine[] = devisToUpdate.lines || [];

    if (calcFunc && lines.length > 0) {
      const amount: number = lines.reduce(
        (acc: number, line: InvoiceDevisExpressLine) =>
          acc + calcFunc(line.quantity, line.unitPrice, line.unit, line.tvaRate ?? '20'),
        0
      );
      devisToUpdate.amount = amount;
    }
    delete devisToUpdate.id;
    delete devisToUpdate.lines;

    await this.firestore
      .collection(this.EXPRESS_COLLECTION_NAME)
      .doc(id)
      .update(devisToUpdate);
    if (updateLine) {
      await Promise.all(
        lines.map((line: InvoiceLine) => {
          const lineToUpdate: InvoiceLine = { ...line };
          const lineId: string = lineToUpdate.id
            ? lineToUpdate.id
            : this.createLineId(lines);

          if (!lineId || lineId.trim() === '') {
            throw new Error('Invalid Id.');
          }

          line.id = lineId;
          delete lineToUpdate.id;
          return this.firestore
            .collection(this.EXPRESS_COLLECTION_NAME)
            .doc(id)
            .collection(this.COLLECTION_NAME_LINE)
            .doc(lineId)
            .set(lineToUpdate, { merge: true });
        })
      );
    }
    if (invoiceHeaderData) {
      await this.firestore
        .collection(this.EXPRESS_COLLECTION_NAME)
        .doc(id)
        .set(
          {
            ...devisToUpdate,
            ...invoiceHeaderData,
          },
          { merge: true }
        );
    }
  }

  /**
   * Deletes an invoice devis express by its ID.
   *
   * @param id - The ID of the invoice devis express to delete.
   *
   * @returns A Promise that resolves when the deletion operation is complete.
   * @memberof InvoiceDevisExpressService
   */
  public deleteDevisExpress(id: string): Promise<void> {
    return this.firestore
      .collection(this.EXPRESS_COLLECTION_NAME)
      .doc(id)
      .delete();
  }

  /**
   * Validates an invoice devis express
   *
   * @param devis - The invoice devis express to validate.
   * @param calcAmount - A function to calculate the amount based on quantity, unit price, and unit.
   *
   * @returns A Promise that resolves when the validation operation is complete.
   * @memberof InvoiceDevisExpressService
   */
  public async validateDevisExpress(
    devis: InvoiceDevisExpress,
    calcAmount: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number
  ): Promise<void> {
    const dueDate: Date = moment(devis.plannedDate)
      .add(devis.due, 'days')
      .toDate();
    const totalHT: number =
      (devis.type === 380 ? 1 : devis.type === 381 ? -1 : 1) *
      devis.lines.reduce(
        (acc: number, line: InvoiceDevisExpressLine) =>
          acc + calcAmount(line.quantity, line.unitPrice, line.unit, line.tvaRate ?? '20'),
        0
      );
    const totalTVA: number = totalHT * (20 / 100); //TODO TVA RATE
    const totalTTC: number = totalHT + totalTVA;
    const tresoInvoice: TresoInvoice = {
      bankAccountId: devis.bankAccount,
      clientId: devis.client,
      codeEcheance: DueValueLabel.find((due) => due.value === devis.due).label,
      dateEcheance: dueDate,
      dateFacturation: devis.plannedDate,
      datePaiement: null,
      devise: 'EUR',
      entityId: devis.entity,
      etat: 'PREVISION',
      expectedPercent: 100,
      libelle: devis.label,
      numFacture: devis.invoiceNumber,
      statusRelance: '',
      totalHT: totalHT,
      totalTTC: totalTTC,
      totalTVA: totalTVA,
      tva: '20.0',
      accountingAccountId: devis.accountingAccount,
    };
    await this.firestore.collection('ventes').add(tresoInvoice);
    const devisToUpdate: InvoiceDevisExpress = { ...devis };
    devisToUpdate.hasBeenSent = true;
    return this.updateDevisExpress(devisToUpdate, true, calcAmount);
  }

  /**
   * Sends an invoice devis express to the specified entity.
   *
   * @param invoice - The invoice devis express to send.
   * @param entity - The entity to send the invoice to.
   * @param facturX - The PDF data of the invoice.
   *
   * @returns A Promise that resolves when the invoice is sent.
   * @memberof invoiceDevisExpressService
   */
  public async sendInvoiceExpress(
    invoice: InvoiceDevisExpress,
    entity: IEntity,
    facturX: Blob
  ): Promise<void> {
    const year: string = moment(invoice.plannedDate).format('YYYY');
    const month: string = moment(invoice.plannedDate).format('MM');
    const url: string = environment.invoiceAPI;
    const formData: FormData = new FormData();
    formData.append('contentStream', facturX, `${invoice.label}.pdf`);
    let header: HttpHeaders = new HttpHeaders({
      'Content-type': 'multipart/form-data',
    });
    header = environment.production
      ? header.append('x-Gateway-APIKey', environment.invoiceToken)
      : header.append('Authorization', `Basic ${environment.invoiceToken}`);
    return firstValueFrom(
      this.bypassInterceptorHttp.post<void>(
        `${url}/sendInvoice?entityName=${entity.name}&year=${year}&month=${month}&fileName=${invoice.label}.pdf`,
        formData,
        { headers: header }
      )
    );
  }

  /**
   * Adds a new project and devis express document to Firestore.
   *
   * @param devis - The devis express data to add.
   * @param lines - The lines of the devis express.
   *
   * @returns A Promise that resolves to the added devis express with its ID.
   * @memberof InvoiceDevisExpressService
   */
  public async addProjectDevisExpress(devis: InvoiceDevisExpress, lines: InvoiceDevisExpressLine[], calcAmount: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number): Promise<string> {
    const projectName: string = devis.label;
    const projectEntity: string = devis.entity;
    const projectClient: string = devis.client;
    const price: number = lines.reduce((acc: number, line: InvoiceDevisExpressLine) => acc + calcAmount(line.quantity, line.unitPrice, line.unit, line.tvaRate ?? '20'), 0);

    const userId: string = await firstValueFrom(this.authService.user$.pipe(
      map((user: IUser) => user.key ?? '')
    ));

    if (!projectName) {
        throw new Error('The label is required for the project');
    }
    try {
        const projectDocRef: DocumentReference = await this.firestore
            .collection(this.COLLECTION_NAME_PROJECT)
            .add({
                name: projectName,
                clientId: projectClient,
                entiteId: projectEntity,
                added: {
                  date: new Date(),
                  by: userId ?? ''
                },
                numBonDeCommande: devis.deliveryNumber ?? '',
                numContract: devis.contractNumber ?? '',
                status: 'Opportunité',
                price
            } as IProjet);

        const devisToAdd: InvoiceDevisExpress = { ...devis };
        delete devisToAdd.id;
        delete devisToAdd.lines;

        const devisDocRef: DocumentReference = await projectDocRef
            .collection(this.COLLECTION_NAME_DEVIS)
            .add(devisToAdd);

        if (lines && lines.length > 0) {
            const promises: Promise<void>[] = lines.map((line: InvoiceLine) => {
                const lineToUpdate: InvoiceLine = { ...line };
                const lineId: string = lineToUpdate.id ? lineToUpdate.id : this.createLineId(lines);
                if (!lineId || lineId.trim() === '') {
                    throw new Error('Invalid Id.');
                }
                line.id = lineId;
                delete lineToUpdate.id;
                return devisDocRef.collection(this.COLLECTION_NAME_LINE).doc(lineId).set(lineToUpdate, { merge: true });
            });
            await Promise.all(promises);
        }
        await this.incrementDevis();
        return projectDocRef.id;
    } catch (error) {
        console.error('Error when adding devis', error);
        throw new Error('Error when adding devis');
    }
  }

  public async updateInvoiceDevisExpress(projectId: string, devis: InvoiceDevisExpress, lines: InvoiceDevisExpressLine[], calcAmount: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number): Promise<unknown> {
    delete devis.lines;
    devis.amount = lines.reduce((acc: number, line: InvoiceDevisExpressLine) => acc + calcAmount(line.quantity, line.unitPrice, line.unit, line.tvaRate ?? '20'), 0);
    return this.firestore.collection(this.COLLECTION_NAME_PROJECT).doc(projectId).collection(this.COLLECTION_NAME_DEVIS).doc(devis.id).update(devis).then(() => {
      if (lines && lines.length > 0) {
        const promises: Promise<void>[] = lines.map((line: InvoiceDevisExpressLine) => {
          const lineToUpdate: InvoiceDevisExpressLine = { ...line };
          const lineId: string = lineToUpdate.id ? lineToUpdate.id : this.createLineId(lines);
          if (!lineId || lineId.trim() === '') {
            throw new Error('Invalid Id.');
          }
          line.id = lineId;
          delete lineToUpdate.id;
          return this.firestore.collection(this.COLLECTION_NAME_PROJECT).doc(projectId).collection(this.COLLECTION_NAME_DEVIS).doc(devis.id).collection(this.COLLECTION_NAME_LINE).doc(lineId).set(lineToUpdate, { merge: true });
        });
        return Promise.all(promises);
      }
    });
  }

  public getDevisExpressByProjectId(projectId: string): Observable<InvoiceDevisExpress> {
    return this.firestore.collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectId)
      .collection(this.COLLECTION_NAME_DEVIS)
      .valueChanges({ idField: 'id'})
      .pipe(
        map((devisArray: InvoiceDevisExpress[]) => devisArray.length > 0 ? {
          ...devisArray[0],
          plannedDate: new firestore.Timestamp((devisArray[0].plannedDate as any).seconds, 0).toDate()
        } as InvoiceDevisExpress : null),
        switchMap((devis: InvoiceDevisExpress) => devis ? this.firestore.collection(this.COLLECTION_NAME_PROJECT).doc(projectId).collection(this.COLLECTION_NAME_DEVIS).doc(devis.id).collection(this.COLLECTION_NAME_LINE).valueChanges({ idField: 'id'}).pipe(
          map((lines: InvoiceDevisExpressLine[]) => ({
            ...devis,
            lines
          })
        )) : of(null))
      );
  }

  public get devisIncrement$(): Observable<number> {
    return this.firestore.collection('settings', (ref) => ref.where('key', '==', 'NUMBER_INVOICE')).get().pipe(
      map((querySnapshot: QuerySnapshot<DocumentData>) => querySnapshot.docs.length > 0 ? querySnapshot.docs[0].data().value : 0)
    );
  }

  public incrementDevis(): Promise<unknown> {
    return firstValueFrom(this.firestore.collection('settings', (ref) => ref.where('key', '==', 'NUMBER_INVOICE')).get().pipe(
      map((querySnapshot: QuerySnapshot<DocumentData>) => querySnapshot.docs.length > 0 ? querySnapshot.docs[0].ref : null),
      switchMap((docRef: DocumentReference) => {
        if (docRef) {
          return docRef.update({ value: firestore.FieldValue.increment(1) });
        } else {
          return this.firestore.collection('settings').add({ key: 'NUMBER_INVOICE', value: 1 });
        }
      })
    ));
  }

  public getInvoiceDevisExpressLinesByProjectId(projectId: string): Observable<InvoiceDevisExpressLine[]> {
    return this.getDevisExpressByProjectId(projectId).pipe(
      map((devis: InvoiceDevisExpress) => devis ? devis.lines : [])
    );
  }
}
