import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBankAccount } from '@core/models/bank.model';
import { BankAccountService } from '@core/services/bank-account.service';
import { ibanValidator } from '@shared/validators/iban.validator';
import { swiftValidator } from '@shared/validators/swift.validator';
import moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-bank',
  templateUrl: './edit-bank.component.html',
  styleUrls: ['./edit-bank.component.scss']
})
export class EditBankComponent implements OnInit, OnDestroy {

  public bankForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    devise: new FormControl('', [Validators.required]),
    iban: new FormControl('', [Validators.required, ibanValidator()]),
    bic: new FormControl('', [Validators.required, swiftValidator()]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('')
  });

  public endDatePicker: MatDatepicker<any>;

  public _accountUpdate: Subscription;
  public _accountDelete: Subscription;

  public devises: string[] = this.bankAccountService.devises;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {bank: IBankAccount, isSupplier: boolean},
  private dialogRef: MatDialogRef<EditBankComponent>,
  private bankAccountService: BankAccountService) { }

  public ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    const isEndDateValid: boolean = moment(this.data.bank.endDate).isSameOrAfter(this.data.bank.startDate, 'date');
    this.bankForm.patchValue({
      name: this.data.bank.name,
      devise: this.data.bank.devise,
      iban: this.data.bank.iban,
      bic: this.data.bank.swift,
      solde: this.data.bank.balance,
      limiteCredit: this.data.bank.creditLimite,
      startDate: this.data.bank.startDate,
      endDate: isEndDateValid ? this.data.bank.endDate : ''
    });
  }

  public ngOnDestroy(): void {
    this._accountDelete && this._accountDelete.unsubscribe();
    this._accountUpdate && this._accountUpdate.unsubscribe();
  }

  public getAccountFromForm(): IBankAccount {
    return {
      id: this.data.bank.id,
      ownerId: this.data.bank.ownerId,
      ownerType: this.data.bank.ownerType,
      name: this.bankForm.get('name').value,
      domiciliation: '',
      devise: this.bankForm.get('devise').value,
      iban: this.bankForm.get('iban').value,
      swift: this.bankForm.get('bic').value,
      balance: 0,
      creditLimite: 0,
      startDate: this.bankForm.get('startDate').value ?? null,
      endDate: this.bankForm.get('endDate').value ?? null
    }
  }

  public onSubmit(): void {
    this._accountUpdate = this.bankAccountService.updateBankAccount(this.getAccountFromForm()).subscribe();
    this.dialogRef.close(false);
  }

  public deleteAccount(): void {
    this._accountDelete = this.bankAccountService.deleteBankAccount(this.data.bank.id).subscribe();
    this.dialogRef.close(true);
  }

  public get ibanControl(): AbstractControl {
    return this.bankForm.get('iban');
  }

  public getIbanErrorMessage(): string {
    if (this.ibanControl.hasError('required')) {
      return 'Vous devez saisir un IBAN';
    } else if (this.ibanControl.hasError('iban')) {
      return this.ibanControl.errors.iban;
    }
  }

  public onIbanChange(): void {
    this.ibanControl.setValue(this.ibanControl.value.toUpperCase(), {emitEvent: false});
  }


  public get bicControl(): AbstractControl {
    return this.bankForm.get('bic');
  }

  public getBicErrorMessage(): string {
    if (this.bicControl.hasError('required')) {
      return 'Vous devez saisir un BIC';
    } else if (this.bicControl.hasError('swift')) {
      return this.bicControl.errors.swift;
    }
  }

  public updateEndDate(): void {
    this.bankForm.get('endDate').setValue(null);
  }

  public get minDate(): Date {
    return this.bankForm.get('startDate').value ?? new Date();
  }

}
