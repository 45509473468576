import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { IBankAccount } from '@core/models/bank.model';
import { IClient } from '@core/models/client.models';
import { IEntity } from '@core/models/entity.model';
import { IFacturX } from '@core/models/facture.model';
import {
  Invoice,
  InvoiceDevisExpressLine,
  InvoiceLine,
  TresoInvoice
} from '@core/models/invoice.model';
import { IProjet } from '@core/models/projet.models';
import { firestore } from 'firebase';
import * as Handlebars from 'handlebars';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFont from 'pdfmake/build/vfs_fonts';
import { Observable, firstValueFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { getInvoiceTemplate } from 'src/assets/pdfTemplates/invoiceTemplate';

import { DueValueLabel } from '@pages/projects/components/invoice/invoice-header/invoice-header.component';
import moment from 'moment';
import { environment } from 'src/environments/environment';

// (<any>pdfMake).addVirtualFileSystem(pdfFonts);

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private readonly COLLECTION_NAME = 'invoices';
  private readonly OLD_COLLECTION_NAME = 'facture';
  private readonly COLLECTION_NAME_PROJECT = 'projects';
  private readonly COLLECTION_NAME_LINE = 'lines';
  private readonly EXPRESS_COLLECTION_NAME = 'devis-express';
  private readonly COLLECTION_NAME_DEVIS = 'devis';

  private bypassInterceptorHttp: HttpClient = new HttpClient(this.httpBackend);

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly http: HttpClient,
    private readonly httpBackend: HttpBackend
  ) {}

  public getInvoices(projectKey: string): Observable<Invoice[]> {
    return this.firestore
      .collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectKey)
      .collection<Invoice>(this.COLLECTION_NAME)
      .get()
      .pipe(
        map((invoices: QuerySnapshot<Invoice>) =>
          invoices.docs.map((invoice: QueryDocumentSnapshot<Invoice>) => ({
            ...invoice.data(),
            plannedDate: new firestore.Timestamp(
              (invoice.data().plannedDate as any).seconds,
              0
            ).toDate(),
            id: invoice.id,
          }))
        )
      );
  }

  public getInvoiceById(
    projectKey: string,
    invoiceKey: string
  ): Observable<Invoice> {
    return this.firestore
      .collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectKey)
      .collection<Invoice>(this.COLLECTION_NAME)
      .doc<Invoice>(invoiceKey)
      .get()
      .pipe(
        map((result: DocumentSnapshot<DocumentData>) => ({
          ...result.data(),
          plannedDate: new firestore.Timestamp(
            (result.data().plannedDate as any).seconds,
            0
          ).toDate(),
          id: result.id,
        })),
        switchMap((invoice: Invoice) =>
          this.firestore
            .collection(this.COLLECTION_NAME_PROJECT)
            .doc(projectKey)
            .collection<Invoice>(this.COLLECTION_NAME)
            .doc<Invoice>(invoiceKey)
            .collection<InvoiceLine>(this.COLLECTION_NAME_LINE)
            .get()
            .pipe(
              map((lines: QuerySnapshot<InvoiceLine>) => ({
                ...invoice,
                lines: lines.docs.map(
                  (line: QueryDocumentSnapshot<InvoiceLine>) => ({
                    ...line.data(),
                    id: line.id,
                  })
                ),
              }))
            )
        )
      );
  }

  public getOldInvoices(projectKey: string): Observable<Invoice[]> {
    return this.firestore
      .collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectKey)
      .collection(this.OLD_COLLECTION_NAME)
      .get()
      .pipe(
        map((invoices: QuerySnapshot<DocumentData>) =>
          invoices.docs.map(
            (invoice) =>
              ({
                id: invoice.id,
                amount: invoice.data().amountHT,
                hasBeenSent: invoice.data().issuedFacture,
                invoiceNumber: invoice.data().numberFacture,
                plannedDate: new firestore.Timestamp(
                  (invoice.data().planningForecast as any).seconds,
                  0
                ).toDate(),
                dueLabel: invoice.data().typeFacture,
              } as Invoice)
          )
        )
      );
  }

  public async validateInvoice(
    projectKey: string,
    invoice: Invoice,
    calcAmount: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number
  ): Promise<void> {
    const dueDate: Date = moment(invoice.plannedDate)
      .add(invoice.due, 'days')
      .toDate();
    const totalHT: number =
      (invoice.type === 380 ? 1 : invoice.type === 381 ? -1 : 1) *
      invoice.lines.reduce(
        (acc: number, line: InvoiceLine) =>
          acc + calcAmount(line.quantity, line.unitPrice, line.unit, line.tvaRate),
        0
      );
    const totalTVA: number = totalHT * (invoice.tvaRate / 100);
    const totalTTC: number = totalHT + totalTVA;
    const tresoInvoice: TresoInvoice = {
      bankAccountId: invoice.bankAccount,
      clientId: invoice.client,
      codeEcheance: DueValueLabel.find((due) => due.value === invoice.due)
        .label,
      dateEcheance: dueDate,
      dateFacturation: invoice.plannedDate,
      datePaiement: null,
      devise: 'EUR',
      entityId: invoice.entity,
      etat: 'PREVISION',
      expectedPercent: 100,
      libelle: invoice.label,
      numFacture: invoice.invoiceNumber,
      statusRelance: '',
      totalHT: totalHT,
      totalTTC: totalTTC,
      totalTVA: totalTVA,
      tva: invoice.tvaRate.toFixed(1),
      accountingAccountId: invoice.accountingAccount,
    };
    await this.firestore.collection('ventes').add(tresoInvoice);
    const invoiceToUpdate: Invoice = { ...invoice };
    invoiceToUpdate.hasBeenSent = true;
    return this.updateInvoice(projectKey, invoiceToUpdate, true, calcAmount);
  }

  public async addInvoice(
    projectKey: string,
    invoice: Invoice
  ): Promise<Invoice> {
    const lines: InvoiceLine[] = invoice.lines || [];
    delete invoice.id;
    delete invoice.lines;
    const docRef: DocumentReference = await this.firestore
      .collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectKey)
      .collection(this.COLLECTION_NAME)
      .add(invoice);
    await Promise.all(
      lines.map((line: InvoiceLine) => {
        const lineToUpdate: InvoiceLine = { ...line };
        const lineId: string = lineToUpdate.id
          ? lineToUpdate.id
          : this.createLineId(lines);
        delete lineToUpdate.id;
        return this.firestore
          .collection(this.COLLECTION_NAME_PROJECT)
          .doc(projectKey)
          .collection(this.COLLECTION_NAME)
          .doc(docRef.id)
          .collection(this.COLLECTION_NAME_LINE)
          .doc(lineId)
          .set(lineToUpdate, { merge: true });
      })
    );
    return {
      ...invoice,
      id: docRef.id,
    };
  }

  public async sendInvoice(
    invoice: Invoice,
    entity: IEntity,
    facturX: Blob
  ): Promise<void> {
    const year: string = moment(invoice.plannedDate).format('YYYY');
    const month: string = moment(invoice.plannedDate).format('MM');
    const url: string = environment.invoiceAPI;
    const formData: FormData = new FormData();
    formData.append('contentStream', facturX, `${invoice.label}.pdf`);
    let header: HttpHeaders = new HttpHeaders({
      'Content-type': 'multipart/form-data',
    });
    header = environment.production
      ? header.append('x-Gateway-APIKey', environment.invoiceToken)
      : header.append('Authorization', `Basic ${environment.invoiceToken}`);
    return firstValueFrom(
      this.bypassInterceptorHttp.post<void>(
        `${url}/sendInvoice?entityName=${entity.name}&year=${year}&month=${month}&fileName=${invoice.label}.pdf`,
        formData,
        { headers: header }
      )
    );
  }

  public async updateInvoice(
    projectKey: string,
    invoice: Invoice,
    updateLine: boolean,
    calcFunc?: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number
  ): Promise<void> {
    const invoiceToUpdate: Invoice = { ...invoice };
    const id: string = invoiceToUpdate.id;
    const lines: InvoiceLine[] = invoiceToUpdate.lines || [];
    if (calcFunc && lines.length > 0) {
      const amount: number = lines.reduce(
        (acc: number, line: InvoiceLine) =>
          acc + calcFunc(line.quantity, line.unitPrice, line.unit, line.tvaRate),
        0
      );
      invoiceToUpdate.amount = amount;
    }
    delete invoiceToUpdate.id;
    delete invoiceToUpdate.lines;
    return this.firestore
      .collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectKey)
      .collection(this.COLLECTION_NAME)
      .doc(id)
      .update(invoiceToUpdate)
      .then(async () => {
        updateLine
          ? await Promise.all(
              lines.map((line: InvoiceLine) => {
                const lineToUpdate: InvoiceLine = { ...line };
                const lineId: string = lineToUpdate.id
                  ? lineToUpdate.id
                  : this.createLineId(lines);
                line.id = lineId;
                delete lineToUpdate.id;
                return this.firestore
                  .collection(this.COLLECTION_NAME_PROJECT)
                  .doc(projectKey)
                  .collection(this.COLLECTION_NAME)
                  .doc(id)
                  .collection(this.COLLECTION_NAME_LINE)
                  .doc(lineId)
                  .set(lineToUpdate, { merge: true });
              })
            )
          : Promise.resolve();
        return Promise.resolve();
      });
  }

  public async deleteInvoice(
    projectKey: string,
    invoiceKey: string
  ): Promise<void> {
    const linesId: string[] = await firstValueFrom(
      this.firestore
        .collection(this.COLLECTION_NAME_PROJECT)
        .doc(projectKey)
        .collection(this.COLLECTION_NAME)
        .doc(invoiceKey)
        .collection(this.COLLECTION_NAME_LINE)
        .get()
        .pipe(
          map((lines: QuerySnapshot<InvoiceLine>) =>
            lines.docs.map(
              (line: QueryDocumentSnapshot<InvoiceLine>) => line.id
            )
          )
        )
    );
    await Promise.all(
      linesId.map((lineId: string) =>
        this.firestore
          .collection(this.COLLECTION_NAME_PROJECT)
          .doc(projectKey)
          .collection(this.COLLECTION_NAME)
          .doc(invoiceKey)
          .collection(this.COLLECTION_NAME_LINE)
          .doc(lineId)
          .delete()
      )
    );
    return this.firestore
      .collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectKey)
      .collection(this.COLLECTION_NAME)
      .doc(invoiceKey)
      .delete();
  }

  /**
   * Generates a unique line ID for the invoice lines.
   *
   * @param lines - The array of invoice lines.
   * @returns The generated line ID as a string.
   */
  private createLineId(lines: InvoiceLine[]): string {
    // Find the max id + 1
    const ids: number[] = lines
      .filter((line) => line.id)
      .map((line: InvoiceLine) => parseInt(line.id, 10));

    if (ids.length === 0) {
      return '0';
    }

    const maxId: number = Math.max(...ids);
    return (maxId + 1).toString();
  }

  public deleteLine(
    projectKey: string,
    invoiceKey: string,
    lineKey: string
  ): Promise<void> {
    return this.firestore
      .collection(this.COLLECTION_NAME_PROJECT)
      .doc(projectKey)
      .collection(this.COLLECTION_NAME)
      .doc(invoiceKey)
      .collection(this.COLLECTION_NAME_LINE)
      .doc(lineKey)
      .delete();
  }

  public createPdf(
    invoice: Invoice,
    project: IProjet,
    client: IClient,
    entity: IEntity,
    account: IBankAccount,
    entityLogo: string,
    amountFunc: (amount: number, unitPrice: number, unit: string, tvaRate: string) => number
  ): Promise<any> {
    return getInvoiceTemplate(
      invoice,
      project,
      client,
      entity,
      account,
      entityLogo,
      amountFunc
    );
  }

  public generatePdf(pdfData: any): any {
    return pdfMake.createPdf(pdfData, null, null, pdfFont.pdfMake.vfs);
  }

  public generateFacturXml(facturx: IFacturX): Observable<string> {
    return this.http
      .get('assets/handlebar/factur-x.xml', {
        responseType: 'text',
      })
      .pipe(
        map((xmlString: string) => {
          const template = Handlebars.compile(xmlString);
          return template({ ...facturx });
        })
      );
  }

  public isInvoiceAlreadyValidated(invoiceNumber: string): Observable<boolean> {
    return this.firestore
      .collection('ventes', (ref) =>
        ref.where('numFacture', '==', invoiceNumber)
      )
      .get()
      .pipe(
        map((result: QuerySnapshot<TresoInvoice>) => result.docs.length > 0)
      );
  }

  public isInvoiceNumberAlreadyUsed(
    invoiceNumber: string
  ): Observable<boolean> {
    return this.firestore
      .collection('ventes', (ref) =>
        ref.where('numFacture', '==', invoiceNumber)
      )
      .get()
      .pipe(
        map((result: QuerySnapshot<TresoInvoice>) => result.docs.length > 0)
      );
  }

  public deleteDevisLine(invoiceKey: string, lineKey: string): Promise<void> {
    return this.firestore
      .collection(this.EXPRESS_COLLECTION_NAME)
      .doc(invoiceKey)
      .collection(this.COLLECTION_NAME_LINE)
      .doc(lineKey)
      .delete();
  }
}
