import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBankAccount } from '@core/models/bank.model';
import { BankAccountService } from '@core/services/bank-account.service';
import { ibanValidator } from '@shared/validators/iban.validator';
import { swiftValidator } from '@shared/validators/swift.validator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit {

  public bankForm: FormGroup;

  public startDatepicker: MatDatepicker<any>;
  public endDatepicker: MatDatepicker<any>;

  public _accountAdd: Subscription;

  public devises: string[] = this.bankAccountService.devises;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {ownerId:string, ownerType:string, isSupplier: boolean},
  private dialogRef: MatDialogRef<AddBankComponent>,
  private bankAccountService: BankAccountService) { }

  public ngOnInit(): void {
    this.initForm();
  }

  public initForm(): void {
    this.bankForm = new FormGroup({
      name: new FormControl('',[Validators.required]),
      // domiciliation: new FormControl('', [Validators.required]),
      devise: new FormControl('', [Validators.required]),
      iban: new FormControl('', [Validators.required, ibanValidator()]),
      bic: new FormControl('', [Validators.required, swiftValidator()]),
      solde: new FormControl(0, this.data.isSupplier ? [Validators.nullValidator] :[Validators.required]),
      limiteCredit: new FormControl(0, this.data.isSupplier ? [Validators.nullValidator] :[Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl({value: '', disabled: true}, [Validators.nullValidator])
    })
  }

  public ngOnDestroy(): void {
    this._accountAdd ? this._accountAdd.unsubscribe() : null;
  }

  public getAccountFromForm(): IBankAccount {
    return {
      balance: 0,
      creditLimite: 0,
      devise: this.bankForm.get('devise').value,
      endDate: this.bankForm.get('endDate').value ?? null,
      iban: this.bankForm.get('iban').value,
      name: this.bankForm.get('name').value,
      domiciliation: '',
      startDate: this.bankForm.get('startDate').value,
      swift: this.bankForm.get('bic').value,
      ownerId: this.data.ownerId,
      ownerType: this.data.ownerType
    }
  }

  public onSubmit(): void {
    this._accountAdd = this.bankAccountService.addBankAccount(this.getAccountFromForm()).subscribe((account) => {
      this.dialogRef.close(account);
    });
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }

  public get ibanControl(): AbstractControl {
    return this.bankForm.get('iban');
  }
  
  public getIbanErrorMessage(): string {
    if (this.ibanControl.hasError('required')) {
      return 'Veuillez entrer un IBAN';
    } else if (this.ibanControl.hasError('iban')) {
      return this.ibanControl.errors.iban;
    }
  }

  public onIbanChange(): void {
    this.ibanControl.setValue(this.ibanControl.value.toUpperCase(), {emitEvent: false});
  }

  public get bicControl(): AbstractControl {
    return this.bankForm.get('bic');
  }

  public getBicErrorMessage(): string {
    if (this.bicControl.hasError('required')) {
      return 'Veuillez entrer un BIC';
    } else if (this.bicControl.hasError('swift')) {
      return this.bicControl.errors.swift;
    }
  }

  public updateEndDate(): void {
    this.bankForm.get('endDate')?.enable();
    this.bankForm.get('endDate')?.setValue(null);
  }

  public get minDate(): Date {
    return this.bankForm.get('startDate')?.value ?? new Date();
  }


}
