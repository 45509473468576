import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AngularFirestore, DocumentChangeAction, DocumentData, QuerySnapshot } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IService } from '@core/models/service.models';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  public services: IService[] = [];
  ServicesChange = new BehaviorSubject<IService[]>(this.services);
  public serviceSubscribe;
  public shoppedServiceSubscribe : Subscription;
  public shoppedServices: IService[];

  constructor( private db: AngularFirestore, private snackBar: MatSnackBar) { }

  init() {
    this.cancelSubscribe();
    this.serviceSubscribe = this.db.collection('services').snapshotChanges().pipe(
      map((actions: DocumentChangeAction<unknown>[]) => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const key = a.payload.doc.id;
        if (data && key) {
          data.key = key;
        }
        return { key, ...data };
      }))
    ).subscribe((data) => {
      this.services = data;
      this.ServicesChange.next(this.services);
    }, (error) => {
      this.snackBar.open(error, 'x', {
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'end',
        panelClass: ['red-snackbar']
      });
    });
  }

  serviceForProject(projectId: string) {
    this.shoppedServiceSubscribe = this.db.collection('shoppedServices',
      ref => ref.where('projectID', '==', projectId).orderBy('order', 'asc'))
      .get().pipe(map((snapshot: QuerySnapshot<DocumentData>) => snapshot.docs.map(this.mapService))
      ).subscribe((data) => {
        this.shoppedServices = data;
      }, (error) => {
        console.log('serviceForProject error', error);
        this.snackBar.open(error, 'x', {
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['red-snackbar']
        });
      });
  }

  mapService(snasphot: firebase.firestore.QueryDocumentSnapshot): IService {
    const key = snasphot.id;
    const data = snasphot.data();
    return { key, ...data } as any;
  }
  cancelSubscribe() {
    if (this.serviceSubscribe) {
      this.serviceSubscribe.unsubscribe();
    }
  }
  cancelShoppedService(){
    if(this.shoppedServiceSubscribe){
      this.shoppedServiceSubscribe.unsubscribe();
    }
  }

  getMontantTotalShoppedServices()  {
    if (this.shoppedServices) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      const services = this.shoppedServices.filter(service => service.articles && service.articles.length > 0);
      let price=  (services && services.length > 0 ? services.map(service =>
        service.articles.map(article => article.amount * article.cost).reduce(reducer)).reduce(reducer) : 0);
        return price;
    }
    return null;
  }

  public getProjectShoppedServices(projectId: string): Observable<IService> {
    return this.db.collection<IService>('shoppedServices', ref => ref.where('projectID', '==', projectId)).get().pipe(
      map((snapshot: QuerySnapshot<DocumentData>) => snapshot.docs.length === 1  ? ({
        ...snapshot.docs[0].data(),
        key: snapshot.docs[0].id
      } as IService) : {
        articles: [],
        projectID: projectId
      } as IService)
    )
  }
}
