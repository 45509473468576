import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IContact } from '@core/models/contacts.model';
import { ContactsService } from '@core/services/contacts.service';
import { phoneValidator } from '@shared/validators/phone.validator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-contacts',
  templateUrl: './add-contacts.component.html',
  styleUrls: ['./add-contacts.component.scss']
})
export class AddContactsComponent implements OnInit, OnDestroy {

  public contactForm: FormGroup;

  public contact: IContact;
  public types: string[] = this.contactService.contactType;

  public _contactAdd: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {parent:string, parentType:string},
    private contactService: ContactsService,
    private dialogRef: MatDialogRef<AddContactsComponent>) { }

  public ngOnInit(): void {
    this.contact = {
      parentId: this.data.parent,
      parent: this.data.parentType,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      actif: true,
      type: ''
    }
    this.initForm();
  }

  public initForm(): void {
    this.contactForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.nullValidator, phoneValidator()]),
      email: new FormControl('', [Validators.nullValidator, Validators.email])
    })
  }

  public ngOnDestroy(): void {
    this._contactAdd && this._contactAdd.unsubscribe();
  }

  public getContactFromForm(): IContact {
    return {
      actif: true,
      parentId: this.data.parent,
      parent: this.data.parentType,
      firstName: this.contactForm.get('firstName').value,
      lastName: this.contactForm.get('lastName').value,
      email: this.contactForm.get('email').value,
      phone: this.contactForm.get('phone').value,
      type: this.contactForm.get('type').value
    }
  }

  public onSubmit(): void {
    this._contactAdd = this.contactService.addContacts(this.getContactFromForm()).subscribe((contact) => this.dialogRef.close(contact));
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }

  public get mailControl(): AbstractControl {
    return this.contactForm.get('email');
  }

  public get phoneControl(): AbstractControl {
    return this.contactForm.get('phone');
  }

  public getMailErrorMessage(): string {
    if (this.mailControl.hasError('email')) return 'Format de mail invalide (exemple@gmail.com)';
  }

  public getPhoneErrorMessage(): string {
    if (this.phoneControl.hasError('phone')) return this.phoneControl.errors.phone;
  }

}
