import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IChapter } from '@core/models/chapters.models';

export interface CopyChapterDialogData {
  chaptersTemplates: IChapter[]
}

@Component({
  selector: 'app-copy-chapter',
  templateUrl: './copy-chapter.component.html',
  styleUrls: ['./copy-chapter.component.scss']
})
export class CopyChapterComponent implements OnInit {
  public selectedChapters: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<CopyChapterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CopyChapterDialogData
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
