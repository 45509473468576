import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { ThemeService } from '@core/services/theme.service';
import { SidenavComponent } from '../sidenav/sidenav.component';
import { User } from 'firebase';
import { IUpdated } from '@core/models/intreface.models';
import { IUser } from '@core/models/users.models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() sideNav : SidenavComponent;

  public user: IUser = null;

  constructor(public authService: AuthService, public themeService: ThemeService) { }

  ngOnInit() {
    this.authService.user$.subscribe(user => this.user = user);
  }

  sideNavToggle(){
    this.sideNav.sidenav.toggle();
  }

  public getDisplayName(): string {
    if (!this.user) return '';
    const name: string = this.user.firstName && this.user.lastName ? `${this.user.firstName} ${this.user.lastName}` : null;
    return name ? name : this.user.mail.substring(0, this.user.mail.indexOf('@'));
    
  }


}
