<div *ngIf="projectsService.currentProject" [ngSwitch]="projectsService.currentProject.validate">
  <div *ngSwitchCase="0">
    <div fxLayout="row">
      <div class="container" fxFlex>
        <div fxLayout="row" fxLayoutAlign="center center">
          <h1>Catalogue des services</h1>
        </div>
        <app-shop-services></app-shop-services>
      </div>
      <div class="container" fxFlex>
        <div fxLayout="column" fxLayoutAlign="center none">
          <h1>Services du projet</h1>
          <app-services-project (sendCurrentService)="setCurrentService($event)" (updateProject)="updateProjectPrice()"
            [projet]="projectsService.currentProject" [shoppedServices]="serviceService.shoppedServices" [multi]="true">
          </app-services-project>
        </div>
      </div>
    </div>
  </div>
  <p class="projectValidate" *ngSwitchCase="1">Le projet est en cours de validation et ne peut donc plus être
    modifié.</p>
  <p class="projectValidate" *ngSwitchCase="2">Le projet est déjà validé et ne peut donc plus être modifié.</p>
</div>
