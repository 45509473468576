import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Action, AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { ClientsService } from './clients.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { ServicesService } from './services.service';
import { IUser } from '@core/models/users.models';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import firebase from 'firebase';
import { ThrowStmt } from '@angular/compiler';

export function authFactory(provider: AuthService) {
  return () => environment.connectFrom === 'LDAP' ? provider.loadAuth() : provider.loadAuthFromFirebase();
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: IUser;
  public loading = true;
  secondaryApp: any;
  public userFB: firebase.User;
  firstCookieLog: boolean = true;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private db: AngularFirestore,
    private clientsService: ClientsService,
    private servicesService: ServicesService,
    private http: HttpClient,
    private userService :UserService) {
  }

  public loadAuth() {
    return new Promise<void>(async (resolve, reject) => {
      this.afAuth.onAuthStateChanged(
        async (user) => {
          if (user) {
            this.userFB = user;
            await this.db.doc('/users/' + this.userFB.uid).snapshotChanges().pipe(
              map((action: Action<DocumentSnapshot<unknown>>) => {
                return { key: action.payload.id, ...(action.payload as any).data() } as IUser
              })
            ).subscribe((content) => {
              this.user = content;
              this.clientsService.init();
              this.servicesService.init();
              this.userService.init();
              resolve();
            }, (error) => {
              if (this.user) {
                this.user = null;
                reject();
              }
            });
          } else {
            this.user = null;
            const cookieTab = document.cookie.match('(^|;)\\s*' + "token" + '\\s*=\\s*([^;]+)');
            if (cookieTab) {
              this.logFromCookie().then((res) => {
                if (res) {
                  resolve();
                } else {
                  reject();
                }
              });
            }
          }
          this.loading = false;
        }
      );
      const cookieTab = document.cookie.match('(^|;)\\s*' + "token" + '\\s*=\\s*([^;]+)');
      if (!cookieTab) {
        this.signOutUser();
      }
    });
  }

  public loadAuthFromFirebase() {
    this.clientsService.init();
    this.servicesService.init();
    this.userService.init();
    return;
  }

  public signInWithToken(token: string): Promise<boolean | void> {
    return this.afAuth.signInWithCustomToken(token).then(() => {
      document.cookie = `token =${JSON.stringify(token)};path=/;domain=${environment.mainDomain};`;
      return this.router.navigate(['/']);
    }).catch(() => this.signOutUser())
  }

  public logFromCookie() {
    return new Promise<boolean>((resolve, reject) => {
      try {
        this.firstCookieLog = false;
        const cookieTab = document.cookie.match('(^|;)\\s*' + "token" + '\\s*=\\s*([^;]+)');
        const cookie = cookieTab ? cookieTab.pop() : null;
        const tokens = JSON.parse(cookie);
        this.afAuth.signInWithCustomToken(tokens.token).then(() => {
          resolve(true);
        }).catch((error) => {
          this.signOutUser();
          resolve(false);
        }
        );
      } catch (err) {
        this.signOutUser();
        resolve(false);
      }
    });
  }

  public getUser() {
    return new Promise((resolve, reject) => {
      const test = this.db.doc('/users/' + this.userFB.uid).snapshotChanges().pipe(
        map((action: Action<DocumentSnapshot<unknown>>) => {
          return action.payload.data() as any;
        })
      ).subscribe((content) => {
        resolve(content);
        test.unsubscribe();
      }, (error) => {
        resolve(null);
        test.unsubscribe();
      });
    });
  }

  public createNewUser(email: string, password: string) {
    return new Promise<void>(
      (resolve, reject) => {
        // this.secondaryApp.auth().createUserWithEmailAndPassword(email, password).then(
        this.afAuth.createUserWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  public signInUser(email: string, password: string) {
    return new Promise<void>(
      (resolve, reject) => {
        this.afAuth.signInWithEmailAndPassword(email, password).then(
          () => {
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
      }
    );
  }

  public get user$(): Observable<IUser> {
    return this.afAuth.user.pipe(
      switchMap((user: firebase.User) => {
        return user ? this.db.doc('/users/' + user.uid).snapshotChanges().pipe(
          map((action: Action<DocumentSnapshot<unknown>>) => {
            return { key: action.payload.id, ...(action.payload as any).data() } as IUser
          })
        ) : null;
      })
    )
  }

  public signOutUser() {
    this.unsubscribeAll();
    return this.afAuth.signOut().then(() => {
      document.cookie = `token =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${environment.mainDomain};`;
      let url: string = `${environment.nodeProtocol}://${environment.portailURL}/auth/signin?connectFromApp=true`;
      if (environment.connectFrom === 'FIREBASE') {
        const encodedUrl = btoa(window.location.href);
        url += `&redirect=${encodedUrl}`;
      }
      window.location.href = url;
    });
  }

  unsubscribeAll() {
    this.clientsService.cancelSubscribe();
    this.servicesService.cancelSubscribe();
    this.userService.cancelSubscribe();
  }
}
