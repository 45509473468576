import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFacturX } from '@core/models/facture.model';
import * as Handlebars from 'handlebars';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FacturXService {

  constructor(
    private readonly http: HttpClient
  ) { }


  public generateFacturXml(facturx: IFacturX): Observable<string> {
    return this.http.get('assets/handlebar/factur-x.xml', {
      'responseType': 'text'
    }).pipe(
      map((xmlString: string) => {
        const template = Handlebars.compile(xmlString);
        return template({...facturx});
      })
    )
  }

  public getFacturXInvoice(pdfData: string, xmlData: string): Observable<Blob> {
    const formData = new FormData();
    formData.append('pdf', new Blob([pdfData], {
      type: 'application/pdf'
    }),'invoice.pdf');
    formData.append('xml', new Blob([xmlData], {
      type: 'application/xml'
    }), 'factur-x.xml');
    return this.http.post(`/facturx-server/facturx/create`, formData, {
      responseType: 'blob'
    }).pipe(
      catchError((error) => {
        console.error(error);
        throw error;
      }),
      map((response: Blob) => new Blob([response], { type: 'application/xhtml+xml' })),
    );
  }


}
