import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SelectServicesComponent } from './components/select-services/select-services.component';

const routes: Routes = [{path: '', component: SelectServicesComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelectServicesRoutingModule { }
