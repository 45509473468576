import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddClientComponent } from '@pages/clients/modals/add-client/add-client.component';
import { FilterClientsComponent } from '@pages/clients/modals/filter-clients/filter-clients.component';
import { AddArticlesComponent } from '@pages/gest-services/modals/add-articles/add-articles.component';
import { AddServicesComponent } from '@pages/gest-services/modals/add-services/add-services.component';
import { AddProjectComponent } from '@pages/projects/modals/add-project/add-project.component';
import { CopyProjectsComponent } from '@pages/projects/modals/copy-projects/copy-projects.component';
import { DeleteProjectComponent } from '@pages/projects/modals/delete-project/delete-project.component';
import { UpdateProjectComponent } from '@pages/projects/modals/update-project/update-project.component';
import { QuillModule } from 'ngx-quill';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { BanksModule } from './banks/banks.module';
import { ResearchClientComponent } from './client/modal/research-client/research-client.component';
import { ContactsModule } from './contacts/contacts.module';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { DueValuePipe } from './pipes/dueValue.pipe';
import { InvoiceTypeValuePipe } from './pipes/invoiceTypeValue.pipe';
import { NumberFormatPipe } from './pipes/numberFormat.pipe';
import { PaymentMeanPipe } from './pipes/paymentMean.pipe';
import { SocietyPipe } from './pipes/society.pipe';
import { TableNullValuePipe } from './pipes/table-null-value.pipe';
import { TvaValuePipe } from './pipes/tvaValue.pipe';
import { UnitLabelPipe } from './pipes/unitLabel.pipe';
import { SocietyModule } from './society/society.module';


const MODULES = [
  MatIconModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  FormsModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatInputModule,
  FlexLayoutModule,
  MatButtonModule,
  MatCardModule,
  MatMenuModule,
  MatSortModule,
  MatListModule,
  DragDropModule,
  MatSlideToggleModule,
  MatTooltipModule,
  QuillModule,
  MatStepperModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatNativeDateModule,
  SelectDropDownModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatRadioModule,
]

@NgModule({
  declarations: [
    FilterClientsComponent,
    AddClientComponent,
    SocietyPipe,
    DueValuePipe,
    NumberFormatPipe,
    InvoiceTypeValuePipe,
    PaymentMeanPipe,
    UnitLabelPipe,
    TvaValuePipe,
    ErrorModalComponent,
    ResearchClientComponent,
    TableNullValuePipe,
  ],
  imports: [
    CommonModule,
    QuillModule.forRoot(),
    ...MODULES
  ],
  exports: [
    CommonModule,
    QuillModule,
    SocietyPipe,
    DueValuePipe,
    NumberFormatPipe,
    BanksModule,
    ContactsModule,
    SocietyModule,
    InvoiceTypeValuePipe,
    PaymentMeanPipe,
    UnitLabelPipe,
    TvaValuePipe,
    ErrorModalComponent,
    TableNullValuePipe,
    ...MODULES
  ],
  entryComponents: [
    FilterClientsComponent, AddServicesComponent, AddArticlesComponent, AddClientComponent, CopyProjectsComponent, AddProjectComponent, DeleteProjectComponent, UpdateProjectComponent,
  ]
})
export class SharedModule { }
