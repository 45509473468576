<div class="header">
  <button class="return" mat-flat-button color="primary" (click)="return()">Retour</button>
  <app-devis-invoice-header #appDevisInvoiceHeader [invoice]="invoice" (selectedEntity)="selectedEntity=$event" (selectedBankAccount)="selectedBankAccount=$event"></app-devis-invoice-header>
</div>
<div class="content">
  <app-devis-invoice-lines-card
    [invoice]="invoice"
    [service]="service"
    [project]="project"
    (validate)="onValidate()"
    (generatePdf)="generatePdf()"
    (save)="saveInvoice()"
    ></app-devis-invoice-lines-card>
    <div class="preview">
      <app-devis-invoice-preview [dataUrl]="dataUrl" (generateInvoice)="generatePdfWithFacturx()"></app-devis-invoice-preview>
    </div>
</div>
