<h1>
  {{ !update ? "Création de la facture" : "Modification de la facture"}}
</h1>

<div class="box">

  <div>
    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Type Facture</mat-label>
      <input matInput required type="text" [(ngModel)]="projectFacture.typeFacture" autocomplete="off">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Numéro Facture</mat-label>
      <input matInput type="text" [(ngModel)]="projectFacture.numberFacture" autocomplete="off">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Pourcentage</mat-label>
      <input matInput [disabled]="(projectFacture.amountHT != 0 && projectFacture.amountHT != null) && this.pourcentage" id="pourcentage" type="number" min="0" max="100" [(ngModel)]="projectFacture.pourcent" autocomplete="off">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Montant (max : {{ projectAmount }}€)</mat-label>
      <input matInput [disabled]="(projectFacture.pourcent != 0 && projectFacture.pourcent != null) && this.montant" id="montant" type="number" [(ngModel)]="projectFacture.amountHT" autocomplete="off">
    </mat-form-field>
  </div>

  <div>
    <mat-checkbox class="checkbox" [(ngModel)]="projectFacture.issuedFacture" [color]="checkboxColor"> Facture Emise</mat-checkbox>
  </div>

  <div>
    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>Planning Prévisionnel</mat-label>
      <input matInput required [matDatepicker]="picker" [value]="projectFacture.planningForecast" (dateInput)="dateChange($event)" (dateChange)="dateChange($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker [ngModel]="projectFacture.planningForecast" #picker disabled="false" ngDefaultControl></mat-datepicker>
    </mat-form-field>
  </div>

  <div>
    <button mat-raised-button class="marg" (click)="cancel()">Annuler</button>
  </div>

  <div>
    <button mat-raised-button class="marg" (click)="onSubmit()">
    {{ !update ? "Créer la facture" : "Modifier la facture"}}
  </button>
</div>

</div>
