<form [formGroup]="clientForm" class="row">
  <div class="column">
    <div class="entete">
      <mat-icon aria-hidden="false">home</mat-icon><span>Societe</span>
    </div>
    <div class="row">
      <div class="column">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Raison Sociale</mat-label>
          <input matInput formControlName="name" autocomplete="off">
        </mat-form-field>

        

        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Nom</mat-label>
          <input matInput formControlName="lastName" autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Prénom</mat-label>
          <input matInput formControlName="firstName" autocomplete="off">
        </mat-form-field>

        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Numéro SIRET</mat-label>
          <input matInput formControlName="siret" autocomplete="off">
          <mat-error *ngIf="siretControl.invalid">{{ getSiretErrorMessage() }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Numéro Intracommunautaire</mat-label>
          <input matInput formControlName="vatNumber" autocomplete="off">
          <mat-error *ngIf="vatNumberControl.invalid">{{ getVatNumberErrorMessage() }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Date de règlement</mat-label>
          <mat-select formControlName="dueTerm" required>
            <mat-option *ngFor="let echeance of echeances" [value]="echeance.value">{{echeance.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Taux de TVA</mat-label>
          <mat-select formControlName="tvaRate">
            <mat-option *ngFor="let tva of tvaRates" [value]="tva.value">{{tva.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Compte comptable par défaut</mat-label>
          <input #input matInput formControlName="defaultAccountingAccount" [matAutocomplete]="accountingAuto" autocomplete="off">
          <mat-autocomplete #accountingAuto="matAutocomplete" [displayWith]="getAccountingAccountFn">
            <mat-option *ngFor="let account of filteredAccountingAccounts | async" [value]="account">
              {{account.code}} - {{account.name}}
            </mat-option>
          </mat-autocomplete>
          <button
            mat-icon-button
            matSuffix
            *ngIf="input.value !== ''"
            (click)="input.value = '' && $event.stopPropagation()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="column">
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Pays</mat-label>
          <input matInput formControlName="country" [matAutocomplete]="countryAuto" autocomplete="off" required>
          <mat-autocomplete #countryAuto="matAutocomplete">
            <mat-option *ngFor="let country of filteredCountries | async" [value]="country.label">
              {{country.label}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Entrée - Bâtiment - Immeuble - RES - ZI</mat-label>
          <input matInput formControlName="buldingInfo"
            autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Num. +Voie</mat-label>
          <input matInput formControlName="address"
            autocomplete="off" required>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Mention spéciale</mat-label>
          <input matInput formControlName="additionalAddress" autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Code Postal</mat-label>
          <input matInput formControlName="zipCode" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field appearance="outline" class="example-full-width">
          <mat-label>Ville</mat-label>
          <input matInput formControlName="city" autocomplete="off" required>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="column">
    <h3 class="title">Responsable du compte :</h3>
    <ngx-select-dropdown class="example-full-width" id="clientId" [config]="userConfig" [options]="usersOptions"
      [multiple]="false" formControlName="incharge"></ngx-select-dropdown>
    <h4>Commentaire :</h4>
    <mat-card-content  class="example-full-width">
      <quill-editor format="object" class="editor" formControlName="comments"
          placeholder="Editez votre texte..." [modules]="quillConfig"></quill-editor>
    </mat-card-content>
    <mat-checkbox class="checkbox" formControlName="inactif" color="primary">Inactif</mat-checkbox>
    <div class="logo-section">
      <div>
        <input style="display: none" type="file" (change)="modifyLogo($event)" accept="image/png, image/jpeg"
        #logoModify>
        <button mat-raised-button color="primary" (click)="logoModify.click()">Ajouter un logo</button>
      </div>
      <div class="logo" *ngIf="logoControl.value">
        <img class="image_logo" [src]="logoControl.value" />
        <button mat-raised-button color="warn" (click)="deleteLogo()">Supprimer Le Logo</button>
      </div>
    </div>
  </div>
</form>
