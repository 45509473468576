import { Error, ErrorValidator } from "@core/models/error.model";
import { Invoice, InvoiceDevisExpress } from "@core/models/invoice.model";
import { InvoiceService } from "@core/services/invoice.service";
import { firstValueFrom } from "rxjs";

export interface InvoiceNumberAlreadyExistsError extends Error<Invoice> {}
export interface InvoiceNumberAlreadyExpressExistsError extends Error<InvoiceDevisExpress> {}

export function invoiceNumberAlreadyExistsErrorValidator(invoiceService: InvoiceService): ErrorValidator<InvoiceNumberAlreadyExistsError> {
  return async (value: Invoice) => {
    const isInvoiceNumberAlreadyUsed: boolean = await firstValueFrom(invoiceService.isInvoiceNumberAlreadyUsed(value.invoiceNumber));
    return isInvoiceNumberAlreadyUsed ? {
      value,
      message: 'Le numéro de facture est déjà utilisé'
    } : null;
  }
}

  export function invoiceNumberAlreadyExistsExpressErrorValidator(invoiceService: InvoiceService): ErrorValidator<InvoiceNumberAlreadyExpressExistsError> {
    return async (value: InvoiceDevisExpress) => {
      const isInvoiceNumberAlreadyUsed: boolean = await firstValueFrom(invoiceService.isInvoiceNumberAlreadyUsed(value.invoiceNumber));
      return isInvoiceNumberAlreadyUsed ? {
        value,
        message: 'Le numéro de facture est déjà utilisé'
      } : null;
    }
  }
