// export const environment = {
//   production: false,
//   nodeProtocol: 'http',
//   nodeURL : 'srv-emo-rec-pi.e-mothep.local:3000/v1',
//   taskerURL: 'http://e-task-qual.e-mothep.local',
//   firebase: {
//     apiKey: 'AIzaSyCWRROI_xQo0CCK_QPA8SCEXvg07iYCk94',
//     authDomain: 'contratgen-6beeb.firebaseapp.com',
//     databaseURL: 'https://contratgen-6beeb.firebaseio.com',
//     projectId: 'contratgen-6beeb',
//     storageBucket: 'contratgen-6beeb.appspot.com',
//     messagingSenderId: '552051061896'
//   },
//   authAPI: 'srv-emo-rec-pi.e-mothep.local:4378/v1',
//   portailURL: 'portail-qual.e-mothep.local',
//   mainDomain: '.e-mothep.local',
//   invoiceAPI: 'http://SRV-EMO-PI-APIG:5555/gateway/API%20003_Facture/1.0',
//   invoiceToken: 'QWRtaW5pc3RyYXRvcjptYW5hZ2U=',
//   disableInvoiceSend: false,
//   connectFrom: 'LDAP'
// };
export const environment = {
  production: false,
  nodeProtocol: 'https',
  nodeURL : 'srv-emo-rec-pi.e-mothep.local:3000/v1',
  taskerURL: 'http://e-task-qual.e-mothep.local',
  firebase: {
    apiKey: "AIzaSyCsB7dHszOoqdGscm_M-MNZ_xfJ-twku6Y",
    authDomain: "hosting-hugo-59650.firebaseapp.com",
    projectId: "hosting-hugo-59650",
    storageBucket: "hosting-hugo-59650.appspot.com",
    messagingSenderId: "375294970682",
    appId: "1:375294970682:web:bb3ae2e126a713fec99e03"
  },
  authAPI: 'srv-emo-rec-pi.e-mothep.local:4378/v1',
  portailURL: 'portail-odeal.e-mothep.com/',
  mainDomain: '.e-mothep.com',
  invoiceAPI: 'http://SRV-EMO-PI-APIG:5555/gateway/API%20003_Facture/1.0',
  invoiceToken: 'QWRtaW5pc3RyYXRvcjptYW5hZ2U=',
  disableInvoiceSend: true,
  connectFrom: 'FIREBASE'
};
