import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IParagraphe } from '@core/models/chapters.models';
import { ChaptersService } from '@core/services/chapters.service';

@Component({
  selector: 'app-modify-paragraphe',
  templateUrl: './modify-paragraphe.component.html',
  styleUrls: ['./modify-paragraphe.component.scss']
})
export class ModifyParagrapheComponent implements OnInit {
  @Input() currentChapter = null;
  @Input() projet = null;
  @Input() inCurrentProject : boolean;

  constructor(private db: AngularFirestore,public chaptersService: ChaptersService) { }

  quillConfig = {
    toolbar: [
      [{ 'font': [] }, { 'size': [] }],
      [{ 'header': '1' }, { 'header': '2' }],
      [ 'bold', 'italic', 'underline', 'strike' ],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet'}],
      [{ 'align': [] }, { 'indent': '-1' }, { 'indent': '+1' }],
      [ 'link', 'image'],
      [ 'blockquote', 'code-block' ],
      [ 'clean']
    ],
    keyboard: {
      bindings: {
        'list autofill': {
          prefix: /^\s{0,}(1){1,1}(\.|-|\*|\[ ?\]|\[x\])$/
        }
      }
    }
  };

  ngOnInit(): void {
  }

  changeChapterTitle() {
    this.db.doc(`chapters/${this.currentChapter.key}`).update({ name: this.currentChapter.name });
  }

  changeChapterTitleInProject() {
    return this.chaptersService.updateChapter(this.projet.key, this.currentChapter);
  }

  deleteChapter() {
    this.chaptersService.deleteChapters(this.currentChapter);
    this.currentChapter = null;
  }

  deleteChapterInProject() {
    this.chaptersService.deleteChapter(this.projet.key, this.currentChapter)
      .then(r => {
        this.currentChapter = this.chaptersService.chapters.length > 0
          ? this.chaptersService.chapters[0]
          : null;
      });
  }

  addParagraphe() {
    const lastOrder = this.chaptersService.paragraphes.length > 0
      ? Math.max(...this.chaptersService.paragraphes.map(p => p.order))
      : -1;
    this.chaptersService.addParagraphe(this.currentChapter.key,
      {
        name: "Default name",
        type: "par",
        order: lastOrder + 1,
        ops: [
          {
            insert: "Default content"
          }
        ]
      });
  }

  addParagrapheInProject() {
    const lastOrder = this.chaptersService.paragraphes.length > 0
      ? Math.max(...this.chaptersService.paragraphes.map(p => p.order))
      : -1;
    return this.chaptersService.addParagraph(this.projet.key, this.currentChapter.key,
      {
        name: "Default name",
        chapterKey: this.currentChapter.key,
        type: "par",
        order: lastOrder + 1,
        ops: [
          {
            insert: "Default content"
          }
        ]
      });
  }

  changeParagrapheTitle(par: IParagraphe) {
    this.db.doc(`chapters/${this.currentChapter.key}/paragraphes/${par.key}`).update({ name: par.name });
  }

  changeParagrapheTitleInProject(paragraphe: IParagraphe) {
    return this.chaptersService.updateParagraphe(this.projet.key, this.currentChapter.key, paragraphe);
  }

  reorder(event: CdkDragDrop<IParagraphe[]>) {
    moveItemInArray(this.chaptersService.paragraphes, event.previousIndex, event.currentIndex);
    for(var i=0; i<this.chaptersService.paragraphes.length; i++) {
      this.chaptersService.paragraphes[i].order = i;
    }
    this.chaptersService.reorderParagraphs(this.currentChapter.key);
  }

  reorderInProject(event: CdkDragDrop<IParagraphe[]>) {
    moveItemInArray(this.chaptersService.paragraphes, event.previousIndex, event.currentIndex);
    for (var i = 0; i < this.chaptersService.paragraphes.length; i++) {
      this.chaptersService.paragraphes[i].order = i;
    }
    this.chaptersService.reorderParagraphes(this.projet.key, this.currentChapter.key);
  }

  updateParagrapheText(par: IParagraphe) {
    const parObject: IParagraphe = {
      key: par.key,
      name: par.name,
      order: par.order,
      type: "par",
      ops: par.ops.map(op => {
        return {
          ...op
        }
      })
    }
    this.chaptersService.updateParagraphes(this.currentChapter.key, parObject);
  }

  updateParagrapheTextInProject(par: IParagraphe) {
    const parObject: IParagraphe = {
      key: par.key,
      chapterKey: par.chapterKey,
      name: par.name,
      order: par.order,
      type: "par",
      ops: par.ops.map(op => {
        return {
          ...op
        }
      })
    }
    this.chaptersService.updateParagraphe(this.projet.key, this.currentChapter.key, parObject);
  }

  deleteParagraphe(par: IParagraphe) {
    this.chaptersService.deleteParagraphes(this.currentChapter.key, par);
  }

  deleteParagrapheInProject(paragraphe: IParagraphe) {
    return this.chaptersService.deleteParagraphe(this.projet.key, this.currentChapter.key, paragraphe);
  }


}
