import { AbstractControl, ValidatorFn } from '@angular/forms';

const PHONE_REGEXP: RegExp = /[+]\d{2}\d{9}/;

export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): { phone: string } | null => {
    if (!control.value) return null;
    const isValid = PHONE_REGEXP.test(control.value);
    return isValid ? null : { phone: 'Format invalide (+336001122334)' };
  }
}