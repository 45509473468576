import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IChapter } from '@core/models/chapters.models';
import { ChaptersService } from '@core/services/chapters.service';

@Component({
  selector: 'app-paragraphe-list',
  templateUrl: './paragraphe-list.component.html',
  styleUrls: ['./paragraphe-list.component.scss']
})
export class ParagrapheListComponent implements OnInit {
  public currentChapter: IChapter = null;
  @Output() sendCurrentChapter = new EventEmitter();

  constructor(public chaptersService: ChaptersService) { }

  ngOnInit() {
    this.chaptersService.init();
    this.chaptersService.ChaptersChange.subscribe(data => {
    });
    this.chaptersService.ParagraphesChange.subscribe(data => {
    });
  }

  ngOnDestroy() {
    this.chaptersService.cancelChaptersSubscription()
    this.chaptersService.cancelParagraphesSubscription()
  }

  addChapter() {
    const name = "newChapter";
    const type = "text";
    this.chaptersService.addChapters(name, type);
  }

  selectChapter(chapter: IChapter) {
    this.currentChapter = chapter;
    this.chaptersService.subscribeToParagraphe(chapter.key);
    this.sendCurrentChapter.emit(this.currentChapter);
  }

}
