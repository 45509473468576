import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IArticle } from '@core/models/service.models';

@Component({
  selector: 'app-add-articles',
  templateUrl: './add-articles.component.html',
  styleUrls: ['./add-articles.component.scss']
})
export class AddArticlesComponent implements OnInit {
  public action = 'Editer';
  constructor(@Inject(MAT_DIALOG_DATA) public data: IArticle) { }

  ngOnInit() {
    if (!this.data) {
      this.action = 'Ajouter';
      this.data = {
        amount: null,
        cost: null,
        outOfBounds: null,
        deliverables: null,
        technicalMeans: null,
        name: null,
        objectifs: null,
        requirements: null,
        mainActivities: null,
        profilsClient: null,
        profilsPresta: null,
        formation: false,
      };
    }
  }

}
