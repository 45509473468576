import { NgModule } from '@angular/core';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ListProjectsComponent } from './components/list-projects/list-projects.component';
import { SharedModule } from '@shared/shared.module';
import { AddProjectComponent } from './modals/add-project/add-project.component';
import { DeleteProjectComponent } from './modals/delete-project/delete-project.component';
import { UpdateProjectComponent } from './modals/update-project/update-project.component';
import { CopyProjectsComponent } from './modals/copy-projects/copy-projects.component';
import { ProjectsClientComponent } from './components/projects-client/projects-client.component';
import { ProjectHeaderComponent } from './components/project-header/project-header.component';
import { ProjectSetterComponent } from './components/project-setter/project-setter.component';
import { EditArticleModule } from './module/edit-article/edit-article.module';
import { EditPdfModule } from './module/edit-pdf/edit-pdf.module';
import { ValidationProjectsModule } from './module/validation-projects/validation-projects.module';
import { SelectServicesModule } from './module/select-services/select-services.module';
import { AddProjectFactureComponent } from './modals/add-project-facture/add-project-facture.component';
import { GestionProjetComponent } from './components/gestion-projet/gestion-projet.component';
import { AddDueDateComponent } from './modals/add-due-date/add-due-date.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { InvoiceHeaderComponent } from './components/invoice/invoice-header/invoice-header.component';
import { InvoiceLineComponent } from './components/invoice/invoice-line/invoice-line.component';
import { InvoicePreviewComponent } from './components/invoice/invoice-preview/invoice-preview.component';
import { InvoiceLinesCardComponent } from './components/invoice/invoice-lines-card/invoice-lines-card.component';
import { ClientErrorModalComponent } from './modals/client-error-modal/client-error-modal.component';
import { DevisExpressModule } from '@pages/devis-express/devis-express.module';

@NgModule({
  declarations: [ListProjectsComponent, AddProjectComponent, DeleteProjectComponent, UpdateProjectComponent, CopyProjectsComponent, CopyProjectsComponent, ProjectsClientComponent, ProjectHeaderComponent, ProjectSetterComponent, AddProjectFactureComponent, GestionProjetComponent, AddDueDateComponent, InvoiceComponent, InvoiceHeaderComponent, InvoiceLineComponent, InvoicePreviewComponent, InvoiceLinesCardComponent, ClientErrorModalComponent],
  imports: [
    SharedModule,
    ProjectsRoutingModule,
    EditArticleModule,
    EditPdfModule,
    ValidationProjectsModule,
    SelectServicesModule,
    DevisExpressModule
  ]
})
export class ProjectsModule { }
