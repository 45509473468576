import { NgModule } from '@angular/core';
import { ClientsRoutingModule } from './clients-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ListClientsComponent } from './components/list-clients/list-clients.component';
import { ClientGestionComponent } from './components/client-gestion/client-gestion.component';
import { ClientProjectsComponent } from './components/client-projects/client-projects.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { CommonModule } from '@angular/common';
import { ClientCreationComponent } from './modals/client-creation/client-creation.component';
import { ClientCreationFormComponent } from './modals/client-creation/client-creation-form/client-creation-form.component';
import { ClientCreationSearchComponent } from './modals/client-creation/client-creation-search/client-creation-search.component';


@NgModule({
  declarations: [
    ListClientsComponent, 
    ClientGestionComponent, 
    ClientProjectsComponent, 
    ClientDetailsComponent, 
    ClientCreationComponent, 
    ClientCreationFormComponent,
    ClientCreationSearchComponent
  ],
  imports: [
    SharedModule,
    ClientsRoutingModule,
    CommonModule
  ],
  exports : [
    ListClientsComponent
  ]
})
export class ClientsModule { }
