<header matDialogTitle>
  Création d'un client
</header>
<main>
  <mat-horizontal-stepper linear #stepper>
    <mat-step>
      <ng-template matStepLabel>Recherche de client</ng-template>
      <div>
        <button mat-button matStepperNext>Suivant</button>
      </div>
      <app-client-creation-search [searchClient]="searchClient" (goToClientClick)="goToClient($event)" (clientSelection)="selectClient($event)"></app-client-creation-search>
      <div>
        <button mat-button matStepperNext>Suivant</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="clientForm">
      <ng-template matStepLabel>Saisie des informations du client</ng-template>
      <app-client-creation-form [clientForm]="clientForm"></app-client-creation-form>
      <div>
        <button mat-button matStepperPrevious>Précédent</button>
        <button mat-button [disabled]="clientForm.invalid" (click)="createClient()">Créer le client</button>
        <span class="already-exist" *ngIf="alreadyExist">
          Ce client existe déjà, <strong class="already-exist-link" (click)="goToClient(alreadyExist)">lien vers la fiche client</strong>.
        </span>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</main>