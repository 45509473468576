<div class="paragraphes-content" *ngIf="currentChapter">
  <div class="paragraphes-header">
    <ng-container *ngIf="!inCurrentProject">
      <mat-form-field fxFlex class="titreCh" *ngIf="currentChapter.type === 'text'">
        <input debounce fxFlex matInput [(ngModel)]="currentChapter.name" (func)="changeChapterTitle()"
          placeholder="Titre du chapitre" autocomplete="off">
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="inCurrentProject">
      <mat-form-field fxFlex [ngStyle]="{'width': '400px'}" *ngIf="currentChapter.type === 'text'">
        <input debounce [delay]="700" fxFlex matInput [(ngModel)]="currentChapter.name"
          (func)="changeChapterTitleInProject()" placeholder="Titre du chapitre" autocomplete="off">
      </mat-form-field>
    </ng-container>
    <span *ngIf="!(currentChapter.type === 'text')">
      {{ currentChapter.name }}
      <i class="fas fa-cogs"></i>
    </span>
  </div>
  <div class="paragraphes-header">
    <ng-container *ngIf="!inCurrentProject">
      <button class="boutonEntete" mat-raised-button (click)="deleteChapter()" *ngIf="currentChapter.type === 'text'">Supprimer</button>
      <button class="boutonEntete" mat-raised-button (click)="addParagraphe()" *ngIf="currentChapter.type === 'text'">Ajouter un paragraphe</button>
    </ng-container>
    <ng-container *ngIf="inCurrentProject">
      <button mat-raised-button (click)="deleteChapterInProject()" class="boutonEntete">Supprimer</button>
      <button class="boutonEntete" mat-raised-button (click)="addParagrapheInProject()" *ngIf="currentChapter.type === 'text'">Ajouter un paragraphe</button>
    </ng-container>
  </div>
  <ng-container *ngIf="!inCurrentProject">
    <div cdkDropList (cdkDropListDropped)="reorder($event)" *ngIf="currentChapter.type === 'text'">
      <mat-card *ngFor="let par of chaptersService.paragraphes" class="paragraphe" cdkDrag>
        <div cdkDragHandle><i class="fas fa-arrows-alt"></i></div>
        <mat-card-header>
          <mat-card-title>
            <mat-form-field fxFlex [ngStyle]="{'width': '600px'}">
              <input debounce fxFlex matInput [(ngModel)]="par.name" (func)="changeParagrapheTitle(par)"
                placeholder="Titre du paragraphe" autocomplete="off">
            </mat-form-field>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <quill-editor format="object" class="editor" [(ngModel)]="par.ops" placeholder="Entrer votre texte ici"
            [modules]="quillConfig"></quill-editor>
        </mat-card-content>
        <mat-card-actions>
          <button class="boutonPara" mat-button (click)="deleteParagraphe(par)">Supprimer</button>
          <button class="boutonPara" mat-button (click)="updateParagrapheText(par)">Sauvegarder</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngIf="inCurrentProject">
    <div cdkDropList (cdkDropListDropped)="reorderInProject($event)" *ngIf="currentChapter.type === 'text'">
      <mat-card *ngFor="let par of chaptersService.paragraphes" class="paragraphe" cdkDrag>
        <div cdkDragHandle><i class="fas fa-arrows-alt"></i></div>
        <mat-card-header>
          <mat-card-title>
            <mat-form-field fxFlex [ngStyle]="{'width': '400px'}">
              <input debounce fxFlex matInput [(ngModel)]="par.name" (func)="changeParagrapheTitleInProject(par)"
                placeholder="Titre du paragraphe" autocomplete="off">
            </mat-form-field>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <quill-editor format="object" class="editor" [(ngModel)]="par.ops" placeholder="Entrer votre texte ici"
            [modules]="quillConfig"></quill-editor>
        </mat-card-content>
        <mat-card-actions>
          <button class="boutonPara" mat-button (click)="deleteParagrapheInProject(par)">Supprimer</button>
          <button class="boutonPara" mat-button (click)="updateParagrapheTextInProject(par)">Sauvegarder</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </ng-container>
</div>
