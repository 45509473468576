import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { InvoiceDevisExpressLine, InvoiceLine } from '@core/models/invoice.model';
import { IArticle, IService } from '@core/models/service.models';
import { UnitCode, UnitLabel } from '@core/models/units.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-line',
  templateUrl: './invoice-line.component.html',
  styleUrls: ['./invoice-line.component.scss']
})
export class InvoiceLineComponent {
  @Input() public line: InvoiceDevisExpressLine;
  @Input() public service: IService;
  @Optional() @Input() public options: InvoiceDevisExpressLine[] = [];

  @Output() public deleteLine: EventEmitter<InvoiceLine> = new EventEmitter<InvoiceLine>();

  public nameControl: FormControl = new FormControl();

  public filteredArticles$: Observable<string[]>;

  public UNIT_CODE_VALUES: ReadonlyArray<UnitCode> = UnitLabel.map((unit) => unit.value);

  constructor() {
    this.filteredArticles$ = this.nameControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value))
    )
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.service.articles.filter((option: IArticle) => option.name.toLowerCase().includes(filterValue)).map((article: IArticle) => article.name);
  }

  public onArticleSelect(event: MatAutocompleteSelectedEvent): void {
    const selectedLine = this.lineOptions.find((line: InvoiceLine) => line.name === event.option.value);
    this.line.quantity = environment.connectFrom === 'FIREBASE' ? selectedLine.quantity :  0;
    this.line.unit = selectedLine.unit;
    this.line.unitPrice = selectedLine.unitPrice;
    this.line.tvaRate = selectedLine.tvaRate;
  }

  public removeLine(): void {
    this.deleteLine.emit(this.line);
  }

  private getArticleUnitValue(unit: string): string {
    switch(unit) {
      case 'J':
        return this.line.unit = UnitLabel.find((unit) => unit.label === 'Jour').value;
      case 'M':
        return this.line.unit = UnitLabel.find((unit) => unit.label === 'Mois').value;
      case 'H':
        return this.line.unit = UnitLabel.find((unit) => unit.label === 'Heure').value;
      case 'U':
        return this.line.unit = UnitLabel.find((unit) => unit.label === 'Unité').value;
      case '%':
        return this.line.unit = UnitLabel.find((unit) => unit.label === '%').value;
    }
  }

  public get lineOptions(): InvoiceDevisExpressLine[] {
    if (environment.connectFrom === 'FIREBASE') {
      return this.options;
    }
    return this.service.articles.map((article: IArticle) => ({
      id: null,
      name: article.name,
      quantity: 0,
      unit: this.getArticleUnitValue (this.service.unity),
      unitPrice: article.cost,
      tvaRate: '21'
    }));
  }

}
