import { Component, OnInit } from '@angular/core';
import { IService } from '@core/models/service.models';
import { ServicesService } from '@core/services/services.service';

@Component({
  selector: 'app-shop-services',
  templateUrl: './shop-services.component.html',
  styleUrls: ['./shop-services.component.scss']
})
export class ShopServicesComponent implements OnInit {

  constructor(public servicesService: ServicesService) { }

  ngOnInit(): void {
  }

  getMontantTotalByService(service: IService) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return +(service.articles && service.articles.length > 0 ?
      service.articles.map(article => article.amount * article.cost).reduce(reducer) : 0).toFixed(2);
  }

  getChargeTotalByService(service: IService) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return (service.articles && service.articles.length > 0 ?
      service.articles.map(article => article.amount).reduce(reducer) : 0);
  }

  noReturnPredicate() {
    return false;
  }

}
