<div class="chapters-content">
  <div class="chapters-header">
    <button mat-raised-button class="addCh" (click)="addChapter()">Ajouter un chapitre</button>
  </div>
  <mat-action-list class="chapters-list">
    <mat-list-item *ngFor="let chapter of chaptersService.chapters" (click)="selectChapter(chapter)"
      [ngClass]="{'chapter-box': true, 'selected': chapter === currentChapter}" class="chapter-button">
      {{ chapter.name }}
      <i class="fas fa-cogs" *ngIf="chapter.type !== 'text'"></i>
    </mat-list-item>
  </mat-action-list>
</div>
