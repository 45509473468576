import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { CountryService } from '@core/services/country.service';
import { firstValueFrom, map } from 'rxjs';

export function countryValidator(countryService: CountryService): AsyncValidatorFn {
  return (control: AbstractControl): Promise<{ country: string | null }> => {
    if (!control.value) return Promise.resolve(null);
    return firstValueFrom(countryService.getCountries().pipe(
      map((countries) => {
        const country = countries.find((country) => country.label === control.value);
        return country ? null : { country: 'Pays inconnu' };
      })
    ))
  };
}