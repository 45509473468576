import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { IChapter } from '@core/models/chapters.models';
import { IDasboard } from '@core/models/dasboard.model';
import { InvoiceDevisExpress } from '@core/models/invoice.model';
import { IProjet } from '@core/models/projet.models';
import { ClientsService } from '@core/services/clients.service';
import { InvoiceDevisExpressService } from '@core/services/invoice-devis-express.service';
import { ProjetsService } from '@core/services/projets.service';
import { ServicesService } from '@core/services/services.service';
import { UserService } from '@core/services/user.service';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-setter',
  templateUrl: './project-setter.component.html',
  styleUrls: ['./project-setter.component.scss']
})
export class ProjectSetterComponent implements OnInit, OnDestroy {
  public currentStep = 0;
  public stepValue = ["Sélection des services", "Edition des articles", "Edition du PDF", "Validation"];
  @ViewChild('stepper') private myStepper: MatStepper;
  private projetId: string;
  public project: IProjet;
  private _project: Subscription;
  public chaptersTemp: IChapter[];
  public chapters: IChapter[] = [];

  public devisInvoice: InvoiceDevisExpress;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public projectsService: ProjetsService,
    private db: AngularFirestore,
    public serviceService: ServicesService,
    private ar: ActivatedRoute,
    public userService: UserService,
    private readonly devisExpressService: InvoiceDevisExpressService 
  ) {

  }

  ngOnInit() {
    this.projetId = this.route.snapshot.paramMap.get('key') as string;
    this._project = this.projectsService.getProjectById(this.projetId).subscribe(async project => {
      this.project = project
      if (!this.isExternal) {
        const step = Number(this.route.snapshot.paramMap.get('step'));
        if (step && step < 3 && step >= 0) {
          this.myStepper.selectedIndex = step;
        }

        if (this.project != null) {
          this.setProject();
        } else {
          this.router.navigateByUrl("project/" + this.ar.snapshot.params.key);
        }

        this.serviceService.serviceForProject(this.projetId);
        this.chaptersTemp = [];
        this.getChapter('/projects/' + this.projetId + '/chapter/').then(() => {
          this.chapters = this.chaptersTemp;
        });
      } else {
        this.devisInvoice = await firstValueFrom(this.devisExpressService.getDevisExpressByProjectId(this.project.key));
      }
    });
  }

  public setProject() {
    this.projetId = this.route.snapshot.paramMap.get('key');
    this.projectsService.loadCurrentProject(this.projetId);
  }

  ngOnDestroy() {
    this.projectsService.destroyCurrentProject();
    this.serviceService.cancelShoppedService();
  }

  stepChange(event) {
    this.currentStep = event.selectedIndex;
    if (this.currentStep === 2 && !this.project.price) {
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      const services = this.serviceService.shoppedServices.filter(service => service.articles && service.articles.length > 0);
      let price = (services && services.length > 0 ? services.map(service => service.articles.map(article => article.amount * article.cost).reduce(reducer)).reduce(reducer) : 0);
      this.projectsService.updateProjectPrice(this.project.key, price);
      this.project.price = price;
    }
    this.router.navigateByUrl(`/project/${this.projectsService.currentProject.key}/devis/${this.currentStep}`);
  }

  getChapter(path) {
    return new Promise((resolve, reject) => {
      const collection = this.db.collection(path, ref => ref.orderBy('order', 'asc'));
      collection.get().subscribe((data) => {
        const promises = [];
        data.forEach((element) => {
          const key = element.id;
          const data = element.data();
          if (element.ref.parent.id === 'chapter') {
            promises.push(this.getChapter('projects/' + this.projetId + '/chapter/' + key + '/paragraphes'));
            this.chaptersTemp.push({ key, ...data, content: [] } as any);
          } else {
            this.chaptersTemp.filter(chapter => chapter.key === element.ref.parent.parent.id)[0].content.push({ key, ...data });
            resolve(true);
          }
        });
        Promise.all(promises).then(() => { resolve(true); });
      });
    });
  }

  public get isExternal(): boolean {
    return environment.connectFrom === 'FIREBASE';
  }
}
