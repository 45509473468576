import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditPdfComponent } from './components/edit-pdf/edit-pdf.component';

const routes: Routes = [{path: '', component: EditPdfComponent}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EditPdfRoutingModule { }
