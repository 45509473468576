<mat-form-field appearance="outline">
  <mat-label>Désignation</mat-label>
  <input matInput placeholder="Description" [(ngModel)]="line.name" [matAutocomplete]="nameAuto">
  <mat-autocomplete #nameAuto="matAutocomplete" (optionSelected)="onArticleSelect($event)">
    <mat-option *ngFor="let article of lineOptions" [value]="article.name">
      {{ article.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field appearance="outline" class="number-input">
  <mat-label>Quantité</mat-label>
  <input matInput type="number" placeholder="Quantité" [(ngModel)]="line.quantity">
</mat-form-field>
<mat-form-field appearance="outline">
  <mat-label>Nature</mat-label>
  <mat-select [(ngModel)]="line.unit">
    <mat-option *ngFor="let unit of UNIT_CODE_VALUES" [value]="unit">
      {{ unit | unitLabel }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="outline" class="number-input">
  <mat-label>Prix unitaire</mat-label>
  <input matInput placeholder="Prix unitaire" [(ngModel)]="line.unitPrice">
</mat-form-field>
<mat-form-field appearance="outline" class="number-input">
  <mat-label>Taux TVA</mat-label>
  <mat-select [(ngModel)]="line.tvaRate">
    <mat-option value="0">Exonéré</mat-option>
    <mat-option value="2.1">2.1</mat-option>
    <mat-option value="5.5">5.5</mat-option>
    <mat-option value="10">10</mat-option>
    <mat-option value="20">20</mat-option>
  </mat-select>
</mat-form-field>
<button mat-icon-button (click)="removeLine()"><mat-icon>delete</mat-icon></button>