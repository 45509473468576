<mat-toolbar class="toolbar" fxLayoutAlign="space-between center">
  <div>
    <img src="../../../../assets/imgpsh_fullsize_anim-2.png" height="35" style="margin-right: 10px;"/>
  </div>
  <div>
    <button mat-icon-button (click)="sideNavToggle()">
      <i class="fas fa-bars"></i>
    </button>
    <button mat-icon-button [routerLink]="['/dashboard']">
      <i class="fas fa-home fa-lg"></i>
    </button>
    <button mat-icon-button *ngIf="themeService.alternativeTheme" (click)="themeService.changeTheme('default-theme')">
      <span class="material-icons">wb_sunny</span>
    </button>
    <!-- <button mat-icon-button *ngIf="!themeService.alternativeTheme"
      (click)="themeService.changeTheme('theme-alternate')">
      <span class="material-icons">nights_stay</span>
    </button> -->
  </div>
  <span class="margin-position">E-Business</span>
  <div class="margin-position" [matMenuTriggerFor]="menu" *ngIf="user">{{ getDisplayName() }}
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="authService.signOutUser()">
      Déconnexion
    </button>
  </mat-menu>
</mat-toolbar>
