import { Pipe, PipeTransform } from "@angular/core";
import { InvoiceTypeLabel } from "@core/models/invoice.model";

@Pipe({
    name: 'invoiceTypeValue'
})
export class InvoiceTypeValuePipe implements PipeTransform {

  public transform(value: number): string {
    return InvoiceTypeLabel.find((due) => due.value === value)?.label || value.toString();
  }
}