import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { PagesModule } from '../pages/pages.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatMenuModule } from '@angular/material/menu';
import { AngularFireStorageModule } from '@angular/fire/storage';



const MODULES = [
  MatSnackBarModule,
  HttpClientModule,
  MatToolbarModule,
  RouterModule,
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireAuthModule,
  AngularFireFunctionsModule,
  AngularFireStorageModule,
  MatSidenavModule,
  MatListModule,
  BrowserAnimationsModule,
  MatIconModule,
  MatButtonModule,
  FlexLayoutModule,
  MatMenuModule,
];


@NgModule({
  declarations: [HeaderComponent,SidenavComponent],
  imports: [
    CommonModule,
    PagesModule,
    AngularFireFunctionsModule,
    ...MODULES
  ],
  exports : [
    HeaderComponent,
    SidenavComponent,
  ],
  providers: [
    { provide: REGION, useValue: 'europe-west3' }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already lodaded.')
    }

  }
}
