export interface Error<T> {
    value: T;
    message: string;
}

export type ErrorValidator<T extends Error<any>> = (value: T['value']) => Promise<T | null>;

export type ThrowableValue<T> = {
  value: T;
  validators: ErrorValidator<Error<T>>[];
}

export async function validateThrowableValues<T>(throwableValues: ThrowableValue<T>[]): Promise<Error<T>[]> {
  const errors: Error<T>[][] = await Promise.all(throwableValues.map((throwable) => Promise.all(throwable.validators.map((validator) => validator(throwable.value)))));
  return errors.reduce((acc, val) => acc.concat(val), []).filter((error) => error !== null);
}

export async function validateThrowableValue<T>(throwableValue: ThrowableValue<T>): Promise<Error<T>[]> {
  const errors: Error<T>[] = await Promise.all(throwableValue.validators.map((validator) => validator(throwableValue.value)));
  return errors.filter((error) => error !== null);
}