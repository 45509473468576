import { Injectable } from '@angular/core';
import { ClientsService } from './clients.service';

@Injectable({
  providedIn: 'root'
})
export class FilterClientsService {

  constructor(public clientsService: ClientsService) { }

  getAllClientFiltered(clientsSelected: string[]) {
    if(!(clientsSelected && clientsSelected.length > 0)) {
      return this.clientsService.clients;
    }
    return this.clientsService.clients.filter(client => {
      return clientsSelected.includes(client.key);
    });
  }

  getFilteredClient(ignoreFilter?: boolean) {
    const filteredIdCLients = JSON.parse(localStorage.getItem("clientsSelect"));
    if(ignoreFilter) {
      return this.clientsService.clients;
    }
    return this.getAllClientFiltered(filteredIdCLients);
  }
}
