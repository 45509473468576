<h1 mat-dialog-title>{{data.create ? 'Création de la facture' : 'Modification de la facture'}}</h1>
<div mat-dialog-content>
  <form [formGroup]="dueDateForm" (ngSubmit)="submit()">
    <div class="grid-form">
      <mat-form-field appearance="outline" class="type full-width">
        <mat-label>Libelle d'échéance</mat-label>
        <input matInput type="text" formControlName="type" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="num full-width">
        <mat-label>Numéro Facture</mat-label>
        <input matInput type="text" formControlName="num" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="amount full-width">
        <mat-label>Montant (max : {{data.create ? data.remainsToBeBilled : (data.remainsToBeBilled + data.invoice.amount)}}€)</mat-label>
        <input matInput type="number" formControlName="amount" [max]="data.project.price" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="forecastPlan full-width">
        <mat-label>Planning Prévisionnel</mat-label>
        <input matInput type="date" formControlName="forecastPlan" />
      </mat-form-field>
      <mat-checkbox formControlName="send" class="send">Facture Emise</mat-checkbox>
    </div>
    <div class="button-footer">
      <button class="marg" mat-dialog-close mat-raised-button type="button">Annuler</button>
      <button class="marg" mat-raised-button type="submit" [disabled]="!dueDateForm.valid">{{data.create ? 'Créer la facture' : 'Modifier la facture'}}</button>
    </div>
  </form>
</div>
