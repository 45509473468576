<h1 mat-dialog-title>Rechercher un client</h1>
<form mat-dialog-content>
  <div class="research-header">
    <div class="research-input">
      <mat-form-field>
        <input matInput type="text" placeholder="Nom du client" [(ngModel)]="this.data" name="search">
      </mat-form-field>
      <button mat-raised-button color="primary" type="button" (click)="research()">Rechercher</button>
    </div>
    <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Sélectionner</button>
  </div>
  <div class="research-result" *ngIf="this.societies && this.societies.length > 0 && this.researchDone">
    <table mat-table [dataSource]="this.societies">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Nom</th>
        <td mat-cell *matCellDef="let society"> {{ society.name }} </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Addresse</th>
        <td mat-cell *matCellDef="let society"> {{ society.address }} </td>
      </ng-container>
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>Ville </th>
        <td mat-cell *matCellDef="let society"> {{ society.city }} </td>
      </ng-container>
      <ng-container matColumnDef="zipCode">
        <th mat-header-cell *matHeaderCellDef>Code postal</th>
        <td mat-cell *matCellDef="let society"> {{ society.zipCode }} </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let society"> {{ society.type }} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let rowData; columns: displayedColumns" (click)="selectClient(rowData)" class="row" [ngClass]="{'selected': rowData === this.selectedClient}"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>

</form>

