import { Component, Input, OnInit } from '@angular/core';
import { IArticle, IService } from '@core/models/service.models';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {
  @Input() articles: IArticle[];
  @Input() service: IService;

  constructor() { }

  ngOnInit(): void {
  }

  montantTotal() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return +this.articles.map(article => article.amount * article.cost).reduce(reducer).toFixed(2);
  }

  chargeTotal() {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    return this.articles.map(article => article.amount).reduce(reducer);
  }

  public getTotalPrice(article: IArticle): number {
    return +(article.amount * article.cost).toFixed(2);
  }

}
