import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelectServicesRoutingModule } from './select-services-routing.module';
import { SelectServicesComponent } from './components/select-services/select-services.component';
import { SharedModule } from '@shared/shared.module';
import { GestServicesModule } from '@pages/gest-services/gest-services.module';


@NgModule({
  declarations: [SelectServicesComponent],
  imports: [
    SharedModule,
    SelectServicesRoutingModule,
    GestServicesModule
  ]
})
export class SelectServicesModule { }
