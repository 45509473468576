import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AccountingAccount } from '@core/models/accounting-account.model';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountingAccountService {

  private readonly COLLECTION_NAME: string = 'accounting-accounts';

  public constructor(
    private readonly firestore: AngularFirestore, 
  ) {}

  public get accountingAccounts(): Observable<AccountingAccount[]> {
    return this.firestore.collection(this.COLLECTION_NAME).get().pipe(
      map((querySnapshot) => querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      } as AccountingAccount)))
    )
  }

}