<div class="edition">
  <div class="chapters">
    <div class="chapters-content">
      <div class="chapters-header">
        <button mat-raised-button (click)="openChaptersTemplate()">COPIER CHAPITRE</button>
      </div>
      <div class="chapters-header">
        <button mat-raised-button (click)="addChapter()">AJOUTER CHAPITRE</button>
      </div>
      <div cdkDropList class="chapters-list" (cdkDropListDropped)="reorderChapters($event)">
        <div [ngClass]="{'chapter-box': true, 'selected': chapter === selectedChapter}"
          *ngFor="let chapter of chaptersService.chapters" (click)="selectChapter(chapter)" cdkDrag>
          {{ chapter.name }}
          <i class="fas fa-cogs" *ngIf="chapter.type !== 'text'"></i>
        </div>
      </div>
    </div>
  </div>
