import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { IBankAccount } from '@core/models/bank.model';
import { IClient } from '@core/models/client.models';
import { IEntity } from '@core/models/entity.model';
import { Invoice, InvoiceDevisExpress, InvoiceType, InvoiceTypeLabel } from '@core/models/invoice.model';
import { BankAccountService } from '@core/services/bank-account.service';
import { ClientsService } from '@core/services/clients.service';
import { EntityService } from '@core/services/entity.service';
import { InvoiceDevisExpressService } from '@core/services/invoice-devis-express.service';
import { InvoiceService } from '@core/services/invoice.service';
import { isInvoiceNumberValid } from '@core/validators/invoiceNumber.validator';
import { ClientCreationComponent } from '@pages/clients/modals/client-creation/client-creation.component';
import { DueValue, PaymentMeans, PaymentMeansLabel, TvaValue, TvaValueLabel } from '@pages/projects/components/invoice/invoice-header/invoice-header.component';
import { Observable, Subscription, combineLatest, map, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'app-devis-invoice-header',
  templateUrl: './devis-invoice-header.component.html',
  styleUrls: ['./devis-invoice-header.component.scss']
})
export class DevisInvoiceHeaderComponent implements OnInit, OnDestroy {

  /** The invoice */
  @Input() public invoice: Invoice;

  /** The client */
  @Input() public client: IClient;

  /** Emit selected entity event */
  @Output() public selectedEntity: EventEmitter<IEntity> = new EventEmitter<IEntity>();

  /** Emit selected bank account event */
  @Output() public selectedBankAccount: EventEmitter<string> = new EventEmitter<string>();

  /** Emit invoice change */
  @Output() public invoiceChange: EventEmitter<Invoice> = new EventEmitter<Invoice>();

  /** Emit save devis change */
  @Output() public saveDevisChange : EventEmitter<InvoiceDevisExpress> = new EventEmitter<InvoiceDevisExpress>();

  /** The list of entity */
  public entities: IEntity[] = [];

  /** List of banks accounts */
  public bankAccounts: IBankAccount[] = [];

  /** The clients */
  public clients: IClient[] = [];

  /** The entity */
  public entity: IEntity;


  /** Due value label */
  public DueValueLabel: readonly { value: string, label: string }[] = [
    { value: '7 jours', label: '7 jours' },
    { value: '15 jours', label: '15 jours' },
    { value: '1 mois', label: '1 mois' },
    { value: '3 mois', label: '3 mois' },
    { value: '6 mois', label: '6 mois' },
  ];

  /** datas subscription */
  private _data: Subscription;

  /** selected entity */
  public isEntitySelected = false;

  /** The form group */
  public devisForm: FormGroup;


  /** Values for differents list */
  public DUE_VALUES: ReadonlyArray<DueValue> = this.DueValueLabel.map((due) => due.value);
  public TYPES_VALUES: ReadonlyArray<InvoiceType> = InvoiceTypeLabel.map((type) => type.value);
  public TVA_VALUES: ReadonlyArray<TvaValue> = TvaValueLabel.map((tva) => tva.value);

  /** validator invoice number */
  public invoiceNumberControl: FormControl = new FormControl('',{
    asyncValidators: [isInvoiceNumberValid(this.invoiceService)]
  });

  /**
   * Creates an instance of DevisInvoiceHeaderComponent.
   * @param entityService of EntityService
   * @param bankAccountService of BankAccountService
   * @param clientsService of ClientsService
   * @param dialog of MatDialog
   * @param invoiceService of InvoiceService
   */
  constructor(
    private readonly entityService: EntityService,
    private readonly bankAccountService: BankAccountService,
    private readonly clientsService: ClientsService,
    private readonly dialog: MatDialog,
    private readonly invoiceService: InvoiceService,
    private readonly devisExpressService: InvoiceDevisExpressService,
  ) { }

  /**
   * Initializes the component
   *
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
  */
  public ngOnInit(): void {
    this.devisForm = new FormGroup({
      entity: new FormControl('', Validators.required),
      client: new FormControl('', Validators.required),
      bankAccount: new FormControl({ value: '', disabled: true}, Validators.required),
      due: new FormControl('15 jours'),
      type: new FormControl('DEVIS'),
      invoiceNumber: new FormControl({value: '', disabled: true}),
      label: new FormControl('', Validators.required),
      deliveryNumber: new FormControl(''),
      contractNumber: new FormControl(''),
      clientNumber: new FormControl(''),
      lines: new FormControl([]),
      hasBeenSent: new FormControl(''),
      plannedDate: new FormControl(new Date()),
      dueLabel: new FormControl(''),
      amount: new FormControl(),
      comment: new FormControl(''),
    });
    this._data = combineLatest([
      this.entityService.getEntities(),
      this.bankAccountService.bankAccountsByOwner$,
      this.clientsService.getAllClient(),
      this.devisExpressService.devisIncrement$
    ]).subscribe(([entities, bankAccounts, clients, devisIncrement]) => {
      this.entities = entities;
      this.bankAccounts = bankAccounts;
      this.clients = clients;
      if (this.devisForm.get('invoiceNumber').value === '') {
        const invoiceNumber: string = devisIncrement.toString().padStart(5, '0');
        this.devisForm.get('invoiceNumber').setValue(invoiceNumber);
      }

      if (this.entities.length === 1) {
        this.devisForm.get('entity').setValue(this.entities[0].key);
        this.isEntitySelected = true;
        this.bankAccountService.updateBankAccountOwner(this.entities[0].key);
        this.devisForm.get('bankAccount').enable();
        this.selectedEntity.emit(this.entities[0]);
      }
    });
  }

  /**
   * Handles changes to the `invoice` input property.
   *
   * @param changes - An object containing the SimpleChanges for the `invoice` property.
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if(changes['invoice'].currentValue !== changes['invoice'].previousValue) {
      if(this.invoice?.entity) {
        this.isEntitySelected = true;
        this.bankAccountService.updateBankAccountOwner(this.invoice.entity);
        this.devisForm.get('bankAccount').enable();
        this.selectedBankAccount.emit(this.invoice.bankAccount);
        const selectedEntity: IEntity = this.entities.find(e => e.key === this.invoice.entity);
        this.selectedEntity.emit(selectedEntity);
      }
      this.devisForm && this.devisForm.patchValue(this.invoice);
    }
  }


  /**
   * Retrieves the devis data from the form and constructs an InvoiceDevisExpress object.
   *
   * @returns {InvoiceDevisExpress} - An InvoiceDevisExpress object containing the devis data.
   * @memberof DevisInvoiceHeaderComponent
   */
  public getDevisFromForm(): InvoiceDevisExpress {
    const clientValue = this.devisForm.get('client').value;
    return {
      id: null,
      entity: this.devisForm.get('entity').value ?? null,
      client: typeof clientValue === 'string' ? clientValue : clientValue?.key ?? null,
      bankAccount: this.devisForm.get('bankAccount')?.value ?? null,
      due: this.devisForm.get('due')?.value ?? null,
      type: this.devisForm.get('type')?.value ?? null,
      invoiceNumber: this.devisForm.get('invoiceNumber')?.value ?? null,
      label: this.devisForm.get('label').value ?? null,
      deliveryNumber: this.devisForm.get('deliveryNumber')?.value ?? null,
      contractNumber: this.devisForm.get('contractNumber')?.value ?? null,
      clientNumber: this.devisForm.get('clientNumber')?.value ?? null,
      lines: this.devisForm.get('lines')?.value ?? null,
      hasBeenSent: this.devisForm.get('hasBeenSent')?.value ?? false,
      plannedDate: this.devisForm.get('plannedDate')?.value ?? new Date(),
      dueLabel: this.devisForm.get('dueLabel')?.value ?? null,
      amount: this.devisForm.get('amount')?.value ?? 0,
      paymentMean: this.devisForm.get('paymentMean')?.value ?? null,
      comment: this.devisForm.get('comment')?.value ?? null,
    }
  }


  /**
   * Unsubscribes from the data observables
   *
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public ngOnDestroy(): void {
    this._data.unsubscribe();
  }

  /**
   * Handles the entity change event in the devis invoice header component.
   *
   * @param {MatSelectChange} entity - The selected entity from the MatSelectChange event.
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public onEntityChange(entity: MatSelectChange): void {
    const selectedEntity: IEntity = this.entities.find(e => e.key === entity.value);
    this.isEntitySelected = true;
    this.bankAccountService.updateBankAccountOwner(entity.value);

    if (selectedEntity?.defaultBankAccountId) {
      this.devisForm.get('bankAccount').setValue(selectedEntity.defaultBankAccountId);
      this.invoice.bankAccount = selectedEntity.defaultBankAccountId;
      this.devisForm.get('bankAccount').enable();
      this.selectedBankAccount.emit(selectedEntity.defaultBankAccountId);
    }

    this.selectedEntity.emit(selectedEntity);
    this.udpateInvoicePrefix();
  }



  /**
   * Emits the updateInvoicePrefix event.
   *
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public udpateInvoicePrefix(): void {
    const prefix: string = InvoiceTypeLabel.find((type) => type.value === this.invoice?.type)?.prefix || '';
    const month: number = new Date().getMonth()+1;
    const year: number = new Date().getFullYear();
    const entity: IEntity = this.entities.find((e) => e.key === this.invoice?.entity);
    this.invoice.invoiceNumber = this.getEntityPrefix(prefix, entity) + year.toString().substring(2) + (month < 10 ? `0${month}` : month) + '-';
  }

   /**
   * Gets the entity prefix for the invoice number.
   *
   * @param {string} prefix - The prefix for the invoice type.
   * @param {IEntity} entity - The selected entity.
   * @returns {string} - The entity prefix combined with the entity value's substring from the 3rd character.
   * @memberof DevisInvoiceHeaderComponent
   */
  private getEntityPrefix(prefix: string, entity: IEntity): string {
    return prefix + entity?.value.substring(2);
  }

  /**
   * Handles the bank account change event in the devis invoice header component.
   *
   * @param {MatSelectChange} account - The selected bank account from the MatSelectChange event.
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public onBankAccountChange(account: MatSelectChange): void {
    this.selectedBankAccount.emit(account.value);
  }

  /**
   * Emits the saveDevisChange event with the provided invoice data.
   *
   * @param {InvoiceDevisExpress} invoice - The invoice data to be emitted.
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public onSaveInvoice(invoice: InvoiceDevisExpress): void {
    this.saveDevisChange.emit(invoice);
  }

  /**
   * Handles the client change event in the devis invoice header component.
   *
   * @param {MatSelectChange} client - The selected client from the MatSelectChange event.
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public onClientChange(client: MatSelectChange): void {
    const selectedClient: IClient = this.clients.find(c => c.key === client.value);

    // if (selectedClient) {
    //   this.devisForm.get('clientNumber').setValue(selectedClient || '');
    // }
  }

  /**
   * Opens a dialog to create a new client.
   *
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public addClient() {
    this.dialog.open(ClientCreationComponent, {
      width: '60%',
      height: '80%'
    });
  }

  public onInvoiceChange(): void {
    this.invoiceChange.emit(this.invoice);
  }

  /**
   * Updates the invoice prefix based on the selected entity and invoice type.
   *
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public updateInvoicePrefix(): void {
    const prefix: string = InvoiceTypeLabel.find((type) => type.value === this.invoice.type)?.prefix || '';
    const month: number = new Date().getMonth() + 1;
    const year: number = new Date().getFullYear();
    const entity: IEntity = this.entities.find((e) => e.key === this.invoice.entity);
    this.invoice.invoiceNumber = this.getEntityPrefix(prefix, entity) + year.toString().substring(2) + (month < 10 ? `0${month}` : month) + '-';
  }
  /**
   * Updates the label of the invoice based on the invoice number and due label.
   *
   * @returns {void}
   * @memberof DevisInvoiceHeaderComponent
   */
  public onInvoiceNumberChange(): void {
    this.invoice.label = `${this.invoice.invoiceNumber}_${this.invoice.dueLabel}`;
  }

  public get deliveryNumber(): string {
    return this.invoice.deliveryNumber || '';
  }

  public set deliveryNumber(value: string) {
    this.invoice.deliveryNumber = value;
  }

  public get contractNumber(): string {
    return this.invoice.contractNumber || '';
  }

  public set contractNumber(value: string) {
    this.invoice.contractNumber = value;
  }

  public getClientName(client: IClient): string {
    return client.name && client.name.length > 0 ? client.name : `${client.lastName} ${client.firstName}`;
  }
}
