import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectSetterComponent } from './components/project-setter/project-setter.component';
import { ProjectsClientComponent } from './components/projects-client/projects-client.component';
import { GestionProjetComponent } from './components/gestion-projet/gestion-projet.component';
import { InvoiceComponent } from './components/invoice/invoice.component';


const routes: Routes = [
  { path: 'client/:key', component: ProjectsClientComponent  },
  { 
    path: ':key', 
    component: GestionProjetComponent, 
  },
  {
    path: ':key/invoice/:invoiceKey',
    component: InvoiceComponent
  },
  { path: ':key/devis', component: ProjectSetterComponent, children:[
    {
      path :'0', loadChildren: () => import('./module/select-services/select-services.module').then(m => m.SelectServicesModule),
    },
    {
      path :'1', loadChildren: () => import('./module/edit-article/edit-article.module').then(m => m.EditArticleModule),
    },
    {
      path :'2', loadChildren: () => import('./module/edit-pdf/edit-pdf.module').then(m => m.EditPdfModule),
    },
    {
      path :'3', loadChildren: () => import('./module/validation-projects/validation-projects.module').then(m => m.ValidationProjectsModule),
    }

  ]  },
  { path: '', component: GestionProjetComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full'  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
